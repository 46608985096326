import type { LiveDraftPick } from '@pff-consumer/schema/fantasy-live-draft/picks'

const FIRST_ROUND = 1
export function getDraftersFromPicks(picks: LiveDraftPick[]) {
  return picks
    .filter(({ round }) => round === FIRST_ROUND)
    .map((pick, index) => {
      return {
        userId: (index + 1).toString(),
        drafteeName: pick.drafteeName,
        pickNumber: index + 1,
      }
    })
}
