import {
  Event,
  Week,
  Team,
  Player,
  PlayerProp,
  PlayerPropType,
  RecentDefenseList,
  HotPlayer,
  PlayerPropMarket,
  PlayerMismatch,
  HistoricalPerformance as PFFKitHistoricalPerformance,
  ReceiverMatchups,
  PlayerVsTeamMatchup,
  PlayerPosition,
  type TightEndMatchups as TightEndMatchupsInterface,
  TightEndMatchups,
  QuarterbackMatchups,
  QuarterbackOffensiveUnit,
  QuarterbackDefendingUnit,
  RunningBackReceivingMatchups,
  RunningBackRushingMatchups,
  RunningBackDefendingUnit,
} from '@pro-football-focus/pffkit-node-client'
import { NFLWeekAbbreviation, FootballStatKey, FantasyPosition } from '@pff-consumer/schema'
import { Matchup } from '@pff-consumer/schema/sdk/matchups'
import { compact, capitalize } from 'lodash'
import type {
  SDKEvent,
  NFLTeam,
  FootballPlayer,
  SDKPlayerPropGradeAnalysis,
  PlayerPropAnalysisVersusType,
  RecentDefensesForTeam,
  PlayerPropStat,
  PlayerPropKey,
  HistoricalPerformance,
  BiggestPlayerMismatch,
} from '@pff-consumer/schema'

const eventWeekToNFLWeekAbbrev = (w: Week): NFLWeekAbbreviation | undefined => {
  return Object.values(NFLWeekAbbreviation).some((weekAbv: string) => w === weekAbv)
    ? (w as unknown as NFLWeekAbbreviation)
    : undefined
}

export const eventToSDKEvent = (e: Event): SDKEvent => ({
  eventId: e.id,
  eventStatus: e.status,
  startTimestamp: e.startDate.getTime(),
  homeTeamId: e.homeTeam.id,
  awayTeamId: e.awayTeam.id,
  homeTeam: e.homeTeam.name,
  awayTeam: e.awayTeam.name,
  homeScore: e.homeScore,
  awayScore: e.awayScore,
  sportradarId: e.sportsradarId,
  homeTeamAbbreviation: e.homeTeam.abbreviation,
  awayTeamAbbreviation: e.awayTeam.abbreviation,
  weekAbv: eventWeekToNFLWeekAbbrev(e.week),
  sportsbookEventId: e.sportsbookEventId,
})

export const teamToNFLTeam = (t: Team, season: number): NFLTeam => ({
  teamId: t.id,
  teamAbv: t.abbreviation,
  name: t.name,
  city: t.city,
  fullName: t.fullName,
  colors: [t.primaryColor, t.secondaryColor],
  league: 'nfl',
  season,
})

export const playerToFootballPlayer = (p: Player): FootballPlayer => ({
  playerId: p.id,
  teamId: p.team.id,
  firstName: p.firstName,
  lastName: p.lastName,
})

const playerPropTypeToPlayerVersusType = (t: PlayerPropType): PlayerPropAnalysisVersusType | undefined => {
  switch (t) {
    case PlayerPropType.PlayerVersusPlayer:
      return 'PLAYER_VS_PLAYER'

    case PlayerPropType.PlayerVersusTeam:
      return 'PLAYER_VS_TEAM_UNIT'

    default:
      return undefined
  }
}

export const playerPropToSDKPlayerProp = (p: PlayerProp): SDKPlayerPropGradeAnalysis => {
  const prop: SDKPlayerPropGradeAnalysis = {
    name: p.name,
    playerId: p.playerId,
    gameId: p.eventId,
    sportsradarId: p.sportsRadarId,
    sportsbookEventId: p.sportsbookEventId,
    sportsbookMarketId: p.sportsbookMarketId,
    sportsbookOptionId: p.sportsbookOptionId,
    analysisText: p.text,
  }

  if (p.details) {
    const { player, opponent } = p.details
    const type = playerPropTypeToPlayerVersusType(p.details.type)

    if (type) {
      prop.analysisDetails = {
        type,
        selfName: player.name,
        selfGradeFacet: player.facet,
        selfGradeValue: player.grade,
        selfGradeRank: player.rank,
        selfGradeRankTotal: player.totalRank,
        versusName: opponent.name,
        versusGradeFacet: opponent.facet,
        versusGradeValue: opponent.grade,
        versusGradeRank: opponent.rank,
        versusGradeRankTotal: opponent.totalRank,
      }
    }
  }

  return prop
}

export const recentDefenseListsToRecentDefensesForTeam = (
  recentDefenses: RecentDefenseList[]
): RecentDefensesForTeam[] => {
  return recentDefenses.map((r) => ({
    teamId: r.teamId,
    recentDefenses: r.recentDefenses.map((rd) => ({
      eventId: rd.eventId,
      teamId: rd.team.id,
      overallSimilarity: rd.overallSimilarity,
    })),
  }))
}

const getPropKeyFromPropMarket = (market?: PlayerPropMarket): PlayerPropKey | undefined => {
  if (!market) return undefined
  // The PFFKit PlayerPropMarket enum has the same strings as are in the Voodoo
  // PLayerPropKey union type, expect the PFFKit PlayerPropKey enum values are uppercased.
  // I could have written a switch/case statement for each of the 38 properties of the
  // PlayerPropKey enum but using `as` here seemed more readable & maintainable
  return market.toLowerCase() as PlayerPropKey
}

export const hotPlayerToPlayerPropStat = (hotPlayer: HotPlayer): PlayerPropStat | undefined => {
  const propMarket = getPropKeyFromPropMarket(hotPlayer.market)
  if (!propMarket) return undefined

  return {
    playerId: hotPlayer.player.id,
    market: propMarket,
    value: hotPlayer.value,
  }
}

const getStatKeyFromPropMarket = (market?: PlayerPropMarket): FootballStatKey | undefined => {
  if (!market) return undefined

  const key = market.toLowerCase()
  return Object.values(FootballStatKey).some((statKey) => statKey === key) ? <FootballStatKey>key : undefined
}

export const pffKitHistoricalPerformanceToHistoricalPerformance = (
  performance: PFFKitHistoricalPerformance
): HistoricalPerformance => ({
  eventId: performance.eventId,
  stats: compact(
    performance.stats.map((s) => {
      const statKey = getStatKeyFromPropMarket(s.market)
      if (!statKey) return undefined

      return {
        statKey,
        statValue: s.value,
        displayOrder: s.displayOrder,
      }
    })
  ),
})

export const playerMismatchToPlayerBiggestPlayerMismatch = (
  mismatch: PlayerMismatch
): BiggestPlayerMismatch | undefined => {
  const market = getPropKeyFromPropMarket(mismatch.market)
  if (!market) return undefined

  return {
    playerId: mismatch.player.id,
    market,
  }
}

export const getPlayerPositionDescription = (position: PlayerPosition): string => {
  switch (position) {
    case PlayerPosition.Cornerback:
      return 'cornerback'
    case PlayerPosition.Linebacker:
      return 'linebacker'
    case PlayerPosition.Safety:
      return 'safety'
    default:
      return ''
  }
}

export const getPlayerNameForTeMatchups = (player: TightEndMatchups.DefenderMatchup): string => {
  const playerPosition = getPlayerPositionDescription(player.position)
  return `${capitalize(playerPosition)} ${capitalize(player.grade.facet)}`
}

const OffensiveUnitName = {
  [QuarterbackOffensiveUnit.PassBlock]: 'OL Pass Block',
  [QuarterbackOffensiveUnit.WideReceiverReceiving]: 'WR Receiving',
  [QuarterbackOffensiveUnit.TightEndReceiving]: 'TE Receiving',
  [QuarterbackOffensiveUnit.RunningBackReceiving]: 'RB Receiving',
}

const DefensiveUnitName = {
  [QuarterbackDefendingUnit.PassDefense]: 'DL Pass Block',
  [QuarterbackDefendingUnit.WideReceiverCoverage]: 'WR Coverage',
  [QuarterbackDefendingUnit.TightEndCoverage]: 'TE Coverage',
  [QuarterbackDefendingUnit.RunningBackCoverage]: 'RB Coverage',
  [QuarterbackDefendingUnit.PassRush]: 'DL Pass Rush',
}

export const getNameForRbReceivingMatchups = (position: PlayerPosition): string => {
  switch (position) {
    case PlayerPosition.Linebacker:
      return 'Linebacker Coverage'
    case PlayerPosition.DefensiveBack:
      return 'Defensive Back Coverage'
    case PlayerPosition.DefensiveLine:
      return 'Defensive Tackle'
    default:
      return ''
  }
}

export const getNameForRbRushingMatchups = (type: RunningBackDefendingUnit): string => {
  return type
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

/*
  This function is used to convert the object we get back from PFF kit and turn it into a 
  more generic object that can be better used though out the repo.

  We should talk to Justin and figure out if we can push some of this logic down to the into the
  PFF kit 
*/
export const cbWrMapToGenericMatchupObject = (
  teamMatchups: PlayerVsTeamMatchup[],
  receiverMatchups: ReceiverMatchups[]
) => {
  const matchups: Matchup[] = []

  receiverMatchups.forEach((matchup) => {
    if (matchup.matchups.length > 0) {
      const team = teamMatchups.find(
        (teamMatchup) => teamMatchup.opponent.id === matchup.matchups[0].cornerback.team.id
      )

      if (team && team.teamGrade) {
        const mappedMatchup: Matchup = {
          player: matchup.receiver,
          grade: matchup.grade,
          overallScore: matchup.overallScore,
          teamGrade: team.teamGrade,
          matchups: matchup.matchups.map((player) => {
            return {
              player: player.cornerback,
              expectedSnapCount: player.expectedSnapCount,
              grade: player.grade,
              score: player.score,
            }
          }),
          matchupPlayer1: `${matchup.matchups[0].cornerback.firstName} ${matchup.matchups[0].cornerback.lastName}`,
          matchupPlayer2:
            matchup.matchups.length > 1
              ? `${matchup.matchups[1].cornerback.firstName} ${matchup.matchups[1].cornerback.lastName}`
              : undefined,
          matchupPlayer3:
            matchup.matchups.length > 2
              ? `${matchup.matchups[2].cornerback.firstName} ${matchup.matchups[2].cornerback.lastName}`
              : undefined,
        }

        matchups.push(mappedMatchup)
      }
    }
  })

  return matchups
}

export const teMatchupMapToGenericMatchupObject = (tightEndMatchups: TightEndMatchupsInterface[]) => {
  const matchups: Matchup[] = []

  tightEndMatchups.forEach((matchup) => {
    if (matchup.matchups.length > 0) {
      if (matchup.defendingTeamGrade) {
        const mappedMatchup: Matchup = {
          player: matchup.player,
          grade: matchup.playerGrade,
          overallScore: matchup.overallMatchupScore,
          teamGrade: matchup.defendingTeamGrade,
          matchups: matchup.matchups.map((player: TightEndMatchups.DefenderMatchup, index: number) => {
            return {
              player: {
                team: matchup.defendingTeam,
                grades: [player.grade],
                id: index,
                firstName: '',
                lastName: getPlayerNameForTeMatchups(player),
                position: player.position,
              },
              expectedSnapCount: player.expectedSnapCount,
              grade: player.grade,
              score: player.matchupScore,
            }
          }),
          matchupPlayer1: getPlayerNameForTeMatchups(matchup.matchups[0]),
          matchupPlayer2: matchup.matchups.length > 1 ? getPlayerNameForTeMatchups(matchup.matchups[1]) : undefined,
          matchupPlayer3: matchup.matchups.length > 2 ? getPlayerNameForTeMatchups(matchup.matchups[2]) : undefined,
        }

        matchups.push(mappedMatchup)
      }
    }
  })

  return matchups
}

export const qbMatchupMapToGenericMatchupObject = (quarterbackMatchups: QuarterbackMatchups[]) => {
  const matchups: Matchup[] = []

  quarterbackMatchups.forEach((matchup) => {
    if (matchup.matchups.length > 0) {
      if (matchup.defendingTeamGrade) {
        const mappedMatchup: Matchup = {
          player: matchup.player,
          grade: matchup.playerGrade,
          overallScore: matchup.overallMatchupScore,
          teamGrade: matchup.defendingTeamGrade,
          matchups: matchup.matchups.map((player: QuarterbackMatchups.Matchup) => {
            return {
              player: {
                team: matchup.defendingTeam,
                grades: [matchup.defendingTeamGrade],
                id: matchup.defendingTeam.id,
                firstName: matchup.defendingTeam.fullName,
                lastName: matchup.defendingTeam.fullName,
                position: PlayerPosition.DefensiveLine,
              },
              expectedSnapCount: 0, //  no projected snaps for QB matchups
              grade: matchup.defendingTeamGrade,
              score: player.matchupScore,
              offense: {
                unitName: OffensiveUnitName[player.offensiveUnit],
                rank: player.offensiveRank,
                rankTotal: player.offensiveRankTotal,
              },
              defense: {
                unitName: DefensiveUnitName[player.defendingUnit],
                rank: player.defendingRank,
                rankTotal: player.defendingRankTotal,
              },
            }
          }),
        }

        matchups.push(mappedMatchup)
      }
    }
  })
  return matchups
}

export const runningBackReceivingMatchupMapToGenericMatchupObject = (
  receivingMatchups: RunningBackReceivingMatchups[]
) => {
  const matchups: Matchup[] = []

  receivingMatchups.forEach((matchup) => {
    if (matchup.matchups.length > 0) {
      if (matchup.defendingTeamGrade) {
        const mappedMatchup: Matchup = {
          player: matchup.player,
          grade: matchup.playerGrade,
          overallScore: matchup.overallMatchupScore,
          teamGrade: matchup.defendingTeamGrade,
          matchups: matchup.matchups.map((player: RunningBackReceivingMatchups.Matchup, index: number) => {
            return {
              player: {
                team: matchup.defendingTeam,
                grades: [matchup.defendingTeamGrade],
                id: index,
                firstName: '',
                lastName: getNameForRbReceivingMatchups(player.defendingPosition),
                position: player.defendingPosition,
              },
              grade: {
                ...matchup.defendingTeamGrade,
                rank: player.defendingRank,
                totalRank: player.defendingRankTotal,
              },
              expectedSnapCount: player.projectedSnapCount,
              score: player.matchupScore,
            }
          }),
          matchupPlayer1: getNameForRbReceivingMatchups(matchup.matchups[0].defendingPosition),
          matchupPlayer2:
            matchup.matchups.length > 1
              ? getNameForRbReceivingMatchups(matchup.matchups[1].defendingPosition)
              : undefined,
          matchupPlayer3:
            matchup.matchups.length > 2
              ? getNameForRbReceivingMatchups(matchup.matchups[2].defendingPosition)
              : undefined,
        }

        matchups.push(mappedMatchup)
      }
    }
  })

  return matchups
}

export const runningBackRushingMatchupMapToGenericMatchupObject = (rushingMatchups: RunningBackRushingMatchups[]) => {
  const matchups: Matchup[] = []

  rushingMatchups.forEach((matchup) => {
    if (matchup.matchups.length > 0) {
      if (matchup.defendingTeamGrade) {
        const mappedMatchup: Matchup = {
          player: matchup.player,
          grade: matchup.playerGrade,
          overallScore: matchup.overallMatchupScore,
          teamGrade: matchup.defendingTeamGrade,
          matchups: matchup.matchups.map((player: RunningBackRushingMatchups.Matchup, index: number) => {
            return {
              player: {
                team: matchup.defendingTeam,
                grades: [matchup.defendingTeamGrade],
                id: index,
                firstName: '',
                lastName: getNameForRbRushingMatchups(player.defendingUnit),
                position: PlayerPosition.DefensiveBack,
              },
              grade: {
                ...matchup.defendingTeamGrade,
                rank: player.defendingRank,
                totalRank: player.defendingRankTotal,
              },
              expectedSnapCount: matchup.expectedAttempts,
              score: player.matchupScore,
            }
          }),
          matchupPlayer1: getNameForRbRushingMatchups(matchup.matchups[0].defendingUnit),
          matchupPlayer2:
            matchup.matchups.length > 1 ? getNameForRbRushingMatchups(matchup.matchups[1].defendingUnit) : undefined,
          matchupPlayer3:
            matchup.matchups.length > 2 ? getNameForRbRushingMatchups(matchup.matchups[2].defendingUnit) : undefined,
        }

        matchups.push(mappedMatchup)
      }
    }
  })

  return matchups
}

export const mapPffKitPositionToFantasyPosition = (position: PlayerPosition) => {
  switch (position) {
    case PlayerPosition.WideReceiver:
      return FantasyPosition.WR
    case PlayerPosition.TightEnd:
      return FantasyPosition.TE
    case PlayerPosition.Quarterback:
      return FantasyPosition.QB
    default:
      return FantasyPosition.WR
  }
}
