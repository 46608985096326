/* eslint-disable @typescript-eslint/naming-convention, camelcase */
import { MDSLeagueSettings } from '@pff-consumer/schema'

type PositionsMap = {
  [key: string]: string[]
}

const positionsMap: PositionsMap = {
  starting_WRRB: ['WR', 'RB'],
  starting_WRTE: ['WR', 'TE'],
  starting_flex: ['WR', 'RB', 'TE'],
}

export const mapAdditionalFlexPositionsWithFlex = (leagueSettings: MDSLeagueSettings) => {
  const { starting_WRRB = 0, starting_WRTE = 0, ...withOutAdditionalFlex } = leagueSettings
  const { starting_flex = 0 } = withOutAdditionalFlex

  const wrrbArr = new Array(starting_WRRB).fill(positionsMap.starting_WRRB)
  const wrteArr = new Array(starting_WRTE).fill(positionsMap.starting_WRTE)
  const flexArr = new Array(starting_flex as number).fill(positionsMap.starting_flex)

  // Combine all additional flex positions
  const combinedFlex = [...wrrbArr, ...wrteArr, ...flexArr]

  return { ...withOutAdditionalFlex, starting_flex: combinedFlex }
}
