import { FantasyPosition, LABEL_FANTASY_POSITION_DT } from '@pff-consumer/schema'

export const getRankingsPlayersPosition = (position: string) => {
  switch (position) {
    case FantasyPosition.DEF:
      return FantasyPosition.DST
    case FantasyPosition.DT:
      return LABEL_FANTASY_POSITION_DT
    default:
      return position
  }
}
