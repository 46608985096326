import { DraftPickStatus, DraftersInfo, Drafter, DraftTypes } from '@pff-consumer/schema'
import { Keeper } from '@pff-consumer/schema/fantasy-live-draft/keeper'

interface TeamNameMap {
  [key: string]: string
}

export const getTeams = (fantasyPlayersCount: number, userPick: number): string[] => {
  const teamNames = []
  let count = 1
  while (count <= fantasyPlayersCount) {
    if (count === userPick) {
      teamNames.push('Your team')
    } else {
      teamNames.push(`Team ${count}`)
    }
    count++
  }
  return teamNames
}

// TODO: Convert this to support 3RR and other pick formats when required
export const generateDraftPicksList = (
  rounds: number,
  fantasyPlayersCount: number,
  userPick: number,
  draftType: string,
  drafters: Drafter[],
  keepers: Keeper[]
): DraftersInfo[] => {
  const teamNames = getTeams(fantasyPlayersCount, userPick)
  const reversedTeamNames = teamNames.slice().reverse()
  const picksInfo: any = []
  let pendingRounds = 1

  if (draftType === DraftTypes.LINEAR) {
    while (pendingRounds <= rounds) {
      teamNames.forEach((team) => {
        picksInfo.push({
          userTeamName: team,
        })
      })
      pendingRounds++
    }
  } else if (draftType === DraftTypes.THIRD_ROUND_REVERSAL) {
    while (pendingRounds <= rounds) {
      const normalTraversal = pendingRounds === 1 || (pendingRounds >= 4 && pendingRounds % 2 === 0)
      // left to right in snake draft for rounds 1 & 4,6,8...
      if (normalTraversal) {
        teamNames.forEach((team) => {
          picksInfo.push({
            userTeamName: team,
          })
        })
      } else {
        // right to left in snake draft for rounds 2,3 & 5,7,9...
        reversedTeamNames.forEach((team) => {
          picksInfo.push({
            userTeamName: team,
          })
        })
      }
      pendingRounds++
    }
  } else {
    while (pendingRounds <= rounds) {
      // Right to letf in snake draft
      if (pendingRounds % 2 !== 0) {
        teamNames.forEach((team) => {
          picksInfo.push({
            userTeamName: team,
          })
        })
      } else {
        // Left to right in snake draft
        reversedTeamNames.forEach((team) => {
          picksInfo.push({
            userTeamName: team,
          })
        })
      }
      pendingRounds++
    }
  }

  const draftPicks: DraftersInfo[] = picksInfo.map((data: DraftersInfo, index: number) => {
    return {
      ...data,
      pick: index + 1,
      status: DraftPickStatus.PENDING,
    }
  })

  for (let i = 0; i < keepers.length; i++) {
    const keeper = keepers[i]
    const keeperPickIndex = draftPicks.findIndex((pick) => pick.pick === keeper.pickNumber)

    if (
      Number.isInteger(keeperPickIndex) &&
      draftPicks[keeperPickIndex] &&
      draftPicks[keeperPickIndex].status === DraftPickStatus.PENDING
    ) {
      const pickData = {
        ...draftPicks[keeperPickIndex],
        status: DraftPickStatus.KEEPER,
        keeperPlayerId: keeper.playerId,
      }

      draftPicks[keeperPickIndex] = pickData
    }
  }

  // Map the draftee names for the first round using the pick number
  const teamNameMap: TeamNameMap = draftPicks.slice(0, fantasyPlayersCount).reduce((acc: TeamNameMap, draftee) => {
    const map = { ...acc }
    const matchedPickNumber = drafters.find((item) => item.pickNumber === draftee.pick)
    map[draftee.userTeamName] = matchedPickNumber ? matchedPickNumber.drafteeName : draftee.userTeamName
    return map
  }, {})

  // Map the draftee names for all the rounds
  const transformedDraftPicks = draftPicks.map((item: any) => ({
    ...item,
    userTeamName: teamNameMap[item.userTeamName],
  }))

  return transformedDraftPicks
}
