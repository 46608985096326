import type { FootballStatKey } from '@pff-consumer/schema'

const descriptorMap: { [key: string]: string } = {
  pass_yd: 'YDS',
  pass_att: 'ATT',
  pass_comp: 'COMP',
  pass_td: 'TD',
  pass_int: 'INT',
  recv_rec: 'REC',
  recv_yd: 'YDS',
  targets: 'TGT',
  rush_att: 'ATT',
  rush_yd: 'YDS',
  rush_recv_yd: 'YDS',
  any_time_td: 'TD',
  longest_rush: 'Long Rush',
  rush_yd_att: 'YDS/ATT',
  longest_reception: 'Long Rec',
  recv_yd_rec: 'YDS/REC',
  longest_pass: 'Long Pass',
  kick_points: 'Kicking Pts',
  fg_made: 'FG',
  fg_att: 'FG ATT',
}

export const getPropDescriptor = (prop: FootballStatKey) => descriptorMap[prop] || ''
