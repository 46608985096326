import { includes, some } from 'lodash'
import type { User } from '@pff-consumer/schema'
import { CONSUMER_PREMIUM_SUBSCRIPTION } from './subscriptions'
import { isExpired } from './isExpired'

export const checkHasCurrentSubscription = (user: User | null) => {
  const subscriptions = user?.subscriptions || []

  // Check if the user has the right role AND it is NOT expired
  return some(subscriptions, (sub) => includes(sub?.roles, CONSUMER_PREMIUM_SUBSCRIPTION) && !isExpired(sub?.end_date))
}
