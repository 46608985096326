import { getSession } from 'next-auth/react'

const INTERNAL_USER = 'PFF FT Staff'
export const getUserType = async (): Promise<string> => {
  const session = await getSession()
  // @ts-ignore
  const pffGroup: string[] = session?.user?.groups || []

  return pffGroup.includes(INTERNAL_USER) ? 'PFF Internal' : 'External'
}
