import { useFeatureFlags } from './use-feature-flags'
import { FeatureFlagLensType, FeatureFlagLensProps } from '../shared/feature-flag-types'

export const FeatureFlagLens: FeatureFlagLensType = (props: FeatureFlagLensProps) => {
  const { children, flag } = props
  const { getFeatureFlag } = useFeatureFlags()

  if (getFeatureFlag<boolean>(flag, false)) {
    return children
  }

  return null
}
