import { FantasyScoringTypeEnum, FantasyProjectionScoringType } from '@pff-consumer/schema/fantasy/fantasy'

export const getProjectionsScoringType = (scoringType: FantasyScoringTypeEnum): FantasyProjectionScoringType | null => {
  const scoringTypeMap: Record<string, FantasyProjectionScoringType | null> = {
    [FantasyScoringTypeEnum.REDRAFT_PPR]: FantasyScoringTypeEnum.PPR,
    [FantasyScoringTypeEnum.REDRAFT_HALF_PPR]: FantasyScoringTypeEnum.ZERO_POINT_FIVE_PPR,
    [FantasyScoringTypeEnum.REDRAFT_NON_PPR]: FantasyScoringTypeEnum.STANDARD,
    [FantasyScoringTypeEnum.REDRAFT_2QB_PPR]: FantasyScoringTypeEnum.PPR,
    [FantasyScoringTypeEnum.REDRAFT_IDP]: null,
    [FantasyScoringTypeEnum.DYNASTY_STARTUP]: FantasyScoringTypeEnum.PPR,
    [FantasyScoringTypeEnum.DYNASTY_ROOKIE]: FantasyScoringTypeEnum.PPR,
    [FantasyScoringTypeEnum.DYNASTY_2QB_STARTUP]: FantasyScoringTypeEnum.PPR,
    [FantasyScoringTypeEnum.DYNASTY_2QB_ROOKIE]: FantasyScoringTypeEnum.PPR,
    [FantasyScoringTypeEnum.DYNASTY_IDP_STARTUP]: null,
    [FantasyScoringTypeEnum.DYNASTY_IDP_ROOKIE]: null,
    [FantasyScoringTypeEnum.BEST_BALL]: FantasyScoringTypeEnum.ZERO_POINT_FIVE_PPR,
    [FantasyScoringTypeEnum.PPR]: FantasyScoringTypeEnum.PPR,
    [FantasyScoringTypeEnum.PPR_SUPERFLEX]: FantasyScoringTypeEnum.PPR,
    [FantasyScoringTypeEnum.ZERO_POINT_FIVE_PPR]: FantasyScoringTypeEnum.ZERO_POINT_FIVE_PPR,
    [FantasyScoringTypeEnum.ZERO_POINT_FIVE_PPR_SUPERFLEX]: FantasyScoringTypeEnum.ZERO_POINT_FIVE_PPR,
    [FantasyScoringTypeEnum.STANDARD]: FantasyScoringTypeEnum.STANDARD,
    [FantasyScoringTypeEnum.STANDARD_SUPERFLEX]: FantasyScoringTypeEnum.STANDARD,
    [FantasyScoringTypeEnum.IDP]: FantasyScoringTypeEnum.IDP,
  }
  if (scoringType in scoringTypeMap) {
    return scoringTypeMap[scoringType]
  }
  return null
}
