type ColorNames =
  | 'divider'
  | 'background'
  | 'white'
  | 'white15'
  | 'white20'
  | 'white50'
  | 'aqua'
  | 'blue'
  | 'blue50'
  | 'blue100'
  | 'cyan300'
  | 'red'
  | 'red2'
  | 'red3'
  | 'red300'
  | 'red350'
  | 'lightBlue'
  | 'gradeA'
  | 'gradeB'
  | 'green'
  | 'green3'
  | 'yellow'
  | 'orange'
  | 'gray50'
  | 'gray100'
  | 'gray150'
  | 'gray200'
  | 'gray250'
  | 'gray300'
  | 'gray350'
  | 'gray400'
  | 'gray450'
  | 'gray500'
  | 'gray550'
  | 'gray600'
  | 'gray650'
  | 'gray700'
  | 'gray750'
  | 'gray800'
  | 'gray850'
  | 'gray900'
  | 'gray950'
  | 'gray1000'
  | 'gray1050'
  | 'gray1100'
  | 'gray1150'
  | 'gray1200'
  | 'gray1250'
  | 'black'
  | 'black30'
  | 'black70'
  | 'black85'
  | 'black90'
  | 'linkBlue'
  | 'divider65'
  | 'darkGray'
  | 'lightWhiteText'
  | 'footerText'
  | 'toggleGray'
  | 'toggleLightGray'
  | 'toggleDarkGray'
  | 'matchupPoor'
  | 'matchupAverage'
  | 'matchupGreat'
  | 'matchupPoorBackground'
  | 'matchupAverageBackground'
  | 'matchupGreatBackground'
  | 'fantasyEmptyStateBackground'
  | 'successToastBorder'
  | 'errorToastBorder'
  | 'infoToastBorder'
  | 'warningToastBorder'
  | 'toastGreyBackground'
  | 'green300'
  | 'green500'
  | 'yellow500'
  | 'red500'

export type ColorsDEPRECATED = Record<ColorNames, string>

export const colorsDEPRECATED: ColorsDEPRECATED = {
  divider: '#222222',
  background: '#222222',
  white: '#FFFFFF',
  white15: 'rgba(255,255,255,.15)',
  white20: 'rgba(255,255,255,.2)',
  white50: 'rgba(255,255,255,.5)',
  aqua: '#00EECC',
  blue: '#0000FF',
  blue50: '#0A1B33',
  blue100: '#1A4480',
  cyan300: '#44C8D7',
  red: '#EE0033',
  red2: '#FF374F',
  red3: '#77011A',
  red300: '#DE2E21',
  red350: '#FC1D1D',
  red500: '#320606',
  lightBlue: '#3388FF',
  gradeA: '#00CC66',
  gradeB: '#97CC00',
  green: '#01C763',
  green3: '#2DD287',
  yellow: '#FFDD00',
  orange: '#FF5100',
  gray50: '#F4F4F4',
  gray100: '#E8E8E8',
  gray150: '#DDDDDD',
  gray200: '#D2D2D2',
  gray250: '#C7C7C7',
  gray300: '#BBBBBB',
  gray350: '#B0B0B0',
  gray400: '#A5A5A5',
  gray450: '#999999',
  gray500: '#8E8E8E',
  gray550: '#808080',
  gray600: '#727272',
  gray650: '#636363',
  gray700: '#555555',
  gray750: '#474747',
  gray800: '#393939',
  gray850: '#2B2B2B',
  gray900: '#1C1C1C',
  gray950: '#0E0E0E',
  gray1000: '#3D3D3D',
  gray1050: '#595959',
  gray1100: '#828282',
  gray1150: '#343434',
  gray1200: '#A7A7A7',
  gray1250: '#E1E1E1',
  green300: '#12C56A',
  green500: '#042715',
  yellow500: '#332808',
  black: '#000000',
  black30: 'rgba(0,0,0,.3)',
  black70: 'rgba(0,0,0,.7)',
  black85: 'rgba(0,0,0,.85)',
  black90: '#262626',
  linkBlue: '#4593E8',
  divider65: 'rgba(84, 84, 88, 0.65)',
  darkGray: '#161719',
  lightWhiteText: '#8A8A8A',
  footerText: '#959595',
  toggleGray: '#767577',
  toggleLightGray: '#f4f3f4',
  toggleDarkGray: '#3e3e3e',
  matchupPoor: 'rgb(255, 81, 0)',
  matchupAverage: 'rgb(255, 221, 0)',
  matchupGreat: 'rgb(0, 204, 102)',
  matchupPoorBackground: '#331000',
  matchupAverageBackground: '#423900',
  matchupGreatBackground: '#00351B',
  fantasyEmptyStateBackground: '#2D2D2D',
  successToastBorder: '#0FA659',
  errorToastBorder: '#DE2E21',
  infoToastBorder: '#3388FF',
  warningToastBorder: '#FFC629',
  toastGreyBackground: '#3F3F3F',
}
