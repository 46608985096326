import { FantasyDisplayPosition } from '@pff-consumer/schema'

export function isFlexPosition(position: FantasyDisplayPosition) {
  return [
    FantasyDisplayPosition.FLEX,
    FantasyDisplayPosition.SUPER_FLEX,
    FantasyDisplayPosition.WRRB_FLEX,
    FantasyDisplayPosition.REC_FLEX,
  ].includes(position)
}
