import { chain } from 'lodash'
import { SDKEvent } from '@pff-consumer/schema'
import { formatDayMonthDate } from '../format/format-date'

export const groupEventsByMatchDay = (events: SDKEvent[]) => {
  const eventsWithDay = events
    .map((event) => {
      const eventDay = formatDayMonthDate(event.startTimestamp)
      return { ...event, eventDay }
    })
    .sort((a, b) => a.startTimestamp - b.startTimestamp)

  return chain(eventsWithDay)
    .groupBy('eventDay')
    .map((dayEvents, eventDay) => {
      return { eventDay, dayEvents }
    })
    .compact()
    .value()
}
