import { ProviderConfig, withLDProvider } from 'launchdarkly-react-client-sdk'
import { envConfig } from '@pff-consumer/utils'

const { LD_CLIENT_SIDE_ID } = envConfig

const params = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '')

const config: ProviderConfig = {
  clientSideID: LD_CLIENT_SIDE_ID!,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
}

const session = params.get('session')
if (session) {
  config.user = {
    key: session,
  }
}

// SYNC
export const withFeatureFlagProvider = () => withLDProvider(config)
