import { PFFKit, Sportsbook, Week, PlayerPropMarket } from '@pro-football-focus/pffkit-node-client'
import { PlayerMatchup, PlayerPosition, ReceiverMatchups } from '@pro-football-focus/pffkit-js-core'
import { envConfig } from '@pff-consumer/utils'

import {
  SDKWeek,
  RecentDefensesForEvent,
  SDKEvent,
  FootballPlayer,
  NFLTeam,
  SDKPlayerPropGradeAnalysis,
  PlayerPropKey,
  PlayerPropStat,
  HistoricalPerformance,
  BiggestPlayerMismatch,
  SDK_SEASON,
  RunningBackType,
} from '@pff-consumer/schema'
import { Matchup } from '@pff-consumer/schema/sdk/matchups'
import { DEFAULT_SPORTSBOOK } from '@pff-consumer/api-sdk'
import { compact } from 'lodash'
import {
  eventToSDKEvent,
  playerToFootballPlayer,
  teamToNFLTeam,
  playerPropToSDKPlayerProp,
  recentDefenseListsToRecentDefensesForTeam,
  hotPlayerToPlayerPropStat,
  pffKitHistoricalPerformanceToHistoricalPerformance,
  playerMismatchToPlayerBiggestPlayerMismatch,
  cbWrMapToGenericMatchupObject,
  teMatchupMapToGenericMatchupObject,
  qbMatchupMapToGenericMatchupObject,
  runningBackReceivingMatchupMapToGenericMatchupObject,
  runningBackRushingMatchupMapToGenericMatchupObject,
} from './mappers'

export { PFFKitError } from '@pro-football-focus/pffkit-node-client'

const { CONSUMER_API_KEY, CONSUMER_API_URL } = envConfig
const baseURL = new URL('/', CONSUMER_API_URL)

const pffKit = new PFFKit({ name: 'voodoo', baseURL, apiKey: CONSUMER_API_KEY })

const getWeekFromWeekAbv = (weekAbv?: string): Week | undefined => {
  return Object.values(Week).some((wk: string) => wk === weekAbv) ? <Week>weekAbv : undefined
}

const getSportsbookFromId = (sportsbookId: string): Sportsbook | undefined => {
  return Object.values(Sportsbook).some((id: string) => id === sportsbookId) ? <Sportsbook>sportsbookId : undefined
}

const getPlayerPropMarketFromPropKey = (propKey: PlayerPropKey): PlayerPropMarket | undefined => {
  const key = propKey.toUpperCase()
  return Object.values(PlayerPropMarket).some((market) => market === key) ? <PlayerPropMarket>key : undefined
}

export const PffKitService = (() => {
  return {
    getEvents: async (
      season: number,
      sportsbook: string | undefined,
      weekAbv: SDKWeek['abbreviation'],
      includeScoringData = true
    ): Promise<SDKEvent[]> => {
      const sb = getSportsbookFromId(sportsbook || DEFAULT_SPORTSBOOK)
      if (!sb) return []

      const events = await pffKit.getEventsForSeason(season, sb, includeScoringData, getWeekFromWeekAbv(weekAbv))
      return events.map((e) => eventToSDKEvent(e))
    },

    getEvent: async (
      eventId: number,
      sportsbook: string | undefined,
      includeScoringData = true
    ): Promise<SDKEvent | null> => {
      const sb = getSportsbookFromId(sportsbook || DEFAULT_SPORTSBOOK)
      if (!sb) return null

      const event = await pffKit.getEventWithID(eventId, sb, includeScoringData)
      return eventToSDKEvent(event)
    },

    getTeamsForSeason: async (season: number = SDK_SEASON): Promise<NFLTeam[]> => {
      const teams = await pffKit.getTeamsForSeason(season)
      return teams.map((t) => teamToNFLTeam(t, season))
    },

    getPlayer: async (playerId: number): Promise<FootballPlayer> => {
      const player = await pffKit.getPlayerProfile(playerId)
      return playerToFootballPlayer(player)
    },

    getPlayerPropsForEvent: async (
      eventId: number,
      sportsbook: string | undefined,
      detailed: boolean = false
    ): Promise<SDKPlayerPropGradeAnalysis[]> => {
      const sb = getSportsbookFromId(sportsbook || DEFAULT_SPORTSBOOK)
      if (!sb) return []

      const props = await pffKit.getPlayerPropsForEvent(eventId, sb, detailed)

      return props.map((p) => playerPropToSDKPlayerProp(p))
    },

    getRecentDefensesForEvent: async (eventId: number): Promise<RecentDefensesForEvent> => {
      const recentDefenseLists = await pffKit.getRecentDefensesForEvent(eventId)

      return { eventId, recentDefenses: recentDefenseListsToRecentDefensesForTeam(recentDefenseLists) }
    },

    getHotPlayersForEvent: async (
      eventId: number,
      sportsbook: string | undefined,
      propKeys: PlayerPropKey[]
    ): Promise<PlayerPropStat[]> => {
      const sb = getSportsbookFromId(sportsbook || DEFAULT_SPORTSBOOK)
      if (!sb) return []

      const markets = compact(propKeys.map((p) => getPlayerPropMarketFromPropKey(p)))
      const hotPlayers = await pffKit.getHotPlayersForEvent(eventId, sb, markets)

      return compact(hotPlayers.map((hp) => hotPlayerToPlayerPropStat(hp)))
    },

    getHotPlayersForWeek: async (
      season: number | undefined,
      weekAbv: SDKWeek['abbreviation'] | undefined,
      sportsbook: string | undefined,
      propKeys: PlayerPropKey[]
    ): Promise<PlayerPropStat[]> => {
      const sb = getSportsbookFromId(sportsbook || DEFAULT_SPORTSBOOK)
      const week = getWeekFromWeekAbv(weekAbv)
      if (!sb || !week) return []

      const markets = compact(propKeys.map((p) => getPlayerPropMarketFromPropKey(p)))
      const hotPlayers = await pffKit.getHotPlayersForSeason(season || SDK_SEASON, week, sb, markets)

      return compact(hotPlayers.map((hp) => hotPlayerToPlayerPropStat(hp)))
    },

    getPlayerHistoricalPerformances: async (
      playerId: number,
      propKey: PlayerPropKey,
      eventIds: number[]
    ): Promise<HistoricalPerformance[]> => {
      const market = getPlayerPropMarketFromPropKey(propKey)
      if (!market) return []

      const performances = await pffKit.getHistoricalPerformancesForPlayer(playerId, market, eventIds)

      return performances.map((p) => pffKitHistoricalPerformanceToHistoricalPerformance(p))
    },

    getPlayerMismatchesForEvent: async (
      eventId: number,
      sportsbook: string | undefined,
      propKeys: PlayerPropKey[]
    ): Promise<BiggestPlayerMismatch[]> => {
      const sb = getSportsbookFromId(sportsbook || DEFAULT_SPORTSBOOK)
      const markets = compact(propKeys.map((p) => getPlayerPropMarketFromPropKey(p)))
      if (!sb || !markets.length) return []

      const mismatches = await pffKit.getMismatchesForEvent(eventId, sb, markets)

      return compact(mismatches.map((m) => playerMismatchToPlayerBiggestPlayerMismatch(m)))
    },

    getPlayerMismatchesForWeek: async (
      season: number | undefined,
      weekAbv: SDKWeek['abbreviation'] | undefined,
      sportsbook: string | undefined,
      propKeys: PlayerPropKey[]
    ): Promise<BiggestPlayerMismatch[]> => {
      const sb = getSportsbookFromId(sportsbook || DEFAULT_SPORTSBOOK)
      const markets = compact(propKeys.map((p) => getPlayerPropMarketFromPropKey(p)))
      const week = getWeekFromWeekAbv(weekAbv)
      if (!sb || !week || !markets.length) return []

      const mismatches = await pffKit.getMismatchesForSeason(season || SDK_SEASON, week, sb, markets)

      return compact(mismatches.map((m) => playerMismatchToPlayerBiggestPlayerMismatch(m)))
    },

    getMatchupsForWeek: async (
      season: number,
      weekAbv: SDKWeek['abbreviation'] | undefined
    ): Promise<PlayerMatchup[]> => {
      const week = getWeekFromWeekAbv(weekAbv)
      if (!week) return []

      const matchups = await pffKit.getPlayerMatchupsForWeek(season, week)
      return matchups
    },
    getMatchupsForPlayer: async (playerId: number, eventId: number): Promise<PlayerMatchup[]> => {
      const matchupsForPlayer = await pffKit.getMatchupsForPlayer(playerId, eventId)
      return matchupsForPlayer
    },
    getReceiverMatchupsForWeek: async (
      season: number,
      weekAbv: SDKWeek['abbreviation'] | undefined
    ): Promise<ReceiverMatchups[]> => {
      const week = getWeekFromWeekAbv(weekAbv)
      if (!week) return []

      const receiverMatchups = await pffKit.getReceiverMatchupsForWeek(season, week)
      return receiverMatchups
    },
    getMatchupsForReceiver: async (receiverId: number, eventId: number): Promise<ReceiverMatchups> => {
      const matchupsForReceiver = await pffKit.getMatchupsForReceiver(receiverId, eventId)
      return matchupsForReceiver
    },
    // a separate function for RB matchups
    // since PFFKit - `PlayerPosition` type doesn't have a RB
    getMatchupsForRunningBack: async (
      type: RunningBackType,
      season: number,
      weekAbv: SDKWeek['abbreviation'] | undefined
    ): Promise<Matchup[]> => {
      const week = getWeekFromWeekAbv(weekAbv)
      if (!week) return []

      switch (type) {
        case RunningBackType.RECEIVING: {
          const receivingMatchups = await pffKit.getRunningBackReceivingMatchupsForWeek(season, week)
          return runningBackReceivingMatchupMapToGenericMatchupObject(receivingMatchups)
        }
        case RunningBackType.RUSHING: {
          const rushingMatchups = await pffKit.getRunningBackRushingMatchupsForWeek(season, week)
          return runningBackRushingMatchupMapToGenericMatchupObject(rushingMatchups)
        }
        default:
          return []
      }
    },
    getMatchups: async (
      position: PlayerPosition,
      season: number,
      weekAbv: SDKWeek['abbreviation'] | undefined
    ): Promise<Matchup[]> => {
      const week = getWeekFromWeekAbv(weekAbv)
      if (!week) return []

      switch (position) {
        case PlayerPosition.WideReceiver: {
          const teamMatchups = await pffKit.getTeamMatchupsForWeek(season, week)
          const receiverMatchups = await pffKit.getReceiverMatchupsForWeek(season, week)

          return cbWrMapToGenericMatchupObject(teamMatchups, receiverMatchups)
        }
        case PlayerPosition.TightEnd: {
          const tightEndMatchups = await pffKit.getTightEndMatchupsForWeek(season, week)
          return teMatchupMapToGenericMatchupObject(tightEndMatchups)
        }
        case PlayerPosition.Quarterback: {
          const quarterbackMatchups = await pffKit.getQuarterbackMatchupsForWeek(season, week)
          return qbMatchupMapToGenericMatchupObject(quarterbackMatchups)
        }
        default:
          return []
      }
    },
  }
})()
