import { NFLWeekAbbreviation, PostSeasonWeeksMap } from '@pff-consumer/schema'

export const getWeekHeaderText = (weekAbbr: NFLWeekAbbreviation | number | undefined | null, fantasyPage: string) => {
  if (weekAbbr === undefined || weekAbbr === null) {
    return ''
  }
  const weekAbbrStr = weekAbbr.toString() as Partial<NFLWeekAbbreviation>
  return PostSeasonWeeksMap[weekAbbrStr]
    ? `${PostSeasonWeeksMap[weekAbbrStr]} ${fantasyPage}`
    : `Week ${weekAbbr} ${fantasyPage}`
}
