import { DropdownOption, HostedSites, League } from '@pff-consumer/schema'

export const getDropdownOptions = (leagues: League[]): DropdownOption[] => {
  return leagues.map((league) => {
    return {
      name: league.name,
      value: league.name,
      icon: `${league.hosted_site === HostedSites.NONE ? 'manual' : league.hosted_site}-short-icon`,
    }
  })
}

export const getTeamDropdownOptions = (teamNames: string[]): DropdownOption[] => {
  return teamNames.map((team) => {
    return {
      name: team,
      value: team,
    }
  })
}
