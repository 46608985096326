import { InjuryStatus } from '../news/news'

export enum InjuryStatusAbbreviation {
  ACTIVE = 'ACTIVE',
  PROBABLE = 'PROB',
  DOUBTFUL = 'DOUBT',
  QUESTIONABLE = 'QUEST',
  OUT = 'OUT',
  INACTIVE = 'INACTIVE',
}

export const InjuryStatusAbbreviationMap = {
  [InjuryStatus.Active]: InjuryStatusAbbreviation.ACTIVE,
  [InjuryStatus.Probable]: InjuryStatusAbbreviation.PROBABLE,
  [InjuryStatus.Doubtful]: InjuryStatusAbbreviation.DOUBTFUL,
  [InjuryStatus.Questionable]: InjuryStatusAbbreviation.QUESTIONABLE,
  [InjuryStatus.Out]: InjuryStatusAbbreviation.OUT,
}

export const LIST_OF_INJURED_PLAYER_STATUS = [
  InjuryStatusAbbreviation.DOUBTFUL,
  InjuryStatusAbbreviation.OUT,
  InjuryStatusAbbreviation.PROBABLE,
  InjuryStatusAbbreviation.QUESTIONABLE,
]
