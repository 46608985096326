import React from 'react'
import { ThemeProvider } from 'react-jss'
import { theme } from '../styles/theme'
import { combinedColors } from '../styles/browser-colors'

interface WebUiProviderProps {
  children: React.ReactNode
  isBrowserTheme?: boolean
}

export const WebUiProvider = ({ children, isBrowserTheme = false }: WebUiProviderProps) => {
  if (isBrowserTheme) {
    theme.colors = combinedColors
  }
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
