import { ALL_CATEGORY } from '@pff-consumer/schema'

export const contentEndpoints = {
  feedCategories: () => {
    return { url: `/content/v1/feed-categories` }
  },
  feed: ({
    feedCategorySlug,
    start,
    limit,
    showPremium = false,
  }: {
    feedCategorySlug: string | undefined
    start: number
    limit: number
    showPremium: boolean
  }) => {
    const feedCategoryFilter: string =
      feedCategorySlug && feedCategorySlug !== ALL_CATEGORY.slug ? `feedCategories=${feedCategorySlug}&` : ''

    return {
      url: `/content/v1/feed?${feedCategoryFilter}start=${start}&limit=${limit}&showPremium=${showPremium}`,
    }
  },
  latestArticles: () => {
    return { url: `/content/v1/articles` }
  },
  articleById: ({ id }: { id: number }) => {
    return { url: `/content/v1/articles/${id}` }
  },
  articleBySlug: ({ slug }: { slug: string }) => {
    return { url: `/content/v1/articles?slugs=["${slug}"]` }
  },
  bookmarkedArticles: ({ ids }: { ids: number[] }) => {
    const stringifiedIds = JSON.stringify(ids)
    return { url: `/content/v1/articles?ids=${stringifiedIds}&abbreviated=true` }
  },
  ladningPageContent: ({ pageTopic }: { pageTopic: string }) => {
    return { url: `/content/v1/landing-pages/${pageTopic}` }
  },
}
