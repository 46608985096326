import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, format } from 'date-fns'
import { isNaN } from 'lodash'

export const convertPublishTime = (publishedAt: number | undefined): string => {
  if (publishedAt === undefined) return ''

  const milliFormat = `${publishedAt}`.length > 12 ? publishedAt : publishedAt * 1000 // assumes a date more recent than 2001
  const publishedAtDate: Date = new Date(milliFormat) // convert seconds to milliseconds
  const now: Date = new Date(Date.now())

  const secondDifference = differenceInSeconds(now, publishedAtDate, { roundingMethod: 'floor' })
  const minuteDifference = differenceInMinutes(now, publishedAtDate, { roundingMethod: 'floor' })
  const hourDifference = differenceInHours(now, publishedAtDate, { roundingMethod: 'floor' })
  const dayDifference = differenceInDays(now, publishedAtDate)

  if (secondDifference < 60) return `Less than a minute ago`
  if (minuteDifference < 60) return `${Math.abs(minuteDifference)} minute${minuteDifference === 1 ? '' : 's'} ago`
  if (hourDifference < 24) return `${Math.abs(hourDifference)} hour${hourDifference === 1 ? '' : 's'} ago`
  if (dayDifference < 8) return `${Math.abs(dayDifference)} day${dayDifference === 1 ? '' : 's'} ago`

  return !isNaN(publishedAtDate) ? format(publishedAtDate, 'LLLL d, yyyy') : ''
}
