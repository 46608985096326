import { combineReducers, configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { FLUSH, PAUSE, PERSIST, PersistConfig, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import { ACCOUNT_KEY, accountSlice } from '../lib/account/account.slice'
import { consumerApi } from '../lib/consumer-api/consumer-api'
import { fantasySyncApi } from '../lib/fantasy-sync/fantasy-sync-api'
import { fantasyLdaMdsApi } from '../lib/fantasy-lda-mds/fantasy-lda-mds-api'
import { FANTASY_FILTER_KEY, fantasySlice, FantasyState } from '../lib/fantasy/fantasy.slice'
import { WEB_VIEW_KEY, webViewSlice } from '../lib/web-view/web-view.slice'
import { BOTTOM_DRAWER_KEY, bottomDrawerSlice } from '../lib/bottom-drawer/bottom-drawer.slice'
import { APP_STATE_KEY, appSlice } from '../lib/app/app.slice'
import { addFantasyRankingsListeners } from './listeners/fantasy-rankings'
import { addFantasyLdaSpecificListeners } from './listeners/fantasy-lda-specific'
import { addFantasyMDSSpecificListeners } from './listeners/fantasy-mds-specific'
import { addFantasyDraftRoomSpecificListeners } from './listeners/fantasy-draft-room'
import { fantasyInSeasonApi } from '../lib/fantasy-in-season-api/fantasy-in-season-api'
import { addFantasyInSeasonSpecificListeners } from './listeners/fantasy-in-season/in-season-specific-listeners'
import { addFantasyStandaloneRankingsListeners } from './listeners/fantasy-standalone-rankings-listener'
import { addFantasyMatchupsListeners } from './listeners/fantasy-matchups'

declare const process: any

declare global {
  interface Window {
    mdsDelayTime?: number
  }
}

const persistFantasyConfig: PersistConfig<FantasyState> = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['hasShownCheatsheetSurvey'],
}

const baseReducers = {
  [consumerApi.reducerPath]: consumerApi.reducer,
  [fantasySyncApi.reducerPath]: fantasySyncApi.reducer,
  [fantasyLdaMdsApi.reducerPath]: fantasyLdaMdsApi.reducer,
  [FANTASY_FILTER_KEY]: persistReducer(persistFantasyConfig, fantasySlice.reducer),
  [ACCOUNT_KEY]: accountSlice.reducer,
  [fantasyInSeasonApi.reducerPath]: fantasyInSeasonApi.reducer,
}

const nativeAppReducers = {
  [WEB_VIEW_KEY]: webViewSlice.reducer,
  [BOTTOM_DRAWER_KEY]: bottomDrawerSlice.reducer,
  [APP_STATE_KEY]: appSlice.reducer,
}

const listenerMiddleware = createListenerMiddleware()

addFantasyRankingsListeners(listenerMiddleware.startListening)
addFantasyLdaSpecificListeners(listenerMiddleware.startListening)
addFantasyMDSSpecificListeners(listenerMiddleware.startListening)
addFantasyDraftRoomSpecificListeners(listenerMiddleware.startListening)
addFantasyInSeasonSpecificListeners(listenerMiddleware.startListening)
addFantasyStandaloneRankingsListeners(listenerMiddleware.startListening)
addFantasyMatchupsListeners(listenerMiddleware.startListening)

// Make sure we call next pick info
const devTools = process.env.NODE_ENV === 'development'

const middlewares = [
  consumerApi.middleware,
  fantasySyncApi.middleware,
  fantasyLdaMdsApi.middleware,
  fantasyInSeasonApi.middleware,
]

export const makeStore = () => {
  const reducers = combineReducers({ ...baseReducers, ...nativeAppReducers })
  const persistedReducer = reducers

  return configureStore({
    reducer: persistedReducer,
    devTools,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .prepend(listenerMiddleware.middleware)
        .concat(middlewares),
  })
}

export const makeWebStore = () => {
  const reducers = combineReducers(baseReducers)
  const persistedReducer = reducers

  return configureStore({
    reducer: persistedReducer,
    devTools,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .prepend(listenerMiddleware.middleware)
        .concat(middlewares),
  })
}

export const makeTestStore = (preloadedState?: any) => {
  const reducers = combineReducers({ ...baseReducers, ...nativeAppReducers })
  const persistedReducer = reducers

  return configureStore({
    reducer: persistedReducer,
    devTools,
    preloadedState,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  })
}
