import type { RankingsEntity } from '../fantasy.slice'

export const getPlayersFilteredByTags = (players: RankingsEntity[], tags: string[]): RankingsEntity[] => {
  if (tags.length === 0) {
    return players
  }
  return players.filter((player: any) => {
    return player.tags.some((tag: string) => tags.includes(tag))
  })
}
