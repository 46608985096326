import type { SDKEvent } from '@pff-consumer/schema'
import { camelCase, getDayAndTime, getTimeGroup, unCamelCase } from '../format/format'

export type GameDayGroup = Record<string, { ids: number[]; timestamp: number }>

export function removeExtraGroup(gameGroup: GameDayGroup) {
  const gameDayGroup = { ...gameGroup }
  Object.entries(gameGroup).forEach((entry) => {
    const [key, { ids }] = entry
    const [day, timeGroup] = unCamelCase(key).split(' ')
    if (timeGroup && ids.length === gameGroup[day.toLocaleLowerCase()].ids.length) {
      delete gameDayGroup[key]
    }
  })
  return gameDayGroup
}

export function groupGameByMatchDay(games: SDKEvent[]): GameDayGroup {
  let gameGroup: GameDayGroup = {}
  gameGroup = [...games]
    .sort((a, b) => (a.startTimestamp > b.startTimestamp ? -1 : 1))
    .reduce((acc, game) => {
      if (!game.startTimestamp) return acc
      const [day, time] = getDayAndTime(game.startTimestamp)
      const gameTime: string = `${day} ${getTimeGroup(time)}`
      ;[camelCase(day), camelCase(gameTime)].forEach((x, ix) => {
        const isDayGroup = ix === 0
        const startTimestamp = isDayGroup ? game.startTimestamp - 1 : game.startTimestamp

        // eslint-disable-next-line no-param-reassign
        acc[x] =
          acc[x] && acc[x].ids
            ? { ids: [...acc[x].ids, game.eventId], timestamp: startTimestamp }
            : { ids: [game.eventId], timestamp: startTimestamp }
      })
      return acc
    }, gameGroup)

  return removeExtraGroup(gameGroup)
}

export function getFiltersFromGameDayGroup(
  gameDayGroup: GameDayGroup,
  dayTimeGameMap: Record<string, number[]>
): { text: string; value: string; disabled: boolean }[] {
  return Object.keys(gameDayGroup)
    .sort((a, b) => (gameDayGroup[a]?.timestamp || 0) - (gameDayGroup[b]?.timestamp || 0))
    .map((key) => ({
      text: unCamelCase(key),
      value: key,
      disabled: !dayTimeGameMap[key],
    }))
}
