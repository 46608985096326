import type { SDKPlayerPropGradeAnalysis } from '@pff-consumer/schema'

/**
 * Converts an array of SDKPlayerPropGradeAnalysis to Record<string, SDKPlayerPropGradeAnalysis>
 * where key is the sportsbookBetId
 * This function is used by getPropBetsWithAnalysis to add analysis attribute to PaginatedPlayerPropBets
 */
export function getSportsbookIds(analysis: SDKPlayerPropGradeAnalysis[]) {
  return analysis.reduce<Record<string, SDKPlayerPropGradeAnalysis>>((acc, detail) => {
    const { sportsbookEventId, sportsbookMarketId, sportsbookOptionId } = detail
    const sportsbookBetId = `${sportsbookEventId}-${sportsbookMarketId}-${sportsbookOptionId}`
    return { ...acc, [sportsbookBetId]: detail }
  }, {})
}
