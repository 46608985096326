import { FantasyScoringTypeEnum, ManualLeagueSyncScoringTypeEnum } from '@pff-consumer/schema'

export const getScoringOptionDescription = (selectedButton: string) => {
  if (selectedButton === FantasyScoringTypeEnum.STANDARD || FantasyScoringTypeEnum.REDRAFT_NON_PPR) {
    return 'Standard (Non-PPR) is the most traditional.'
  }
  if (selectedButton === FantasyScoringTypeEnum.ZERO_POINT_FIVE_PPR || FantasyScoringTypeEnum.REDRAFT_HALF_PPR) {
    return 'Players are awarded an additional 0.5 points for every reception made.'
  }
  if (selectedButton === FantasyScoringTypeEnum.PPR || FantasyScoringTypeEnum.REDRAFT_PPR) {
    return 'Points Per Reception (PPR) increases the value of receivers and running backs who catch passes.'
  }

  return ''
}

export const getManualLeagueSettingsScoringOptionDescription = (selectedButton: string) => {
  if (selectedButton === ManualLeagueSyncScoringTypeEnum.STANDARD) {
    return 'Standard (Non-PPR) is the most traditional.'
  }
  if (selectedButton === ManualLeagueSyncScoringTypeEnum.ZERO_POINT_FIVE_PPR) {
    return 'Players are awarded an additional 0.5 points for every reception made.'
  }
  if (selectedButton === ManualLeagueSyncScoringTypeEnum.PPR) {
    return 'Points Per Reception (PPR) increases the value of receivers and running backs who catch passes.'
  }

  return ''
}
