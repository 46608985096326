import { PlayerPropKey } from '@pff-consumer/schema'

const ANALYSIS_SUPPORTED_OUTRIGHT_PROP_BET_TYPES: PlayerPropKey[] = [
  'any_time_td',
  'first_td',
  'last_td',
  'first_half_td',
  'second_half_td',
  'first_td_second_half',
  'first_team_td_scorer',
  'any_time_td',
  'two_plus_td',
  'three_plus_td',
]

const ANALYSIS_SUPPORTED_ALT_PROP_BET_TYPES: PlayerPropKey[] = [
  'alt_pass_yd',
  'alt_rush_yd',
  'alt_recv_yd',
  'first_completion',
  'first_reception',
  'first_rush_att',
  'most_pass_yd',
  'most_rush_yd',
]

export const ANALYSIS_SUPPORTED_PROP_BET_TYPES: PlayerPropKey[] = [
  'recv_rec',
  'recv_yd',
  'rush_att',
  'rush_yd',
  'pass_comp',
  'pass_att',
  'pass_yd',
  'pass_td',
  'pass_int',
  'pass_rush_yd',
  'longest_pass',
  'longest_reception',
  'longest_rush',
  ...ANALYSIS_SUPPORTED_OUTRIGHT_PROP_BET_TYPES,
  ...ANALYSIS_SUPPORTED_ALT_PROP_BET_TYPES,
]

export function showAnalytics(propType: PlayerPropKey) {
  return ANALYSIS_SUPPORTED_PROP_BET_TYPES.includes(propType)
}
