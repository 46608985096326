// These Routes are used for pff.com
export const Routes = {
  LDASettings: `/fantasy/draft/live-draft-assistant?step=settings`,
  LDADraft: `/fantasy/draft/live-draft-assistant?step=draft`,
  LDAResults: `/fantasy/draft/live-draft-assistant?step=results`,
  MDSDraft: `/fantasy/draft/mock-draft-simulator?step=draft`,
  MDSSettings: `/fantasy/draft/mock-draft-simulator?step=settings`,
  MDSResults: `/fantasy/draft/mock-draft-simulator?step=results`,
  LeagueSync: '/fantasy/league-sync',
  MyLeagues: '/fantasy/leagues',
  MDSInterstitialPage: '/fantasy/mock',
  MyTeamDashboard: '/fantasy/my-team',
  Home: '/',
  Login: '/login',
  CreateAccount: '/create-account',
  MockDraftSimulator: '/fantasy/draft/mock-draft-simulator',
  LiveDraftAssistant: '/fantasy/draft/live-draft-assistant',
  Fantasy: '/fantasy',
  Rankings: '/fantasy/rankings',
  Cheatsheet: '/fantasy/draft/cheat-sheet',
  WRCBMatchup: '/tools/wr_cb_matchup_chart',
  TEMatchup: '/tools/te_matchup_chart',
  OLDLMatchup: '/tools/oline_dline_matchup_chart',
  Draft: '/draft',
  Articles: '/fantasy/articles',
  Prep: '/fantasy/draft/prep',
  PlayerProfile: '/nfl/players/',
  GameView: '/nfl/scores/',
  TeamProfile: '/nfl/teams/',
}
