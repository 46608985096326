import type { DetailedEventViewWidgetType, PlayerPropKey } from '@pff-consumer/schema'
import { WIDGET_PROPKEY_MAP } from './player-prop-constants'

export function getAvailableWidgets(availableMarkets: PlayerPropKey[]) {
  return Object.entries(WIDGET_PROPKEY_MAP).reduce<Partial<Record<DetailedEventViewWidgetType, PlayerPropKey[]>>>(
    (acc, [key, value]) => {
      const availablePropKeys = value.filter((propKey) => availableMarkets.includes(propKey))
      if (availablePropKeys && availablePropKeys.length > 0) {
        return { ...acc, [key]: availablePropKeys }
      }
      return acc
    },
    {}
  )
}
