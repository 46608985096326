import type { User } from '@pff-consumer/schema'
import { addUserToHeap } from './heap'

export const addUser = (user: User): void => {
  try {
    addUserToHeap(user)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug(e)
  }
}
