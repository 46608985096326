import {
  FantasyPosition,
  FantasyDisplayPosition,
  ORDERED_FANTASY_POSITIONS,
  FantasyRoster,
  RosterItem,
  StartSitSuggestion,
  SuggestionDirection,
  MixedRosterItem,
  FootballGameStatus,
} from '@pff-consumer/schema'

import { filterBetterRankedFlexPlayers } from './filter-better-ranked-flex-players'
import { findBestRankedAvailablePlayer } from './find-best-ranked-available-player'
import { filterBetterRankedBenchPlayers } from './filter-better-ranked-bench-players'

export interface StartSitSuggestions {
  [key: number]: StartSitSuggestion
}

interface StarterSwapDetails {
  swapPlayerID: number
  swapPlayerName: string
  playerPosition: FantasyDisplayPosition
  acceptablePositions: FantasyPosition[]
}

interface StartersSwap {
  [key: number]: StarterSwapDetails
}

interface UpdatedRosterWithSuggestions {
  roster: FantasyRoster
  suggestions: StartSitSuggestions
}

const LARGE_RANK: number = 99999
let startersSwap: StartersSwap = {}
let overallSuggestions: StartSitSuggestions = {}
let hasNewSuggestions: boolean

const orderedFantasyPositions = ORDERED_FANTASY_POSITIONS

function sortOnRankAndFantasyPosition(player1: MixedRosterItem, player2: MixedRosterItem) {
  // Sorting in the order of rank (highest to lowest). This way the swap happens first for the highest ranked player
  // Once it is sorted on rank, we will have to sort based on fantasy positions. If there are multiple players with no rank,
  // then we need to process them in the order of fantasy positions
  const rankComparison = (player2.rank.current || LARGE_RANK) - (player1.rank.current || LARGE_RANK)

  if (rankComparison === 0) {
    const player1Position = orderedFantasyPositions.indexOf(player1.fantasyPosition)
    const player2Position = orderedFantasyPositions.indexOf(player2.fantasyPosition)
    return player1Position - player2Position
  }

  return rankComparison
}

const formStarterSwapObject = (swappedPlayer: RosterItem): StarterSwapDetails => {
  const isDST = swappedPlayer.position === FantasyPosition.DEF
  const name = isDST ? swappedPlayer.team : swappedPlayer.lastName
  const swappedPlayerDetails: StarterSwapDetails = {
    swapPlayerID: swappedPlayer.id,
    swapPlayerName: name,
    playerPosition: swappedPlayer.fantasyPosition,
    acceptablePositions: swappedPlayer.acceptablePositions!,
  }
  return swappedPlayerDetails
}

const optimizeStarters = (roster: FantasyRoster, currentWeek: number = 1): FantasyRoster => {
  const sortedStarters = [...roster.starters].sort(
    (a, b) => (b.rank.current || LARGE_RANK) - (a.rank.current || LARGE_RANK)
  )
  const consideredFLPlayers: Set<number> = new Set()
  startersSwap = {}

  // Iterate through starters to check for swaps
  // eslint-disable-next-line no-restricted-syntax
  for (const starter of sortedStarters) {
    // We need to try and swap only for players without a bye week & not in a flex position.
    if (
      starter.id === null ||
      starter.byeWeek === currentWeek ||
      starter.isFlexPosition ||
      starter.gameStatus !== FootballGameStatus.UPCOMING
    ) {
      continue
    }

    // Find if there is a lower ranked FL player that we can swap within the starter
    // For example - if WR position slot has a FL-35, while the FL position slot has a FL-10 player,
    // we can swap them and optimize the starters

    const betterRankedFlexPlayers = filterBetterRankedFlexPlayers(
      roster.starters,
      consideredFLPlayers,
      starter.position,
      starter.rank.current || LARGE_RANK
    )
    const betterRankedFLPlayer = findBestRankedAvailablePlayer(betterRankedFlexPlayers)

    if (betterRankedFLPlayer) {
      startersSwap[betterRankedFLPlayer.id] = formStarterSwapObject(starter)
      startersSwap[starter.id] = formStarterSwapObject(betterRankedFLPlayer)
      consideredFLPlayers.add(betterRankedFLPlayer.id)
    }
  }

  // Update the roster post the swap
  const updatedRoster = {
    ...roster,
    starters: roster.starters.map((player) => {
      if (player.id && startersSwap[player.id]) {
        return {
          ...player,
          fantasyPosition: startersSwap[player.id].playerPosition,
          isFlexPosition: !player.isFlexPosition,
          acceptablePositions: startersSwap[player.id].acceptablePositions,
        }
      }
      return player
    }),
  }
  return updatedRoster
}

const suggestPlayerSwapsBetweenStartersAndBench = (
  roster: FantasyRoster,
  currentWeek: number = 1
): UpdatedRosterWithSuggestions => {
  const sortedStarters = [...roster.starters].sort(sortOnRankAndFantasyPosition)

  const consideredBenchPlayers: Set<number> = new Set() // This is used so that we dont re-consider once the bench player has been swapped
  const updatedroster: FantasyRoster = { starters: [], bench: [] }
  // Iterate through starters to check for swaps
  // eslint-disable-next-line no-restricted-syntax
  for (const starter of sortedStarters) {
    // if we are dealing with an empty roster item we will skip them this helps
    // us not have to force everything with ! and gives us better typing
    // We will also not try to optimize for players whose game is not upcoming
    if (starter.id === null || starter.gameStatus !== FootballGameStatus.UPCOMING) {
      updatedroster.starters.push(starter)
      continue
    }

    // If the player or team is having a bye week, add that to the suggestions
    if (starter.byeWeek === currentWeek) {
      const isDST = starter.position === FantasyPosition.DST
      const teamOrPlayer = isDST ? 'Team' : 'Player'
      overallSuggestions[starter.id] = {
        suggestedPlayer: -1,
        reason: `${teamOrPlayer} is on a bye this week`,
        direction: SuggestionDirection.BENCH,
      }
      updatedroster.starters.push(starter)
      continue
    }

    const comparablePositions = starter.acceptablePositions!
    const betterRankedBenchPlayers = filterBetterRankedBenchPlayers(
      roster.bench,
      consideredBenchPlayers,
      comparablePositions,
      starter.rank.current || LARGE_RANK
    )
    const betterRankedPlayer = findBestRankedAvailablePlayer(betterRankedBenchPlayers)
    if (betterRankedPlayer) {
      const isStarterDST = starter.position === FantasyPosition.DEF
      const starterName = isStarterDST ? starter.team : starter.lastName

      const isBenchPlayerDST = betterRankedPlayer.position === FantasyPosition.DEF
      const benchPlayerName = isBenchPlayerDST ? betterRankedPlayer.team : betterRankedPlayer.lastName

      if (startersSwap[starter.id] && betterRankedPlayer.position !== starter.position) {
        // This is a two level swap, where you move a better ranked FL player to RB 1/2, WR 1/2 etc.,
        // and move a player from bench to this FL position
        const flexPlayerID = startersSwap[starter.id].swapPlayerID
        overallSuggestions[flexPlayerID] = {
          suggestedPlayer: betterRankedPlayer.id,
          reason: `Move ${benchPlayerName} to Flex position`,
          direction: SuggestionDirection.STARTER,
        }
        overallSuggestions[starter.id] = {
          suggestedPlayer: flexPlayerID,
          reason: `Swap ${starterName} for ${startersSwap[starter.id].swapPlayerName}`,
          direction: SuggestionDirection.BENCH,
        }
        overallSuggestions[betterRankedPlayer.id] = {
          suggestedPlayer: flexPlayerID,
          reason: `Move ${benchPlayerName} to Flex position`,
          direction: SuggestionDirection.STARTER,
        }
      } else {
        overallSuggestions[starter.id] = {
          suggestedPlayer: betterRankedPlayer.id,
          reason: `${benchPlayerName} is ranked higher than ${starterName}`,
          direction: SuggestionDirection.BENCH,
        }
        overallSuggestions[betterRankedPlayer.id] = {
          suggestedPlayer: starter.id,
          reason: `${benchPlayerName} is ranked higher than ${starterName}`,
          direction: SuggestionDirection.STARTER,
        }
      }
      hasNewSuggestions = true

      // We need to update the roster accordingly
      const starterPlayerToBeMovedToBench = {
        ...starter,
        fantasyPosition: FantasyDisplayPosition.BE,
        isFlexPosition: false,
        acceptablePositions: null,
      }
      updatedroster.bench.push(starterPlayerToBeMovedToBench)
      const benchPlayerToBeMovedAsStarter = {
        ...betterRankedPlayer,
        fantasyPosition: starter.fantasyPosition,
        isFlexPosition: starter.isFlexPosition,
        acceptablePositions: starter.acceptablePositions,
      }
      updatedroster.starters.push(benchPlayerToBeMovedAsStarter)
      consideredBenchPlayers.add(betterRankedPlayer.id)
    } else {
      updatedroster.starters.push(starter)
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const benchPlayer of roster.bench) {
    if (benchPlayer.id === null || !consideredBenchPlayers.has(benchPlayer.id)) {
      updatedroster.bench.push(benchPlayer)
    }
  }
  return { roster: updatedroster, suggestions: overallSuggestions }
}

export const optimizeLineup = (roster: FantasyRoster, currentWeek: number = 1) => {
  hasNewSuggestions = false
  overallSuggestions = {}
  let updatedRosterForOptimizedStarters = optimizeStarters(roster, currentWeek)
  let startSitSuggestions = suggestPlayerSwapsBetweenStartersAndBench(updatedRosterForOptimizedStarters, currentWeek)

  // We need to iteratively call to check if there is any further optimization possible
  while (hasNewSuggestions) {
    hasNewSuggestions = false
    updatedRosterForOptimizedStarters = optimizeStarters(startSitSuggestions.roster, currentWeek)
    startSitSuggestions = suggestPlayerSwapsBetweenStartersAndBench(updatedRosterForOptimizedStarters, currentWeek)
  }
  return startSitSuggestions
}
