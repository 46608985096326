import { DraftedPlayerResponse, DraftedTeamResult, MDSResultResponse } from '@pff-consumer/schema'

// This transform function, creates an array of objects, where each object corresponds to the drafted data by a team
// Each object further has a teamId, overallGrade of the team, and the drafted Players by the team
export const transformMdsResultApiResponse = (response: MDSResultResponse): DraftedTeamResult[] => {
  const draftedPlayersMap: { [key: number]: DraftedPlayerResponse[] } = {}

  response.draft.player_id.map((playerId) => {
    const filteredIndex = response.draft.player_id.indexOf(playerId)
    const fantasyTeam = response.draft.fantasy_team[filteredIndex]

    if (!(fantasyTeam in draftedPlayersMap)) {
      draftedPlayersMap[fantasyTeam] = []
    }

    const playerIndex = response.user_picks_with_grades.player_id.indexOf(playerId)

    draftedPlayersMap[fantasyTeam].push({
      id: response.draft.player_id[filteredIndex],
      draftPick: response.draft.draft_pick[filteredIndex],
      fantasyTeamId: response.draft.fantasy_team[filteredIndex],
      pickGradeLetter: response.user_picks_with_grades.pick_grade_letter[playerIndex],
    })

    return null
  })

  const draftedTeamsResult = Object.entries(draftedPlayersMap).map(([fantasyTeam, draftedPlayers]) => ({
    fantasyTeamId: Number(fantasyTeam),
    overallGrade: response.overall_grades[Number(fantasyTeam) - 1][1],
    draftedPlayers,
  }))
  return draftedTeamsResult
}
