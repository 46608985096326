export const auctionValuePlayerIdMap: Record<number, number> = {
  57165: 1,
  2241: 1,
  8288: 2,
  146712: 1,
  45817: 1,
  48135: 1,
  11822: 18,
  9437: 18,
  84470: 52,
  109811: 1,
  124393: 11,
  78050: 2,
  12164: 5,
  46518: 1,
  123126: 53,
  145159: 1,
  8655: 3,
  84109: 23,
  97636: 51,
  146715: 6,
  77672: 3,
  144970: 2,
  129759: 12,
  61071: 1,
  48262: 14,
  57206: 1,
  61570: 55,
  84000: 1,
  11839: 16,
  84355: 33,
  47546: 20,
  11763: 54,
  57373: 3,
  57279: 1,
  60995: 2,
  48164: 6,
  11795: 4,
  57212: 13,
  45734: 1,
  11844: 1,
  10769: 8,
  47272: 3,
  91791: 17,
  47304: 2,
  83659: 1,
  25578: 1,
  48236: 2,
  8688: 33,
  45719: 10,
  11784: 6,
  124305: 30,
  7808: 6,
  48274: 26,
  83774: 1,
  8671: 1,
  10679: 31,
  11767: 1,
  122624: 1,
  40485: 7,
  145773: 1,
  33441: 14,
  99711: 3,
  86519: 37,
  88664: 1,
  77763: 1,
  11778: 12,
  10638: 4,
  7820: 1,
  11901: 9,
  99229: 20,
  11799: 1,
  42237: 1,
  46027: 4,
  47294: 1,
  83806: 1,
  83955: 1,
  83747: 31,
  144918: 1,
  57366: 3,
  123153: 3,
  84270: 52,
  84375: 2,
  122474: 41,
  61081: 9,
  47468: 3,
  138346: 3,
  40291: 18,
  131905: 1,
  61637: 1,
  124282: 1,
  11889: 1,
  11860: 11,
  83666: 24,
  101701: 7,
  10635: 4,
  98508: 1,
  48027: 1,
  123904: 1,
  77803: 6,
  99595: 1,
  129758: 8,
  78171: 12,
  84088: 33,
  98692: 11,
  146934: 2,
  60509: 1,
  124017: 2,
  83601: 7,
  61103: 3,
  28022: 8,
  11803: 19,
  97774: 1,
  144721: 9,
  11855: 1,
  123000: 6,
  45953: 21,
  62979: 4,
  45865: 1,
  82118: 1,
  28237: 1,
  28305: 1,
  61245: 1,
  123826: 1,
  7857: 11,
  123091: 2,
  12270: 1,
  77737: 1,
  144949: 7,
  41668: 1,
  84140: 5,
  123727: 2,
  7102: 2,
  83964: 13,
  38334: 8,
  124030: 8,
  101634: 1,
  98499: 1,
  66272: 1,
  146759: 28,
  124247: 2,
  47436: 16,
  145056: 35,
  47153: 1,
  40555: 1,
  45783: 5,
  61441: 29,
  42388: 1,
  84085: 3,
  11765: 21,
  123085: 1,
  131633: 23,
  9783: 8,
  97605: 15,
  121717: 1,
  63707: 2,
  84296: 2,
  101072: 2,
  98689: 15,
  100660: 2,
  40693: 4,
  129604: 1,
  73774: 5,
  84329: 4,
  62890: 1,
  99467: 1,
  7077: 1,
  101690: 20,
  11869: 1,
  45791: 39,
  9579: 19,
  40912: 2,
  123974: 1,
  78874: 13,
  12112: 1,
  61211: 17,
  48229: 19,
  12087: 1,
  27126: 7,
  7844: 20,
  101106: 1,
  77632: 2,
  75916: 18,
  99248: 1,
  57372: 1,
  99218: 1,
  146567: 1,
  60326: 3,
  62942: 3,
  97195: 7,
  83639: 3,
  47124: 1,
  9502: 3,
  10799: 55,
  84226: 1,
  101735: 1,
  83542: 1,
  144138: 1,
  99197: 1,
  144718: 6,
  40341: 6,
  83665: 13,
  97268: 16,
  61398: 49,
  48327: 47,
  97405: 47,
  57488: 41,
  100632: 37,
  11824: 30,
  61100: 27,
  97118: 27,
  8642: 26,
  48267: 24,
  46601: 20,
  46416: 12,
  11937: 12,
  66581: 11,
  77787: 10,
  57121: 9,
  40306: 10,
  57120: 5,
  97993: 5,
  129574: 5,
  97630: 3,
  131705: 5,
  144622: 2,
  57272: 2,
  11762: 2,
  122832: 2,
  104155: 2,
  123943: 1,
  4924: 1,
  55410: 1,
  39395: 1,
  52266: 1,
  77965: 1,
  147476: 1,
  62946: 1,
  11872: 1,
  10669: 1,
  122906: 1,
  84302: 1,
  10689: 1,
  84423: 1,
  99143: 1,
  10760: 1,
  78038: 1,
  11993: 1,
  11792: 1,
  61568: 17,
  97199: 16,
  146760: 4,
  47702: 1,
  8647: 1,
  122937: 1,
  61418: 1,
  83689: 1,
  47864: 32,
  57185: 30,
  29049: 30,
  7816: 1,
}
