import { CurrentSuggestionData, PlayerPickGrades } from '@pff-consumer/schema'

export const getPlayerPickGradeMap = (suggestions: CurrentSuggestionData): PlayerPickGrades => {
  const { player_id: playerIds, PFF_s: pickGrades } = suggestions
  const playerPickGradeMap: PlayerPickGrades = {}
  playerIds.forEach((id: string, index: number) => {
    if (id) {
      playerPickGradeMap[id] = pickGrades[index]
    }
  })

  return playerPickGradeMap
}
