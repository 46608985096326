export interface SubscribeCompleteSuccessResponse {
  location: string
  success: true
}

export function isSubscribeCompleteSuccessResponse(response: any): response is SubscribeCompleteSuccessResponse {
  return (
    response != null &&
    typeof response === 'object' &&
    'success' in response &&
    response.success === true &&
    'location' in response
  )
}

export interface SubscribeCompleteBody {
  password: string
  userHash: string
  accessToken: string
  redirect?: string
  firstName?: string
  lastName?: string
}

export interface SubscribeCompleteErrorResponse {
  error: 'missing_authorization_token' | 'invalid_complete_request' | 'invalid_update_request' | 'unexpected_error'
  message: string
  success: false
  errors?: Record<string, string[]>
}

export function isSubscribeCompleteErrorResponse(response: any): response is SubscribeCompleteErrorResponse {
  return (
    response != null &&
    typeof response === 'object' &&
    'success' in response &&
    response.success === false &&
    'error' in response
  )
}
