import { useEffect, useState } from 'react'
import { getBreakpointSize } from '@pff-consumer/core-ui'
import { DevicesSet } from '@pff-consumer/tweed-ui/styles/use-dimensions'
import { useWindowSize } from './useWindowSize'

export const useBreakpoints = (): DevicesSet => {
  const { width } = useWindowSize()
  const breakpointSize = getBreakpointSize(width)

  const [isXS, setXS] = useState(breakpointSize === 'xs')
  const [isSM, setSM] = useState(breakpointSize === 'sm')
  const [isMD, setMD] = useState(breakpointSize === 'md')
  const [isLG, setLG] = useState(breakpointSize === 'lg')
  const [isXL, setXL] = useState(breakpointSize === 'xl')
  const [isXXL, setXXL] = useState(breakpointSize === 'xxl')

  useEffect(() => {
    setXS(getBreakpointSize(width) === 'xs')
    setSM(getBreakpointSize(width) === 'sm')
    setMD(getBreakpointSize(width) === 'md')
    setLG(getBreakpointSize(width) === 'lg')
    setXL(getBreakpointSize(width) === 'xl')
    setXXL(getBreakpointSize(width) === 'xxl')
  }, [width])

  return {
    isXS,
    isSM,
    isMD,
    isLG,
    isXL,
    isXXL,
    isMobile: isXS || isSM,
    isTablet: isMD,
    isDesktop: isLG || isXL || isXXL,
  }
}
