import { colors } from '@pff-consumer/core-ui'
import { MATCHUP_AVERAGE_THRESHOLD } from './colorThresholds'

export const getMatchupAdvantageColor = (advantage: number = 0, isOpponent: boolean = false) => {
  const score = isOpponent ? 100 - advantage : advantage
  if (score >= MATCHUP_AVERAGE_THRESHOLD) {
    return {
      textBackground: colors.playerGradeGreatBackground,
      text: colors.playerGradeGreat,
      imageBorder: colors.playerGradeGreat,
    }
  }
  return {
    textBackground: colors.borderColor,
    text: colors.primaryColor,
    imageBorder: colors.matchupLoser,
  }
}
