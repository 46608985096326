export enum SubscriptionPlans {
  PREMIUM_ANNUAL = 'premium-annual-season-2023',
  PREMIUM_MONTHLY = 'premium-monthly',
  PREMIUM_MONTHLY_DRAFT_KIT = 'premium-monthly-draft',
  PREMIUM_MONTHLY_SEASON = 'premium-monthly-season-2023',
  PREMIUM_MONTHLY_SEASON_PASS = 'premium-monthly-season-pass-2023',
  PREMIUM_MONTHLY_2024_DRAFT_EARLY = 'premium-monthly-2024-draft-early',
  PREMIUM_MONTHLY_2024_DRAFT_PEAK = 'premium-monthly-2024-draft-peak',
}

export enum PaymentMethods {
  PAYPAL = 'paypal',
  APPLE_PAY = 'apple-pay',
  CREDIT_CARD = 'credit-card',
  DEFAULT = '',
}
