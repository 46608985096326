export enum FootballStatKey {
  AnyTimeTouchdown = 'any_time_td',
  PassAttempt = 'pass_att',
  PassCompletion = 'pass_comp',
  PassingAirYards = 'pass_air_yd',
  PassingDepthAverage = 'pass_depth',
  PassingDropbacks = 'pass_dropback',
  PassingInterceptions = 'pass_int',
  PassingPressures = 'pass_pressure',
  PassingSacks = 'pass_sack',
  PassingScrambles = 'pass_scramble',
  PassingThrowaways = 'pass_throwaway',
  PassingTouchdown = 'pass_td',
  PassingYards = 'pass_yd',
  ReceivingTargets = 'targets',
  ReceivingTouchdown = 'recv_td',
  ReceivingYards = 'recv_yd',
  ReceivingYardsAfterCatch = 'recv_yd_after_catch',
  Receptions = 'recv_rec',
  RushAttempts = 'rush_att',
  RushingAndReceivingYards = 'rush_recv_yd',
  RushingMissedTackle = 'rush_missed_tackle',
  RushingTouchdown = 'rush_td',
  RushingYards = 'rush_yd',
  RushingYardsAfterContact = 'rush_yd_after_contact',
  RushingYardsBeforeContact = 'rush_yd_before_contact',
  RushingYardsBeforeContactAllowed = 'rush_yd_before_contact_allowed',
  KickingPoints = 'kick_points',
  FieldGoalAttempts = 'fg_att',
  FieldGoalsMade = 'fg_made',
}

export enum CalculatedFootballStatKey {
  PassCompletionPercentage = 'pass_comp_percent',
  PassingYardPerAttempt = 'pass_yd_per_attempt',
  PassingPressurePerDropback = 'pass_pressure_per_dropback',
  ReceivingYardsAfterCatchPerReception = 'recv_yd_after_catch_per_rec',
  ReceivingYardsPerReception = 'recv_yd_per_rec',
  RushingYardPerAttempt = 'rush_yd_per_attempt',
  RushingYardsAfterContactPerAttempt = 'rush_yd_after_contact_per_attempt',
  RushingYardsBeforeContactPerAttempt = 'rush_yd_before_contact_per_attempt',
}
