import type { PlayerPropBetsGroup, SDKPlayerPropGradeAnalysis } from '@pff-consumer/schema'
import { getSportsbookIds } from './get-sportsbookIds'

/**
 * Adds analysis attribute to the PlayerPropBet
 */
export function getPropBetsWithAnalysis(propBets: PlayerPropBetsGroup, analysis: SDKPlayerPropGradeAnalysis[]) {
  const betIdAnalysisMap = getSportsbookIds(analysis)
  return Object.entries(propBets).reduce<PlayerPropBetsGroup>((acc, [key, betValue]) => {
    const playerProps = betValue.playerProps.map((prop) => ({
      ...prop,
      analysis: betIdAnalysisMap[prop.sportsbookBetId],
    }))
    return { ...acc, [key]: { ...betValue, playerProps } }
  }, {})
}
