import { AddListenerOverloads } from '@reduxjs/toolkit/dist/listenerMiddleware/types'
import { isAnyOf } from '@reduxjs/toolkit'
import { FantasyMatchupPosition } from '@pff-consumer/schema'
import { consumerApi } from '../../lib/consumer-api/consumer-api'
import { fantasySlice } from '../../lib/fantasy/fantasy.slice'
import type { AppRootState } from '../store'

export const addFantasyMatchupsListeners = (startListening: AddListenerOverloads<unknown>) => {
  startListening({
    matcher: isAnyOf(
      fantasySlice.actions.updateMatchupFilterPosition.match,
      consumerApi.endpoints.getSDKMatchups.matchFulfilled
    ),
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState() as AppRootState
      listenerApi.cancelActiveListeners()
      const { position, runningBackType } = state.fantasy.matchups.filters
      const queryParams =
        position === FantasyMatchupPosition.RB
          ? {
              position,
              runningBackType,
            }
          : { position }

      const { data, status } = consumerApi.endpoints.getSDKMatchups.select(queryParams)(state)
      if (status === 'fulfilled') {
        listenerApi.dispatch(fantasySlice.actions.updateFantasyMatchups({ matchups: data.matchups }))
      }
    },
  })
}
