import { TransformResponse } from '../fantasy/constants'

enum DraftPosition {
  QB = 'QB',
  WR = 'WR',
  TE = 'TE',
  HB = 'HB',
  FB = 'FB',
  T = 'T',
  C = 'C',
  G = 'G',
  CB = 'CB',
  S = 'S',
  DI = 'DI',
  ED = 'ED',
  LB = 'LB',
  ST = 'ST',
  K = 'K',
  P = 'P',
  LS = 'LS',
}

export type DraftPlayerPositions = `${DraftPosition}`

enum DraftFilterPositionGroup {
  QB = 'QB',
  WR = 'WR',
  TE = 'TE',
  RB = 'RB', // Includes -> HB, FB
  OT = 'OT', // == T
  IOL = 'IOL', // Includes -> C, G
  CB = 'CB',
  S = 'S',
  DI = 'DI',
  ED = 'ED',
  LB = 'LB',
  ST = 'ST', // Includes -> ST, P, K, LS
}

export type DraftFilterPositionGroups = `${DraftFilterPositionGroup}`

const transformOption = (value: string): TransformResponse => ({ label: value, value })

export const DRAFT_LABELS = {
  POSITIONS: Object.values(DraftFilterPositionGroup)
    .map(transformOption)
    .map((item) => {
      return item
    }),
}

type DraftEligiblePlayerPffGrade = {
  grade: number
  positionRank: number
  positionRankTotal: number
}

interface DraftEligiblePlayerBigBoard {
  pffRank: number
  pffGrade1: DraftEligiblePlayerPffGrade
  analysis: string | null
}

interface CollegeTeam {
  city: string
  slug: string
}

export interface DraftEligiblePlayer {
  playerId: number
  name: string
  position: DraftPlayerPositions
  speed: number | null
  height: string | null
  weight: number | null
  age: string | null
  class: string | null
  headshot: string | null
  bigBoard: DraftEligiblePlayerBigBoard
  team: CollegeTeam
}

export interface BigBoardResponse {
  lastUpdatedAt: string | null
  nflDraftSeason: number
  bigBoardVersion: number
  players: DraftEligiblePlayer[] | []
}

export interface BigBoardMetaResponse {
  nflDraftSeason: number
  bigBoardVersion: number
}
