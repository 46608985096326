import { FantasyPosition, FantasyDisplayPosition } from '../fantasy/fantasy'

export type RosterMap = Record<number, FantasyDisplayPosition>

export interface FantasyProviderRoster {
  roster: RosterMap
  emptyPositions: FantasyDisplayPosition[]
}
export interface EmptyRosterItem {
  id: null
  fantasyPosition: FantasyDisplayPosition
  acceptablePositions: FantasyPosition[] | null
  position: null
  isFlexPosition: boolean
  firstName: null
  lastName: null
  team: null
  teamName: null
  teamCity: null
  byeWeek: null
  opponent: {
    time: null
    team: null
  }
  rank: {
    current: null
    position: null
  }
  projection: {
    points: {
      mid: null
    }
  }
  isHomeGame: null
  gameStatus: null
}

export interface RosterItem {
  id: number
  fantasyPosition: FantasyDisplayPosition
  position: FantasyPosition
  acceptablePositions: FantasyPosition[] | null
  isFlexPosition: boolean
  firstName: string
  lastName: string
  team: string
  teamName: string
  teamCity: string
  byeWeek: number
  opponent: {
    time: number | null
    team: string | null
  }
  rank: {
    current: number | null
    position: number | null
  }
  projection: {
    points: {
      mid: number | null
    }
  }
  isHomeGame: boolean
  gameStatus: string
}

export type MixedRosterItem = RosterItem | EmptyRosterItem

export enum SuggestionDirection {
  STARTER = 'Move as Starter',
  BENCH = 'Move to Bench',
}

export interface StartSitSuggestion {
  suggestedPlayer: number
  reason: string
  direction: SuggestionDirection
}

export type RosterItemWithSuggestion = RosterItem & {
  suggestion: StartSitSuggestion | null
}

export type MixedRosterItemWithSuggestion = MixedRosterItem & {
  suggestion: StartSitSuggestion | null
}

export interface FantasyRoster {
  starters: MixedRosterItem[]
  bench: MixedRosterItem[]
}

export interface RosterResponse {
  roster: FantasyRoster
}
