import { FantasyPosition, type WeeklyRankingsEntity } from '@pff-consumer/schema'

export function getOverallRank(position: FantasyPosition, player: WeeklyRankingsEntity) {
  if (
    position === FantasyPosition.FLEX ||
    position === FantasyPosition.SFL ||
    position === FantasyPosition.DL ||
    position === FantasyPosition.DB
  ) {
    return player.rank.current
  }
  return player.rank.position
}
