import type { PlayerPropSection } from '@pff-consumer/schema'
import { uniqBy } from 'lodash'

export function addPaginatedData(sourceData: PlayerPropSection[], currentData: PlayerPropSection[]) {
  // Combining the new paginated data with the existing data
  return sourceData.map((section) => {
    // find the new data based on market
    const newPaginatedValue = currentData.find((x) => x.market === section.market)

    if (!newPaginatedValue) {
      return section
    }
    const combinedData = [...section.data, ...newPaginatedValue.data]

    return {
      ...newPaginatedValue,
      data: uniqBy(combinedData, (betMarket) =>
        'overBet' in betMarket ? betMarket.overBet.sportsbookBetId : betMarket.singleBet.sportsbookBetId
      ),
    }
  })
}
