import { NFLTeamWithLogo } from '@pff-consumer/schema'
import { getTeamIconSlugFromAbbreviation } from '@pff-consumer/utils'
import { useMemo } from 'react'
import { getTeams } from '../consumer-api'

export const useGetTeam = (teamId?: number) => {
  const { data: teams, isLoading } = getTeams.useQueryState()

  const data = useMemo(() => {
    if (!teamId || !teams || isLoading) {
      return null
    }
    const teamObj = teams.find((team) => team.teamId === teamId)

    if (!teamObj) {
      return null
    }
    return { ...teamObj, logo: getTeamIconSlugFromAbbreviation(teamObj.teamAbv) } as NFLTeamWithLogo
  }, [JSON.stringify(teams), teamId])

  return { data, isLoading }
}
