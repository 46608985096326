import { SDK_SEASON } from '@pff-consumer/schema'

export const connectEndpoints = {
  getInsightsByEvents: ({ eventIds }: { eventIds: string }) => ({
    url: `football/v1/nfl/connect-insights?eventIds=${eventIds}`,
  }),
  getPlayerInsightsByWeek: ({ weekAbv, season = SDK_SEASON }: { weekAbv: string; season?: number }) => ({
    url: `/football/v1/nfl/seasons/${season}/week/${weekAbv}/connect-insights/players`,
  }),
  getInsightsUpdatedTimestampByWeek: ({ weekAbv, season = SDK_SEASON }: { weekAbv: string; season?: number }) => ({
    url: `/football/v1/insights/nfl/seasons/${season}/weeks/${weekAbv}/events/last-updated-insights`,
  }),
}
