import { DraftTypes, type League, type MDSLeagueSettings } from '@pff-consumer/schema'
import { ManualLeagueSettings } from '@pff-consumer/schema/fantasy-mds/manual-league-settings'
import type { Keeper } from '@pff-consumer/schema/fantasy-live-draft/keeper'
import { calculateRosterSize } from './calculate-roster-size'
import { getBenchSpots } from './get-bench-spots'

interface KeeperDict {
  player_id: number[]
  pick: number[]
}

export const transformSyncedSettings = (
  league: League,
  leagueSettings: MDSLeagueSettings,
  leagueKeepers?: Keeper[]
) => {
  const leagueCpuWeighting = new Array(league.size).fill(0.5)
  const rosterSize = calculateRosterSize(league.league_settings)
  const starters: ManualLeagueSettings['starters'] = {
    wr: league?.league_settings?.wr || 0,
    rb: league?.league_settings?.rb || 0,
    te: league?.league_settings?.te || 0,
    qb: league?.league_settings?.qb || 0,
    flex: league?.league_settings?.flex || 0,
    superflex: league?.league_settings?.op || 0,
    rec_flex: league?.league_settings?.rec_flex || 0,
    wrrb_flex: league?.league_settings?.wrrb_flex || 0,
    dst: league?.league_settings?.dst || 0,
    k: league?.league_settings?.k || 0,
  }

  const keeperDict = leagueKeepers
    ? leagueKeepers.reduce<KeeperDict>(
        (dict, keeper) => {
          dict.player_id.push(parseInt(keeper.playerId, 10))
          dict.pick.push(keeper.pickNumber)

          return dict
        },
        { player_id: [], pick: [] }
      )
    : undefined

  return {
    starting_WR: starters.wr,
    starting_RB: starters.rb,
    starting_TE: starters.te,
    starting_QB: starters.qb,
    starting_flex: starters.flex,
    starting_superflex: starters.superflex,
    starting_WRTE: starters.rec_flex,
    starting_WRRB: starters.wrrb_flex,
    starting_K: starters.k,
    starting_DST: starters.dst,
    fantasy_players_in_league: league.size,
    user_pick: leagueSettings.user_pick,
    bench_spots: league?.league_settings?.bench || getBenchSpots(starters, rosterSize),
    rounds: rosterSize,
    third_round_reversal: league.draft_type === DraftTypes.THIRD_ROUND_REVERSAL ? 1 : 0,
    linear_drafting: league.draft_type === DraftTypes.LINEAR ? 1 : 0,
    ppr: league?.league_settings?.recv_receptions1 || 0.5,
    projection_weight_jahnke: 1,
    projection_weight_experts: 1,
    projection_weight_pff_simulation: 1,
    user_strategy_zero_rb: leagueSettings?.user_strategy_zero_rb ? 1 : 0,
    user_strategy_late_qb: leagueSettings.user_strategy_late_qb ? 1 : 0,
    user_strategy_stacking: leagueSettings.user_strategy_stacking ? 1 : 0,
    team_affinity_aversion_strength: 1,
    cpu_stacking: new Array(league.size).fill(0),
    cpu_wr: leagueCpuWeighting,
    cpu_rb: leagueCpuWeighting,
    cpu_qb: leagueCpuWeighting,
    cpu_te: leagueCpuWeighting,
    cpu_team_affinity: leagueSettings.cpu_team_affinity,
    cpu_team_aversion: leagueSettings.cpu_team_aversion,
    keeper_dict: keeperDict,
  }
}
