import type { LeagueSettings } from '@pff-consumer/schema'

export const calculateRosterSize = (settings?: LeagueSettings | null) => {
  if (!settings) {
    return 0
  }

  return (
    settings.wr +
    settings.rb +
    settings.te +
    settings.qb +
    settings.flex +
    settings.k +
    settings.dst +
    settings.bench +
    (settings.op || 0) +
    settings.wrrb_flex +
    settings.rec_flex
  )
}
