/**
 * This is so we use the same hover/focus across the web. If any changes are needed we can include this file.
 * There may be some scenarios where the hover is different but ideally that is encapsulated in a tweed atom like with the button
 */
import { colors } from './colors'
import { Opacity } from './opacity'

export const onHoverAndFocus = {
  '&:hover': {
    backgroundColor: colors.webActiveBackground,
  },
  '&:focus': {
    backgroundColor: colors.webActiveBackground,
  },
}

export const onHoverAndFocusOpacity = {
  '&:hover': {
    opacity: Opacity.onHover,
  },
  '&:focus': {
    opacity: Opacity.opaque,
  },
}

export const onHoverAndFocusSelect = {
  '&:hover': {
    backgroundColor: colors.webActiveBackground,
    borderColor: colors.webActiveBorder,
  },
  '&:focus': {
    backgroundColor: colors.webActiveBackground,
    borderColor: colors.webActiveBorder,
  },
}
