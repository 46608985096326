import { FantasyDisplayPosition, FantasyPosition } from '@pff-consumer/schema'

export function getAcceptablePositions(fantasyPosition: FantasyDisplayPosition) {
  const displayPositionToAcceptablePositionMap: Record<FantasyDisplayPosition, FantasyPosition[] | null> = {
    [FantasyDisplayPosition.QB]: [FantasyPosition.QB],
    [FantasyDisplayPosition.RB]: [FantasyPosition.RB],
    [FantasyDisplayPosition.WR]: [FantasyPosition.WR],
    [FantasyDisplayPosition.TE]: [FantasyPosition.TE],
    [FantasyDisplayPosition.K]: [FantasyPosition.K],
    [FantasyDisplayPosition.FLEX]: [FantasyPosition.WR, FantasyPosition.RB, FantasyPosition.TE],
    [FantasyDisplayPosition.WRRB_FLEX]: [FantasyPosition.WR, FantasyPosition.RB],
    [FantasyDisplayPosition.REC_FLEX]: [FantasyPosition.WR, FantasyPosition.TE],
    [FantasyDisplayPosition.SUPER_FLEX]: [
      FantasyPosition.QB,
      FantasyPosition.WR,
      FantasyPosition.RB,
      FantasyPosition.TE,
    ],
    [FantasyDisplayPosition.DEF]: [FantasyPosition.DEF],
    [FantasyDisplayPosition.DST]: [FantasyPosition.DEF],
    [FantasyDisplayPosition.BE]: null,
    [FantasyDisplayPosition.BN]: null,
  }
  return displayPositionToAcceptablePositionMap[fantasyPosition]
}
