import { useState, useEffect, useMemo } from 'react'
import { getBreakpointRange } from '@pff-consumer/core-ui'

export const useDeviceType = () => {
  const defaultValues = useMemo(() => ({ isMobile: false, isTablet: false, isDesktop: false }), [])
  const [deviceTypes, setDeviceTypes] = useState(defaultValues)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mobile = window.matchMedia(`(max-width: ${getBreakpointRange('sm').max}px)`)
      const tablet = window.matchMedia(
        `(min-width: ${getBreakpointRange('md').min}px) and (max-width: ${getBreakpointRange('md').max}px)`
      )
      const desktop = window.matchMedia(`(min-width: ${getBreakpointRange('lg').min}px)`)

      const resize = () => {
        if (mobile.matches) {
          setDeviceTypes({ ...defaultValues, isMobile: true })
        }
        if (tablet.matches) {
          setDeviceTypes({ ...defaultValues, isTablet: true })
        }
        if (desktop.matches) {
          setDeviceTypes({ ...defaultValues, isDesktop: true })
        }
      }

      resize()

      window.addEventListener('resize', resize)

      return () => window.removeEventListener('resize', resize)
    }

    return () => undefined
  }, [defaultValues])

  return deviceTypes
}
