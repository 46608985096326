export const getLongName = (
  firstName: string | null | undefined = '',
  lastName: string | null | undefined = ''
): string => {
  const strFirstName: string = firstName != null ? firstName.trim() : ''
  const strLastName: string = lastName != null ? lastName.trim() : ''

  if (strFirstName === '' && strLastName !== '') {
    return strLastName
  }
  if (strFirstName !== '' && strLastName === '') {
    return strFirstName
  }
  return `${strFirstName} ${strLastName}`
}
