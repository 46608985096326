import { createContext } from 'react'

interface ThemeContextInterface {
  isWeb: boolean
}
/**
 * isWeb - Flag to switch web and native theme
 */
const defaultProviderValue = {
  isWeb: false,
}

export const ThemeContext = createContext<ThemeContextInterface>(defaultProviderValue)
