import { getBreakpointRange, getBreakpointMinOrValue, getBreakpointMaxOrValue } from '@pff-consumer/core-ui'
import type { BreakpointSize, BreakpointOrientation } from '@pff-consumer/core-ui'
import { DimensionsSet } from './use-dimensions'

const hasValidOrientation = (orientation: BreakpointOrientation | undefined, dimensions: DimensionsSet) => {
  return !orientation || orientation === dimensions.windowDimensions.orientation
}

export const generateBreakpoints = <StylesType>(dimensions: DimensionsSet) => {
  const { windowDimensions } = dimensions
  return {
    down: (
      breakpointSize: BreakpointSize | number,
      styles: StylesType,
      orientation?: BreakpointOrientation
    ): StylesType | {} => {
      const breakpointValue = getBreakpointMaxOrValue(breakpointSize)

      if (windowDimensions?.width <= breakpointValue && hasValidOrientation(orientation, dimensions)) {
        return styles
      }

      return {}
    },
    up: (
      breakpointSize: BreakpointSize | number,
      styles: StylesType,
      orientation?: BreakpointOrientation
    ): StylesType | {} => {
      const breakpointValue = getBreakpointMinOrValue(breakpointSize)

      if (windowDimensions?.width >= breakpointValue && hasValidOrientation(orientation, dimensions)) {
        return styles
      }

      return {}
    },
    between: (
      breakpointSizeA: BreakpointSize | number,
      breakpointSizeB: BreakpointSize | number,
      styles: StylesType,
      orientation?: BreakpointOrientation
    ): StylesType | {} => {
      const minA = getBreakpointMinOrValue(breakpointSizeA)
      const maxB = getBreakpointMaxOrValue(breakpointSizeB)

      if (
        minA >= windowDimensions?.width &&
        maxB <= windowDimensions?.width &&
        hasValidOrientation(orientation, dimensions)
      ) {
        return styles
      }

      return {}
    },
    only: (
      breakpointSize: BreakpointSize,
      styles: StylesType,
      orientation?: BreakpointOrientation
    ): StylesType | {} => {
      const { min, max } = getBreakpointRange(breakpointSize)

      if (
        max >= windowDimensions?.width &&
        min <= windowDimensions?.width &&
        hasValidOrientation(orientation, dimensions)
      ) {
        return styles
      }

      return {}
    },
  }
}
