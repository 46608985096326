const ordinalRules = new Intl.PluralRules('en', { type: 'ordinal' })
const suffixes: { [key: string]: string } = {
  zero: '',
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
}

export const getOrdinalNumber = (number: number | null | undefined) => {
  if (number === null || number === undefined) {
    return ''
  }

  const category = ordinalRules.select(number)
  const suffix = suffixes[category]
  return number + suffix
}

export const getSuffix = (number: number) => {
  const category = ordinalRules.select(number)
  const suffix = suffixes[category]

  return suffix
}
