import { FootballStatKey, CalculatedFootballStatKey } from '@pff-consumer/schema'

export const FOOTBALL_STAT_DISPLAY_TEXTS: {
  [key in FootballStatKey | CalculatedFootballStatKey]: { short: string; medium: string; long: string }
} = {
  any_time_td: {
    short: 'TD',
    medium: 'TD',
    long: 'Any Time Touchdown Scorer',
  },
  pass_td: {
    short: 'Passing TDs',
    medium: 'Passing Touchdowns',
    long: 'Passing Touchdowns',
  },
  pass_yd: {
    short: 'Pass Yds',
    medium: 'Passing Yds',
    long: 'Passing Yards',
  },
  pass_att: {
    short: 'Pass Attempts',
    medium: 'Passing Attempts',
    long: 'Passing Attempts',
  },
  pass_comp: {
    short: 'Pass Completions',
    medium: 'Passing Completions',
    long: 'Passing Completions',
  },
  pass_int: {
    short: 'Interceptions',
    medium: 'Passing Interceptions',
    long: 'Passing Interceptions',
  },
  targets: {
    short: 'Targets',
    medium: 'Targets',
    long: 'Targets',
  },
  recv_rec: {
    short: 'Receptions',
    medium: 'Receptions',
    long: 'Receptions',
  },
  recv_td: {
    short: 'Receiving TDs',
    medium: 'Receiving Touchdowns',
    long: 'Receiving Touchdowns',
  },
  recv_yd: {
    short: 'Receiving Yards',
    medium: 'Receiving Yards',
    long: 'Receiving Yards',
  },
  rush_att: {
    short: 'Rush Attempts',
    medium: 'Rushing Attempts',
    long: 'Rushing Attempts',
  },
  rush_td: {
    short: 'Rush TDs',
    medium: 'Rushing Touchdowns',
    long: 'Rushing Touchdowns',
  },
  rush_yd: {
    short: 'Rush Yards',
    medium: 'Rushing Yards',
    long: 'Rushing Yards',
  },
  rush_recv_yd: {
    short: 'Rushing + Receiving',
    medium: 'Rushing and Receiving Yards',
    long: 'Rushing and Receiving Yards',
  },
  rush_missed_tackle: {
    short: 'Missed Tackle',
    medium: 'Missed Tackle',
    long: 'Missed Tackle',
  },
  rush_yd_before_contact: {
    short: 'YbCO',
    medium: 'Yds Before Contact',
    long: 'Yards Before Contact',
  },
  rush_yd_before_contact_allowed: {
    short: 'YbCO Allowed',
    medium: 'Yds Before Contact Allowed',
    long: 'Yards Before Contact Allowed',
  },
  pass_sack: {
    short: 'SK',
    medium: 'Sacks',
    long: 'Passing Sacks',
  },
  pass_scramble: {
    short: 'SCR',
    medium: 'Scrambles',
    long: 'Passing Scrambles',
  },
  pass_throwaway: {
    short: 'TA',
    medium: 'Throwaways',
    long: 'Throwaways',
  },
  recv_yd_after_catch: {
    short: 'YAC',
    medium: 'Yds After Catch',
    long: 'Yards After Catch',
  },
  rush_yd_after_contact: {
    short: 'YaCO',
    medium: 'Yds After Contact',
    long: 'Yards After Contact',
  },
  pass_air_yd: {
    short: 'AirYds',
    medium: 'Air Yds',
    long: 'Air Yards',
  },
  pass_depth: {
    short: 'ADOT',
    medium: 'Avg Depth of Tgt',
    long: 'Average Depth of Target',
  },
  pass_dropback: {
    short: 'DB',
    medium: 'Dropbaks',
    long: 'Dropbacks',
  },
  pass_pressure: {
    short: 'Press.',
    medium: 'Pressures',
    long: 'Passing Pressures',
  },
  pass_pressure_per_dropback: {
    short: 'Press/DB',
    medium: 'Pressures/Dropback',
    long: 'Pass Pressures per Dropback',
  },
  pass_comp_percent: {
    short: 'Comp%',
    medium: 'Completion Percent',
    long: 'Pass Completion Percentage',
  },
  pass_yd_per_attempt: {
    short: 'PassYds/Attempt',
    medium: 'Pass Yds/Attempt',
    long: 'Passing yards per Attempt',
  },
  recv_yd_after_catch_per_rec: {
    short: 'YAC/Rec',
    medium: 'Yds After Catch/Reception',
    long: 'Yards After Catch per Reception',
  },
  recv_yd_per_rec: {
    short: 'RecYd/Rec',
    medium: 'Receiving Yds per Reception',
    long: 'Receiving Yards per Reception',
  },
  rush_yd_per_attempt: {
    short: 'RushYd/Att',
    medium: 'Rush Yds per Attempt',
    long: 'Rushing Yards per Attempt',
  },
  rush_yd_after_contact_per_attempt: {
    short: 'YaCO/Att',
    medium: 'Yds After Contact/Attempt',
    long: 'Yards After Contact per Attempt',
  },
  rush_yd_before_contact_per_attempt: {
    short: 'YbCO/Att',
    medium: 'Yds Before Contact/Attempt',
    long: 'Yards Before Contact per Attempt',
  },
  kick_points: {
    short: 'KickPts',
    medium: 'Kick Points',
    long: 'Kicking Points',
  },
  fg_att: {
    short: 'FG Att',
    medium: 'FG Attempts',
    long: 'Field Goal Attempts',
  },
  fg_made: {
    short: 'FG',
    medium: 'FG Made',
    long: 'Field Goals Made',
  },
}
