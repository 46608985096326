import { FantasyPosition, RosterItem, MixedRosterItem, FootballGameStatus } from '@pff-consumer/schema'

const LARGE_RANK: number = 99999

export const filterBetterRankedBenchPlayers = (
  benchPlayers: MixedRosterItem[],
  alreadySwappedBenchPlayerIDs: Set<number>,
  comparablePositions: FantasyPosition[],
  starterFlexRank: number
) => {
  const filteredPlayers = benchPlayers.filter(
    (benchPlayer): benchPlayer is RosterItem =>
      benchPlayer.id !== null && // Filter out empty slots
      benchPlayer.gameStatus === FootballGameStatus.UPCOMING && // Player still has the week's game upcoming
      !alreadySwappedBenchPlayerIDs.has(benchPlayer.id) && // Filter out already swapped bench players
      comparablePositions.includes(benchPlayer.position) && // Check for the positions that can be swapped (WR, TE, RB for a FL position)
      (benchPlayer.rank.current || LARGE_RANK) < starterFlexRank // Bench player rank should be lesser
  )

  return filteredPlayers
}
