import { isValidIp } from './is-valid-ip'

export async function getCountryFromIp(ip?: string) {
  if (!isValidIp(ip)) {
    return null
  }
  try {
    const geoResponse = await fetch(`https://api.country.is/${ip}`)
    const location: { country: string } = await geoResponse.json()
    return location?.country || null
  } catch (error) {
    return null
  }
}
