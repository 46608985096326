import type { Matchup } from '@pff-consumer/schema/sdk/matchups'
import type { NFLTeamAbbreviation } from '@pff-consumer/schema'

type Accumulator = {
  [key in string]?: NFLTeamAbbreviation
}

const teamMapper = (accumulator: Accumulator, currentValue: NFLTeamAbbreviation) => {
  const newAccumulator = { ...accumulator, [currentValue]: currentValue }
  return newAccumulator
}

export const getMatchupsFilteredByTeam = (matchupsList: Matchup[], selectedTeams: NFLTeamAbbreviation[]) => {
  if (selectedTeams.length === 0) {
    return matchupsList
  }
  const teamMap = selectedTeams.reduce(teamMapper, {})

  const matchupsFilteredByTeam = matchupsList.filter((matchup) => {
    return teamMap[matchup.player.team.abbreviation]
  })
  return matchupsFilteredByTeam
}
