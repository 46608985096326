import { LiveDraftStatus, LiveDraftStatusType } from '@pff-consumer/schema'

export const getLiveDraftBannerMessage = (draftStatus: LiveDraftStatusType | '') => {
  switch (draftStatus) {
    case LiveDraftStatus.PRE_DRAFT:
      return 'Your draft hasn’t started yet. Once the draft has begun, please refresh this page to see live picks come in.'
    case LiveDraftStatus.COMPLETE:
      return 'Your draft has ended.'
    case LiveDraftStatus.STALE:
      return 'Your draft has not had a pick in over ten minutes, please refresh this page after resuming your draft'
    // TODO: To add clock logic
    case '':
      return 'Draft is connecting... It can take up to a minute for picks to populate.'
    case LiveDraftStatus.DRAFTING:
    default:
      return ''
  }
}
