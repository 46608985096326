interface DraftPickType {
  [key: string]: Array<number>
}

const draftOrder: { [key: number]: DraftPickType } = {
  8: {},
  10: {},
  12: {},
}

const populateDraftPicks = (teamSize: number) => {
  const allDraftPicks: DraftPickType = {}

  // eslint-disable-next-line no-plusplus
  for (let draftPosition = 1; draftPosition <= teamSize; draftPosition++) {
    const picks = []

    // eslint-disable-next-line no-plusplus
    for (let round = 1; round <= 13; round++) {
      let draftPick

      if (round % 2 === 0) {
        draftPick = round * teamSize - draftPosition + 1
      } else {
        draftPick = (round - 1) * teamSize + draftPosition
      }

      picks.push(draftPick)
    }

    allDraftPicks[draftPosition] = picks
  }

  draftOrder[teamSize] = allDraftPicks
}

// Populate draft pick list
populateDraftPicks(8)
populateDraftPicks(10)
populateDraftPicks(12)

export const calculateSnakeDraftPick = (round: number, pick: number, teamSize: number) => {
  return draftOrder?.[teamSize]?.[pick]?.[round - 1]
}
