import {
  BorderRadius as borderRadius,
  colors,
  colorsDEPRECATED,
  generateUtilityStyles,
  spacing,
  ZIndex as zIndex,
  Opacity as opacity,
} from '@pff-consumer/core-ui'
import type { Theme } from '@pff-consumer/core-ui'
import { Styles as ReactJssStyles } from 'react-jss'
import { registerTheme as registerNativeUiTheme } from '@pff-consumer/tweed-ui/styles/theme'
import { breakpoint } from './breakpoint'
import { useFontStyles } from './fonts'

export type Styles = ReactJssStyles

type CalcUnitFunction = (n: number) => string

const calcUnit: CalcUnitFunction = (n) => `${n / 16}rem`
const utilityStyles = generateUtilityStyles(calcUnit)

export type WebUiTheme = Theme<CalcUnitFunction, Styles>

export const theme: WebUiTheme = {
  platform: 'web',
  borderRadius,
  calcUnit,
  colors,
  colorsDEPRECATED,
  utilityStyles,
  useFontStyles,
  spacing,
  breakpoint,
  zIndex,
  opacity,
}

/**
 * Registers our web theme with native-ui so the native components
 * that we import have use the web theme and not the native theme.
 *
 * There is a TS error because our theme calcUnit returns a string and not number
 */
registerNativeUiTheme<WebUiTheme>(theme)
