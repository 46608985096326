import type { PFFLetterGrade } from '../common/grade'
import type { FootballPlayer } from '../football/football-player'
import type { SDKPlayerPropGradeAnalysis } from '../sdk'
import type { PlayerPropWithGradeAnalysis } from './player-prop'

export type Spread = number
export type OverUnder = number

export type SportsbookType = 'BETMGM' | 'FD' | string

export type GeneralTypeOfBet = 'Spread' | 'Moneyline' | 'Total' | 'Player Prop'

export type CategoryForBet = 'Game Bet' | 'Mismatches Prop' | 'General Player Prop'

export type SmartBetsTab = 'Games' | 'Player Props' | 'Best Bets'

export type DetailedEventViewWidgetType =
  | 'Popular'
  | 'TD Scorer Props'
  | 'Passing Props'
  | 'Rushing Props'
  | 'Receiving Props'

export type PlayerPropKey =
  | 'rush_att'
  | 'rush_yd'
  | 'rush_recv_yd'
  | 'pass_rush_yd'
  | 'pass_comp'
  | 'pass_int'
  | 'pass_att'
  | 'pass_yd'
  | 'pass_td'
  | 'recv_yd'
  | 'recv_rec'
  | 'longest_pass'
  | 'longest_reception'
  | 'kick_points'
  | 'tackle_assist'
  | 'first_team_td_scorer'
  | 'most_pass_yd'
  | 'most_rush_yd'
  | OutrightPlayerProps

export type OutrightPlayerProps =
  | 'any_time_td'
  | 'first_td'
  | 'two_plus_td'
  | 'three_plus_td'
  | 'last_td'
  | 'first_half_td'
  | 'second_half_td'
  | 'first_td_second_half'
  | 'first_completion'
  | 'first_reception'
  | 'longest_rush'
  | 'first_rush_att'
  | 'sack'
  | 'alt_recv_yd'
  | 'alt_pass_yd'
  | 'alt_rush_yd'

export type DetailedTypeOfBet =
  | 'AwayTeamSpread'
  | 'AwayTeamMoneyline'
  | 'HomeTeamSpread'
  | 'HomeTeamMoneyline'
  | 'Player'
  | 'Over'
  | 'Under'
  | PlayerPropKey

export type ExtraBetMarketTypes =
  | OverUnderBetTypes
  | OutrightBetTypes
  | HandicapBetTypes
  | 'extra-points-made'
  | 'field-goals-made'
  | 'tie-final'
  | 'tie-first-quarter'
  | 'tie-second-quarter'
  | 'tie-third-quarter'
  | 'tie-fourth-quarter'

export type OverUnderBetTypes =
  | 'alt-total'
  | 'home-team-total'
  | 'away-team-total'
  | 'first-half-home-total'
  | 'second-half-home-total'
  | 'first-half-away-total'
  | 'second-half-away-total'
  | 'first-half-total'
  | 'second-half-total'
  | 'first-quarter-total'
  | 'second-quarter-total'
  | 'third-quarter-total'
  | 'fourth-quarter-total'
  | 'away-team-total-tds'
  | 'home-team-total-tds'
  | 'total-tds-first-half'
  | 'total-tds-second-half'

export type OutrightBetTypes =
  | 'correct-score'
  | 'first-quarter-winner'
  | 'second-quarter-winner'
  | 'third-quarter-winner'
  | 'fourth-quarter-winner'
  | 'first-quarter-winner-no-push'
  | 'second-quarter-winner-no-push'
  | 'third-quarter-winner-no-push'
  | 'fourth-quarter-winner-no-push'
  | 'first-quarter-points-odd-even'
  | 'second-quarter-points-odd-even'
  | 'third-quarter-points-odd-even'
  | 'fourth-quarter-points-odd-even'
  | 'first-quarter-both-score'
  | 'second-quarter-both-score'
  | 'third-quarter-both-score'
  | 'fourth-quarter-both-score'
  | 'score-every-quarter'
  | 'last-scoring-play'
  | 'winning-margin'
  | 'last-score-first-half'
  | 'team-to-score-first-half'
  | 'overtime'
  | 'firt-team-td-scorer'
  | 'race-to-five'
  | 'race-to-ten'
  | 'race-to-fifteen'
  | 'race-to-twenty'
  | 'race-to-twenty-five'
  | 'race-to-thirty'
  | 'teams-score-10'
  | 'teams-score-15'
  | 'teams-score-20'
  | 'teams-score-25'
  | 'teams-score-30'
  | 'teams-score-35'
  | 'teams-score-40'
  | 'first-quarter-td'
  | 'second-quarter-td'
  | 'third-quarter-td'
  | 'fourth-quarter-td'
  | 'moneyline-total-parlay'
  | 'any-time-td-moneyline-parlay'
  | 'first-td-moneyline-parlay'
  | 'first-half-final-winner-parlay'
  | 'teams-score-1-td-each-half'
  | 'teams-score-2-td-each-half'
  | 'teams-score-3-td-each-half'
  | 'highest-scoring-quarter'
  | 'highest-scoring-half'
  | 'team-first-fg'
  | 'game-special'

export type HandicapBetTypes = 'alt-handicap' | 'first-half-handicap' | 'second-half-handicap' | 'handicap-total-parlay'

export type BetStatus = 'winner' | 'loser' | 'void' | undefined

export type BetOptionType = 'over' | 'under' | 'home' | 'away' | 'yes' | 'no'

export interface EventMarketData {
  eventId: number
  market: string
  value: number | null
  odds: number
  option: BetOptionType
  sportsbookBetId: string
  sportsbookEventId: string
  sportsbookMarketId: string
  sportsbookOptionId: string
  status: BetStatus
  uniqueBetId: string
}

export interface BetProduct {
  value: Spread | OverUnder | string | number | null
  odds: number
  description?: string
  sportsbookBetId?: string
  sportsbookMarketId?: string
  sportsbookOptionId?: string
  updatedTimestamp?: number
}
export interface BetProductWithMarket extends BetProduct {
  edge?: number
  gameId?: number
  eventId?: number
  playerId?: number
  detailedType?: DetailedTypeOfBet
  generalType?: GeneralTypeOfBet
  category?: CategoryForBet
  valueType?: PlayerPropKey
}

export interface BetProductWithSportsbook extends BetProductWithMarket {
  status: BetStatus
  uniqueBetId: string
  sportsbookId: SportsbookType
  sportsbookEventId?: string
}

export interface PlayerProp {
  eventId?: number
  gameId: number
  playerId: number
  odds: number
  value: number | string
  name: string
  option: BetOptionType
  propKey: PlayerPropKey
  sportsbookId: string
  sportsbookBetId: string
  sportsbookEventId: string
  sportsbookMarketId: string
  sportsbookOptionId: string
  mismatchId?: string
  active?: boolean
  order?: number
  grade?: PFFLetterGrade
  edge?: number
  coverPercentage?: number
  valueType?: string
  status?: BetStatus
}

export interface PlayerPropsByWeekEvent {
  playerProps: PlayerProp[]
}

export type SDKPlayerProp = SDKPlayerPropGradeAnalysis &
  Omit<PlayerPropWithPlayer, 'grade'> &
  PlayerPropWithGradeAnalysis

export interface PlayerPropWithPlayer extends Omit<PlayerProp, 'playerId'> {
  playerId: PlayerProp['playerId'] // This is kept for backwards compatibilithy reasons
  player: Pick<FootballPlayer, 'playerId' | 'firstName' | 'lastName' | 'position' | 'teamId'>
}

export interface GreenlinePropProjection {
  gameId: number
  playerId: number
  propKey: string
  realValue: number
  sportsbookValue: number
  marketType: string
  projectionValue: number
  projectionPercentage: number
}

export interface GreenlineGamelineProjection {
  gameId: number
  sportsbookValue: number
  marketType: string
  projectionValue: number
  projectionPercentage: number
}

export enum InsightsOptions {
  BEST_BETS = 'Best Bets',
  MISMATCHES = 'Mismatches',
  TEAM_GRADING = 'Team Grading',
}

export enum MarketType {
  Total = 'total',
  Outright = 'outright',
  Handicap = 'handicap',
}

export enum OptionType {
  AwayTeam = 'away',
  HomeTeam = 'home',
  OverTotal = 'over',
  UnderTotal = 'under',
  OutrightYes = 'yes',
  OutrightNo = 'no',
}
