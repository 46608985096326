import { flatten, isFunction, reduce, uniq } from 'lodash'

const BASE_UNIT = 4
type CalcUnitFunction = (n: number) => string | number
type SpacingUnit = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
type SpacingDirection = 'a' | 'x' | 'y' | 't' | 'r' | 'b' | 'l'
type UtilityMarginName =
  | 'ma-1'
  | 'ma-2'
  | 'ma-3'
  | 'ma-4'
  | 'ma-5'
  | 'ma-6'
  | 'ma-7'
  | 'ma-8'
  | 'mx-1'
  | 'mx-2'
  | 'mx-3'
  | 'mx-4'
  | 'mx-5'
  | 'mx-6'
  | 'mx-7'
  | 'mx-8'
  | 'my-1'
  | 'my-2'
  | 'my-3'
  | 'my-4'
  | 'my-5'
  | 'my-6'
  | 'my-7'
  | 'my-8'
  | 'mt-1'
  | 'mt-2'
  | 'mt-3'
  | 'mt-4'
  | 'mt-5'
  | 'mt-6'
  | 'mt-7'
  | 'mt-8'
  | 'mr-1'
  | 'mr-2'
  | 'mr-3'
  | 'mr-4'
  | 'mr-5'
  | 'mr-6'
  | 'mr-7'
  | 'mr-8'
  | 'mb-1'
  | 'mb-2'
  | 'mb-3'
  | 'mb-4'
  | 'mb-5'
  | 'mb-6'
  | 'mb-7'
  | 'mb-8'
  | 'ml-1'
  | 'ml-2'
  | 'ml-3'
  | 'ml-4'
  | 'ml-5'
  | 'ml-6'
  | 'ml-7'
  | 'ml-8'

type UtilityPaddingName =
  | 'pa-1'
  | 'pa-2'
  | 'pa-3'
  | 'pa-4'
  | 'pa-5'
  | 'pa-6'
  | 'pa-7'
  | 'pa-8'
  | 'px-1'
  | 'px-2'
  | 'px-3'
  | 'px-4'
  | 'px-5'
  | 'px-6'
  | 'px-7'
  | 'px-8'
  | 'py-1'
  | 'py-2'
  | 'py-3'
  | 'py-4'
  | 'py-5'
  | 'py-6'
  | 'py-7'
  | 'py-8'
  | 'pt-1'
  | 'pt-2'
  | 'pt-3'
  | 'pt-4'
  | 'pt-5'
  | 'pt-6'
  | 'pt-7'
  | 'pt-8'
  | 'pr-1'
  | 'pr-2'
  | 'pr-3'
  | 'pr-4'
  | 'pr-5'
  | 'pr-6'
  | 'pr-7'
  | 'pr-8'
  | 'pb-1'
  | 'pb-2'
  | 'pb-3'
  | 'pb-4'
  | 'pb-5'
  | 'pb-6'
  | 'pb-7'
  | 'pb-8'
  | 'pl-1'
  | 'pl-2'
  | 'pl-3'
  | 'pl-4'
  | 'pl-5'
  | 'pl-6'
  | 'pl-7'
  | 'pl-8'

export type UtilityStyleName = UtilityMarginName | UtilityPaddingName
interface PaddingDeclaration {
  padding?: string | number
  paddingTop?: string | number
  paddingRight?: string | number
  paddingBottom?: string | number
  paddingLeft?: string | number
}

interface MarginDeclaration {
  margin?: string | number
  marginTop?: string | number
  marginRight?: string | number
  marginBottom?: string | number
  marginLeft?: string | number
}

const padding = (direction: SpacingDirection, unit: SpacingUnit, calcUnit: CalcUnitFunction): PaddingDeclaration => {
  const value = calcUnit(unit * BASE_UNIT)
  switch (direction) {
    case 'a':
      return { padding: value }
    case 'x':
      return { paddingLeft: value, paddingRight: value }
    case 'y':
      return { paddingTop: value, paddingBottom: value }
    case 't':
      return { paddingTop: value }
    case 'r':
      return { paddingRight: value }
    case 'b':
      return { paddingBottom: value }
    case 'l':
      return { paddingLeft: value }
    default:
      return {} // Will never happen
  }
}

const margin = (direction: SpacingDirection, unit: SpacingUnit, calcUnit: CalcUnitFunction): MarginDeclaration => {
  const value = calcUnit(unit * BASE_UNIT)
  switch (direction) {
    case 'a':
      return { margin: value }
    case 'x':
      return { marginLeft: value, marginRight: value }
    case 'y':
      return { marginTop: value, marginBottom: value }
    case 't':
      return { marginTop: value }
    case 'r':
      return { marginRight: value }
    case 'b':
      return { marginBottom: value }
    case 'l':
      return { marginLeft: value }
    default:
      return {} // Will never happen
  }
}

export type UtilityStyleMap = {
  [key in UtilityStyleName]: (calcUnit: CalcUnitFunction) => MarginDeclaration | PaddingDeclaration
}

const utilityValueMap: UtilityStyleMap = {
  'ma-1': (calcUnit: CalcUnitFunction) => margin('a', 1, calcUnit),
  'ma-2': (calcUnit: CalcUnitFunction) => margin('a', 2, calcUnit),
  'ma-3': (calcUnit: CalcUnitFunction) => margin('a', 3, calcUnit),
  'ma-4': (calcUnit: CalcUnitFunction) => margin('a', 4, calcUnit),
  'ma-5': (calcUnit: CalcUnitFunction) => margin('a', 5, calcUnit),
  'ma-6': (calcUnit: CalcUnitFunction) => margin('a', 6, calcUnit),
  'ma-7': (calcUnit: CalcUnitFunction) => margin('a', 7, calcUnit),
  'ma-8': (calcUnit: CalcUnitFunction) => margin('a', 8, calcUnit),
  'mx-1': (calcUnit: CalcUnitFunction) => margin('x', 1, calcUnit),
  'mx-2': (calcUnit: CalcUnitFunction) => margin('x', 2, calcUnit),
  'mx-3': (calcUnit: CalcUnitFunction) => margin('x', 3, calcUnit),
  'mx-4': (calcUnit: CalcUnitFunction) => margin('x', 4, calcUnit),
  'mx-5': (calcUnit: CalcUnitFunction) => margin('x', 5, calcUnit),
  'mx-6': (calcUnit: CalcUnitFunction) => margin('x', 6, calcUnit),
  'mx-7': (calcUnit: CalcUnitFunction) => margin('x', 7, calcUnit),
  'mx-8': (calcUnit: CalcUnitFunction) => margin('x', 8, calcUnit),
  'my-1': (calcUnit: CalcUnitFunction) => margin('y', 1, calcUnit),
  'my-2': (calcUnit: CalcUnitFunction) => margin('y', 2, calcUnit),
  'my-3': (calcUnit: CalcUnitFunction) => margin('y', 3, calcUnit),
  'my-4': (calcUnit: CalcUnitFunction) => margin('y', 4, calcUnit),
  'my-5': (calcUnit: CalcUnitFunction) => margin('y', 5, calcUnit),
  'my-6': (calcUnit: CalcUnitFunction) => margin('y', 6, calcUnit),
  'my-7': (calcUnit: CalcUnitFunction) => margin('y', 7, calcUnit),
  'my-8': (calcUnit: CalcUnitFunction) => margin('y', 8, calcUnit),
  'mt-1': (calcUnit: CalcUnitFunction) => margin('t', 1, calcUnit),
  'mt-2': (calcUnit: CalcUnitFunction) => margin('t', 2, calcUnit),
  'mt-3': (calcUnit: CalcUnitFunction) => margin('t', 3, calcUnit),
  'mt-4': (calcUnit: CalcUnitFunction) => margin('t', 4, calcUnit),
  'mt-5': (calcUnit: CalcUnitFunction) => margin('t', 5, calcUnit),
  'mt-6': (calcUnit: CalcUnitFunction) => margin('t', 6, calcUnit),
  'mt-7': (calcUnit: CalcUnitFunction) => margin('t', 7, calcUnit),
  'mt-8': (calcUnit: CalcUnitFunction) => margin('t', 8, calcUnit),
  'mr-1': (calcUnit: CalcUnitFunction) => margin('r', 1, calcUnit),
  'mr-2': (calcUnit: CalcUnitFunction) => margin('r', 2, calcUnit),
  'mr-3': (calcUnit: CalcUnitFunction) => margin('r', 3, calcUnit),
  'mr-4': (calcUnit: CalcUnitFunction) => margin('r', 4, calcUnit),
  'mr-5': (calcUnit: CalcUnitFunction) => margin('r', 5, calcUnit),
  'mr-6': (calcUnit: CalcUnitFunction) => margin('r', 6, calcUnit),
  'mr-7': (calcUnit: CalcUnitFunction) => margin('r', 7, calcUnit),
  'mr-8': (calcUnit: CalcUnitFunction) => margin('r', 8, calcUnit),
  'mb-1': (calcUnit: CalcUnitFunction) => margin('b', 1, calcUnit),
  'mb-2': (calcUnit: CalcUnitFunction) => margin('b', 2, calcUnit),
  'mb-3': (calcUnit: CalcUnitFunction) => margin('b', 3, calcUnit),
  'mb-4': (calcUnit: CalcUnitFunction) => margin('b', 4, calcUnit),
  'mb-5': (calcUnit: CalcUnitFunction) => margin('b', 5, calcUnit),
  'mb-6': (calcUnit: CalcUnitFunction) => margin('b', 6, calcUnit),
  'mb-7': (calcUnit: CalcUnitFunction) => margin('b', 7, calcUnit),
  'mb-8': (calcUnit: CalcUnitFunction) => margin('b', 8, calcUnit),
  'ml-1': (calcUnit: CalcUnitFunction) => margin('l', 1, calcUnit),
  'ml-2': (calcUnit: CalcUnitFunction) => margin('l', 2, calcUnit),
  'ml-3': (calcUnit: CalcUnitFunction) => margin('l', 3, calcUnit),
  'ml-4': (calcUnit: CalcUnitFunction) => margin('l', 4, calcUnit),
  'ml-5': (calcUnit: CalcUnitFunction) => margin('l', 5, calcUnit),
  'ml-6': (calcUnit: CalcUnitFunction) => margin('l', 6, calcUnit),
  'ml-7': (calcUnit: CalcUnitFunction) => margin('l', 7, calcUnit),
  'ml-8': (calcUnit: CalcUnitFunction) => margin('l', 8, calcUnit),
  'pa-1': (calcUnit: CalcUnitFunction) => padding('a', 1, calcUnit),
  'pa-2': (calcUnit: CalcUnitFunction) => padding('a', 2, calcUnit),
  'pa-3': (calcUnit: CalcUnitFunction) => padding('a', 3, calcUnit),
  'pa-4': (calcUnit: CalcUnitFunction) => padding('a', 4, calcUnit),
  'pa-5': (calcUnit: CalcUnitFunction) => padding('a', 5, calcUnit),
  'pa-6': (calcUnit: CalcUnitFunction) => padding('a', 6, calcUnit),
  'pa-7': (calcUnit: CalcUnitFunction) => padding('a', 7, calcUnit),
  'pa-8': (calcUnit: CalcUnitFunction) => padding('a', 8, calcUnit),
  'px-1': (calcUnit: CalcUnitFunction) => padding('x', 1, calcUnit),
  'px-2': (calcUnit: CalcUnitFunction) => padding('x', 2, calcUnit),
  'px-3': (calcUnit: CalcUnitFunction) => padding('x', 3, calcUnit),
  'px-4': (calcUnit: CalcUnitFunction) => padding('x', 4, calcUnit),
  'px-5': (calcUnit: CalcUnitFunction) => padding('x', 5, calcUnit),
  'px-6': (calcUnit: CalcUnitFunction) => padding('x', 6, calcUnit),
  'px-7': (calcUnit: CalcUnitFunction) => padding('x', 7, calcUnit),
  'px-8': (calcUnit: CalcUnitFunction) => padding('x', 8, calcUnit),
  'py-1': (calcUnit: CalcUnitFunction) => padding('y', 1, calcUnit),
  'py-2': (calcUnit: CalcUnitFunction) => padding('y', 2, calcUnit),
  'py-3': (calcUnit: CalcUnitFunction) => padding('y', 3, calcUnit),
  'py-4': (calcUnit: CalcUnitFunction) => padding('y', 4, calcUnit),
  'py-5': (calcUnit: CalcUnitFunction) => padding('y', 5, calcUnit),
  'py-6': (calcUnit: CalcUnitFunction) => padding('y', 6, calcUnit),
  'py-7': (calcUnit: CalcUnitFunction) => padding('y', 7, calcUnit),
  'py-8': (calcUnit: CalcUnitFunction) => padding('y', 8, calcUnit),
  'pt-1': (calcUnit: CalcUnitFunction) => padding('t', 1, calcUnit),
  'pt-2': (calcUnit: CalcUnitFunction) => padding('t', 2, calcUnit),
  'pt-3': (calcUnit: CalcUnitFunction) => padding('t', 3, calcUnit),
  'pt-4': (calcUnit: CalcUnitFunction) => padding('t', 4, calcUnit),
  'pt-5': (calcUnit: CalcUnitFunction) => padding('t', 5, calcUnit),
  'pt-6': (calcUnit: CalcUnitFunction) => padding('t', 6, calcUnit),
  'pt-7': (calcUnit: CalcUnitFunction) => padding('t', 7, calcUnit),
  'pt-8': (calcUnit: CalcUnitFunction) => padding('t', 8, calcUnit),
  'pr-1': (calcUnit: CalcUnitFunction) => padding('r', 1, calcUnit),
  'pr-2': (calcUnit: CalcUnitFunction) => padding('r', 2, calcUnit),
  'pr-3': (calcUnit: CalcUnitFunction) => padding('r', 3, calcUnit),
  'pr-4': (calcUnit: CalcUnitFunction) => padding('r', 4, calcUnit),
  'pr-5': (calcUnit: CalcUnitFunction) => padding('r', 5, calcUnit),
  'pr-6': (calcUnit: CalcUnitFunction) => padding('r', 6, calcUnit),
  'pr-7': (calcUnit: CalcUnitFunction) => padding('r', 7, calcUnit),
  'pr-8': (calcUnit: CalcUnitFunction) => padding('r', 8, calcUnit),
  'pb-1': (calcUnit: CalcUnitFunction) => padding('b', 1, calcUnit),
  'pb-2': (calcUnit: CalcUnitFunction) => padding('b', 2, calcUnit),
  'pb-3': (calcUnit: CalcUnitFunction) => padding('b', 3, calcUnit),
  'pb-4': (calcUnit: CalcUnitFunction) => padding('b', 4, calcUnit),
  'pb-5': (calcUnit: CalcUnitFunction) => padding('b', 5, calcUnit),
  'pb-6': (calcUnit: CalcUnitFunction) => padding('b', 6, calcUnit),
  'pb-7': (calcUnit: CalcUnitFunction) => padding('b', 7, calcUnit),
  'pb-8': (calcUnit: CalcUnitFunction) => padding('b', 8, calcUnit),
  'pl-1': (calcUnit: CalcUnitFunction) => padding('l', 1, calcUnit),
  'pl-2': (calcUnit: CalcUnitFunction) => padding('l', 2, calcUnit),
  'pl-3': (calcUnit: CalcUnitFunction) => padding('l', 3, calcUnit),
  'pl-4': (calcUnit: CalcUnitFunction) => padding('l', 4, calcUnit),
  'pl-5': (calcUnit: CalcUnitFunction) => padding('l', 5, calcUnit),
  'pl-6': (calcUnit: CalcUnitFunction) => padding('l', 6, calcUnit),
  'pl-7': (calcUnit: CalcUnitFunction) => padding('l', 7, calcUnit),
  'pl-8': (calcUnit: CalcUnitFunction) => padding('l', 8, calcUnit),
}

export const generateUtilityStyles = (calcUnit: CalcUnitFunction) => {
  /**
   * Inspired by tailwind utility classes
   * @example
   * const styles = {
   *  ...utilityStyles(['ml-4', 'mt-4'], 'px-8')
   * }
   *
   */
  return function utilityStyles(...args: (UtilityStyleName | UtilityStyleName[])[]) {
    const flattened = flatten<UtilityStyleName>(args)
    const list = uniq(flattened)

    return reduce(
      list,
      (acc, name: UtilityStyleName) => {
        const style = utilityValueMap[name]
        if (isFunction(style)) {
          return { ...acc, ...style(calcUnit) }
        }
        return acc
      },
      {}
    )
  }
}
