import { Platform } from 'react-native'
import { FantasyUrlPaths } from '@pff-consumer/schema'
import { envConfig } from '../config'

export const getFantasySyncLeagueUrl = () => {
  if (Platform.OS === 'web') {
    return `${envConfig.PFF_DOMAIN}${FantasyUrlPaths.SYNC_LEAGUES}`
  }
  return `${envConfig.PFF_DOMAIN}${FantasyUrlPaths.MOBILE_SYNC_LEAGUES}`
}
