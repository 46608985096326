export interface FeedCategory {
  slug: string
  displayName: string
  order: number
}

export const ALL_CATEGORY: FeedCategory = {
  slug: 'all',
  displayName: 'All',
  order: 0,
}
