import { createContext } from 'react'

interface ScreenSizeContextInterface {
  screenWidth: number | undefined
  screenHeight: number | undefined
}
/**
 * isWeb - Flag to switch web and native theme in feature-fantasy library
 * isLiveDraftPage - Live draft assistant page
 * isDraftPage - Mock draft simulator / Live draft assistant page
 */
const defaultProviderValue = {
  screenWidth: undefined,
  screenHeight: undefined,
}

export const ScreenSizeContext = createContext<ScreenSizeContextInterface>(defaultProviderValue)
