import { InjuryStatus, PlayersInjuryRecord, Injuries as PlayerInjuryRecord, InjuryData } from '@pff-consumer/schema'

export const transformPlayerInjuryStatusResponse = (injuryDataResponse: InjuryData[]) => {
  const injuryRecord = injuryDataResponse.flatMap((val) => val.injuries)

  const playersStatusMap: PlayersInjuryRecord = injuryRecord.reduce(
    (acc: { [key: number]: InjuryStatus }, player: PlayerInjuryRecord) => {
      return {
        ...acc,
        [player.playerId]: player.status as InjuryStatus,
      }
    },
    {}
  )

  return playersStatusMap
}
