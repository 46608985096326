import { AddListenerOverloads } from '@reduxjs/toolkit/dist/listenerMiddleware/types'
import { isAnyOf } from '@reduxjs/toolkit'
import { consumerApi } from '../../lib/consumer-api/consumer-api'
import { fantasySlice } from '../../lib/fantasy/fantasy.slice'
import type { AppRootState } from '../store'
import { addPlayerInjuryStatusToWeeklyRankingsData } from '../utils/add-player-injury-status-to-weekly-rankings-data'

export const addFantasyStandaloneRankingsListeners = (startListening: AddListenerOverloads<unknown>) => {
  startListening({
    matcher: isAnyOf(
      fantasySlice.actions.updateFilterScoringType.match,
      fantasySlice.actions.updateFilterPosition.match,
      consumerApi.endpoints.getRankings.matchFulfilled
    ),
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState() as AppRootState
      listenerApi.cancelActiveListeners()
      const { filters } = state.fantasy
      const prunedFilters = { ...filters }
      // Player name search and tags are filtered on FE
      Reflect.deleteProperty(prunedFilters, 'search')
      Reflect.deleteProperty(prunedFilters, 'tags')
      const { data, status } = consumerApi.endpoints.getRankings.select(prunedFilters)(state)
      const players = data?.players || []
      const lastUpdatedAt = data?.lastUpdatedAt || null
      if (status === 'fulfilled') {
        listenerApi.dispatch(fantasySlice.actions.updateRankings(players))
        listenerApi.dispatch(fantasySlice.actions.updateDraftRankingsUpdatedTime(lastUpdatedAt))
      }
    },
  })
  startListening({
    matcher: isAnyOf(
      fantasySlice.actions.updateInSeasonWeeklyRankingsFilterScoringType.match,
      fantasySlice.actions.updateInSeasonWeeklyRankingsFilterPosition.match,
      consumerApi.endpoints.getWeeklyRankings.matchFulfilled,
      consumerApi.endpoints.getPlayerInjuryStatusFantasy.matchFulfilled
    ),
    effect: async (action, listenerApi) => {
      listenerApi.cancelActiveListeners()
      const state = listenerApi.getState() as AppRootState
      const { filters } = state.fantasy.inSeason.weeklyRankings
      const prunedFilters = { ...filters }
      // Player name search and tags are filtered on FE, we are no longer filtering players based on rankers
      Reflect.deleteProperty(prunedFilters, 'search')
      Reflect.deleteProperty(prunedFilters, 'tags')
      const { data, status } = consumerApi.endpoints.getWeeklyRankings.select(prunedFilters)(state)
      if (status === 'fulfilled') {
        let playerRankings = data.rankings
        const { lastUpdatedAt } = data
        const currentGameWeek = Number(data.currentGameWeek)
        const { data: injuryData, status: injuryDataStatus } =
          consumerApi.endpoints.getPlayerInjuryStatusFantasy.select()(state)
        if (injuryDataStatus === 'fulfilled') {
          playerRankings = addPlayerInjuryStatusToWeeklyRankingsData(playerRankings, injuryData)
        }
        listenerApi.dispatch(fantasySlice.actions.updateInSeasonWeeklyRankings(playerRankings))
        listenerApi.dispatch(fantasySlice.actions.updateWeeklyRankingsUpdatedTime(lastUpdatedAt))
        listenerApi.dispatch(fantasySlice.actions.updateCurrentGameWeek(currentGameWeek))
      }
    },
  })
}
