import { colors } from '@pff-consumer/core-ui'

const MATCHUP_POOR_GRADE_MAX = 2
const MATCHUP_GREAT_GRADE_MIN = 4

const TOTAL_BARS = 5

export const getAdvantageBarGradeColorsArray = (score: number) => {
  const barColorArray = Array(TOTAL_BARS).fill(colors.matchupBarNeutralFourthGradientColorSecondary)
  let barColor

  if (score >= MATCHUP_GREAT_GRADE_MIN) {
    barColor = colors.matchupPlayerGradeGreat
  } else if (score <= MATCHUP_POOR_GRADE_MAX) {
    barColor = colors.matchupPlayerGradePoorSecondary
  } else {
    barColor = colors.matchupPlayerGradeGood
  }

  barColorArray.splice(0, score, ...Array(score).fill(barColor))
  return barColorArray
}
