import { LiveDraftPicksStore } from '@pff-consumer/schema'
import type { Keeper } from '@pff-consumer/schema/fantasy-live-draft/keeper'

export const getLatestLiveDraftPickWithoutKeepers = (picks: LiveDraftPicksStore, keepers: Keeper[]) => {
  const keeperPicks = [...keepers]
  const liveDraftPicks = Object.values({ ...picks })

  const liveDraftPicksWithoutKeepers = liveDraftPicks.filter(
    (player) => !keeperPicks.some((keeper) => keeper.pickNumber === player.draftPick)
  )

  const latestPick = liveDraftPicksWithoutKeepers.toSorted((a, b) => a.draftPick - b.draftPick).at(-1)

  return latestPick
}
