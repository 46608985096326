import type { PaginatedPlayerPropBets, PlayerPropBetsGroup } from '@pff-consumer/schema'

/**
 * Combines array of the results from individual fetchWithBQ and combine the result to an object of type Record<prop_key, api result>
 * Also the reduce populates the sportsbookBetIds array with the sportsbookBetId from the above api result
 */
export function combinePaginatedResults(data: PaginatedPlayerPropBets[]) {
  const sportsbookBetIds: String[] = []
  const propBets = data.reduce<PlayerPropBetsGroup>((acc, paginatedValues) => {
    paginatedValues.playerProps?.forEach((value) => sportsbookBetIds.push(value.sportsbookBetId))

    if (paginatedValues?.playerProps[0]?.market) {
      return { ...acc, [paginatedValues.playerProps[0].market]: paginatedValues }
    }

    return acc
  }, {})
  return { propBets, sportsbookBetIds }
}
