import type { InjuryData, Injuries } from '@pff-consumer/schema'
import { InjuryStatusAbbreviation, InjuryStatusAbbreviationMap, InjuryStatus } from '@pff-consumer/schema'

interface PlayerInjuryStatusParams {
  isActive: boolean
  selectedPlayerId: number
  events?: InjuryData[]
}

export const getPlayerInjuryStatus = (params: PlayerInjuryStatusParams): InjuryStatusAbbreviation => {
  const { isActive, selectedPlayerId, events } = params

  if (!isActive) {
    return InjuryStatusAbbreviation.INACTIVE
  }

  if (!events) {
    return InjuryStatusAbbreviation.ACTIVE
  }

  const flattenedInjuries = events?.reduce((acc: Injuries[], event: InjuryData) => acc.concat(event.injuries), [])

  const selectedPlayerInjuryStatus = flattenedInjuries.filter(
    (injury: Injuries) => injury.playerId === selectedPlayerId
  )

  if (selectedPlayerInjuryStatus.length > 0) {
    return InjuryStatusAbbreviationMap[selectedPlayerInjuryStatus[0].status as InjuryStatus]
  }

  return InjuryStatusAbbreviation.ACTIVE
}
