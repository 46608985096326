import AsyncStorage from '@react-native-async-storage/async-storage'
import { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

const STORAGE_KEY = '@uniqueDeviceId'

let uniqueDeviceId: string | null = null

const saveDeviceIdToStorage = async (deviceId: string) => {
  if (deviceId) {
    try {
      await AsyncStorage.setItem(STORAGE_KEY, deviceId)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug('Could not save device Id', e)
    }
  }
}

/**
 * This will get OR create the unique device id.
 */
export const getUniqueDeviceIdFromStorage = async (): Promise<string | null> => {
  try {
    const uniqueDeviceIdString = await AsyncStorage.getItem(STORAGE_KEY)
    if (uniqueDeviceIdString) {
      return uniqueDeviceIdString
    }
    // If we don't have an ID, let's make one.
    const uuid = uuidv4()
    await saveDeviceIdToStorage(uuid)
    return uuid
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug('Could get uniqueDeviceId', e)
    return null
  }
}

/**
 * This hook allows us to initialize the unique device ID (async), even if it doesn't exist
 * and then returns it (sync)
 */
export const useUniqueDeviceId = () => {
  /**
   * Dont call this more than once..
   */
  const initUniqueDeviceId = useCallback(async () => {
    const storedDeviceId = await getUniqueDeviceIdFromStorage()
    uniqueDeviceId = storedDeviceId
    return true
  }, [])

  const getUniqueDeviceId = useCallback(() => uniqueDeviceId, [])

  return { initUniqueDeviceId, uniqueDeviceId, getUniqueDeviceId }
}
