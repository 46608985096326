export enum GameSimulatorAnalyticsEventTypes {
  SELECT_TEAM = 'Game Simulator Select Team',
  CTA_CLICK = 'Game Simulator CTA Click',
  SIMULATION_COMPLETE = 'Season Simulation Completed',
  SHOW_ALL_PLAYERS_TOGGLE = 'Toggle Show All Players',
  GAME_CLICK = 'Game Click',
  LINEUP_PLAYER_CLICK = 'Line Up Player',
}

export enum GameSimulatorCtas {
  SIMULATE_SEASON = 'Simulate Season',
  START_NEW = 'Start New',
  SIMULATE_AGAIN = 'Simulate this season again',
  ADJUST_LINEUPS = 'Adjust Lineups',
  SHARE_RESULTS = 'Share Simulation Results',
  SHOW_ALL_SEASON_STATS = 'Show All Season Stats',
  GO_BACK = 'Go Back',
  WHATS_NEW = 'Whats New?',
}

export interface GameSimulatorTeamInfo {
  teamAbv: string
  teamId: number
  skipNextJsAuth?: boolean
}

export interface GameSimulatorCtaClickInfo {
  simulatorCta: GameSimulatorCtas
  skipNextJsAuth?: boolean
}

export interface GameSimulatorShowAllPlayersInfo {
  showAll: boolean
  playerType: string
  skipNextJsAuth?: boolean
}

export interface GameClickInfo {
  gameSelected: boolean
  gameSimulationId: number
  simulatingTeamId: number
  opponentTeamId: number
  skipNextJsAuth?: boolean
}

export interface GameSimulatorLineupPlayerClickInfo {
  teamId: number
  teamAbv: string
  playerPosition: string
  skipNextJsAuth?: boolean
}

export type GameSimulatorAnalyticsEvents = `${GameSimulatorAnalyticsEventTypes}`

export type GameSimulatorAnalyticsEventProperties = GameSimulatorTeamInfo &
  GameSimulatorCtaClickInfo &
  GameSimulatorShowAllPlayersInfo &
  GameClickInfo &
  GameSimulatorLineupPlayerClickInfo

export type GameSimulatorAnalyticsEventArgs =
  | [eventName: GameSimulatorAnalyticsEventTypes.SELECT_TEAM, eventProperties: GameSimulatorTeamInfo]
  | [eventName: GameSimulatorAnalyticsEventTypes.CTA_CLICK, eventProperties: GameSimulatorCtaClickInfo]
  | [eventName: GameSimulatorAnalyticsEventTypes.SIMULATION_COMPLETE, eventProperties: GameSimulatorTeamInfo]
  | [
      eventName: GameSimulatorAnalyticsEventTypes.SHOW_ALL_PLAYERS_TOGGLE,
      eventProperties: GameSimulatorShowAllPlayersInfo
    ]
  | [eventName: GameSimulatorAnalyticsEventTypes.GAME_CLICK, eventProperties: GameClickInfo]
  | [
      eventName: GameSimulatorAnalyticsEventTypes.LINEUP_PLAYER_CLICK,
      eventProperties: GameSimulatorLineupPlayerClickInfo
    ]
