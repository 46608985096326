import { SportsbookType } from '@pff-consumer/schema'

export const getSportsbookInfo = (sportsbookId: SportsbookType) => {
  const sportsbookInfo = {
    BETMGM: { text: 'BetMGM', logoName: 'betmgm' },
    FD: { text: 'FanDuel', logoName: 'fanduel' },
  }[sportsbookId]

  return sportsbookInfo || { text: 'BetMGM', logoName: 'betmgm' }
}

export const getTotalBetsCartLength = (betsCartLength: Record<SportsbookType, number>): number => {
  return Object.keys(betsCartLength).reduce((sum, sportsbook) => sum + betsCartLength[sportsbook], 0)
}
