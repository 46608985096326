import type { ViewStyle, ImageStyle, TextStyle } from 'react-native'
import type { Theme } from '@pff-consumer/core-ui'
import {
  BorderRadius as borderRadius,
  colors,
  colorsDEPRECATED,
  generateUtilityStyles,
  spacing,
  ZIndex as zIndex,
  Opacity as opacity,
} from '@pff-consumer/core-ui'
import { Platform } from 'react-native'
import { useMemo } from 'react'
import { useFontStyles } from './create-use-fonts'
import { useDimensions, defaultDimensions } from './use-dimensions'
import { generateBreakpoints } from './breakpoints'

type CalcUnitFunction = (n: number) => number
export type Styles = ViewStyle | ImageStyle | TextStyle

const calcUnit: CalcUnitFunction = (n) => n
const utilityStyles = generateUtilityStyles(calcUnit)
const breakpoint = generateBreakpoints<Styles>(defaultDimensions)

export type NativeUiTheme = Theme<CalcUnitFunction, Styles>

export const defaultTheme: NativeUiTheme = {
  platform: Platform.OS || 'native',
  borderRadius,
  calcUnit,
  colors,
  colorsDEPRECATED,
  utilityStyles,
  useFontStyles,
  spacing,
  breakpoint,
  zIndex,
  opacity,
}

let theme = defaultTheme

export const registerTheme = <ThemeType extends Theme<any, any>>(val: ThemeType) => {
  theme = val
}

export const useTheme = (): NativeUiTheme => {
  const dimensions = useDimensions()

  return useMemo<NativeUiTheme>(
    () => ({
      ...theme,
      breakpoint: generateBreakpoints(dimensions),
    }),
    [dimensions]
  )
}
