import { FantasyPosition } from '@pff-consumer/schema'
import { PlayerPosition } from '@pro-football-focus/pffkit-js-core'

export function getPlayerByPosition(
  players: { id: number | null; position: FantasyPosition | PlayerPosition | null }[],
  position: FantasyPosition | PlayerPosition
) {
  return players.flatMap(({ position: playerPosition, id }) => {
    if (!id || !playerPosition) {
      return []
    }
    if (playerPosition === position) {
      return id
    }
    return []
  })
}
