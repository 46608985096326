import { FANTASY_SEASON } from '@pff-consumer/schema'

export const fantasyEndpoints = {
  rankerInsights: () => 'football/v1/fantasy/ranker-insights',
  rankings: () => 'football/v1/fantasy/rankings',
  weeklyRankings: () => 'football/v1/fantasy/weekly-rankings',
  waiverTargets: () => 'football/v1/fantasy/waiver-targets',
  matchups: () => 'football/v1/fantasy/matchups',
  cheatsheet: () => 'football/v1/fantasy/cheatsheet',
  deleteLeague: (leagueId: string) => `fantasy/v1/league-sync/leagues/${leagueId}`,
  getMyLeagues: ({ season }: { season: number | string }) => ({
    url: `fantasy/v1/league-sync/leagues?season=${season}`,
  }),
  getLeagueDraftPicks: ({ leagueId }: { leagueId: number }) => `fantasy/v1/league-sync/leagues/${leagueId}/draft-picks`,
  getLeagueDrafters: ({ leagueId, season = FANTASY_SEASON }: { leagueId: number; season: number }) =>
    `fantasy/v1/league-sync/leagues/${leagueId}/drafters?season=${season}`,
  getLeagueKeepers: ({ leagueId }: { leagueId: number }) => `/fantasy/v1/league-sync/leagues/${leagueId}/keepers`,
  getSleeperLeagues: (username: string) => ({
    url: `fantasy/v1/league-sync/sleeper/leagues/${username}`,
  }),
  syncSleeperLeague: (sleeperLeagueId: string) =>
    `fantasy/v1/league-sync/sleeper/leagues/${sleeperLeagueId}/sync-league`,
  syncEspnLeague: () => `fantasy/v1/league-sync/espn/leagues/sync-league`,
  syncYahooLeague: () => `fantasy/v1/league-sync/yahoo/leagues/sync-league`,
  resyncFantasyLeague: (leagueId: string) => `fantasy/v1/league-sync/leagues/${leagueId}/resync`,
  createManualLeague: () => `fantasy/v1/league-sync/leagues`,
  showResyncLeaguesModal: ({ season }: { season: number }) =>
    `fantasy/v1/league-sync/show-resync-leagues-modal?season=${season}`,

  mds: {
    initialize: () => `fantasy/v1/mds/initialize`,
    getNextPickInfo: () => `fantasy/v1/mds/next-pick-info`,
    getNextCpuPicks: () => `fantasy/v1/mds/next-cpu-picks`,
    getResults: () => `fantasy/v1/mds/results`,
    getUpdatedTime: () => `fantasy/v1/mds/get-updated-time`,
  },
  inSeason: {
    getMatchupRecord: ({ leagueId }: { leagueId: number }) =>
      `fantasy/v1/league-sync/leagues/${leagueId}/matchup-record`,
    getRoster: ({ leagueId }: { leagueId: number }) => `fantasy/v1/league-sync/leagues/${leagueId}/fantasy-roster`,
  },
}
