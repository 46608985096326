import { Platform } from 'react-native'
import { AnalyticsModule } from './shared/analytics-types'

const analyticsModule: AnalyticsModule = Platform.select({
  // eslint-disable-next-line import/extensions, global-require
  ios: () => require('./native/index.native.ts'),
  // eslint-disable-next-line import/extensions, global-require
  android: () => require('./native/index.native.ts'),
  // eslint-disable-next-line import/extensions, global-require
  web: () => require('./web/index.web.ts'),
  default: () => ({
    useAnalytics: () => null,
    withNavigationAnalytics: () => null,
  }),
})()

export const { useAnalytics } = analyticsModule
export const { withNavigationAnalytics } = analyticsModule
// TODO move this to a betting feature lib
export const { useBettingAnalytics } = analyticsModule
export * from './shared/analytics-types'
export * from './web/launch-darkly'
export * from './hooks/useFantasyToolsAnalyticsEvent'
