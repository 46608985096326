import type { FootballPlayer, NFLTeamWithLogo } from '@pff-consumer/schema'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { useGetPlayerQuery } from '../consumer-api'
import { useGetTeam } from './use-get-team'

export interface FootballPlayerWithTeam extends FootballPlayer {
  team: NFLTeamWithLogo
}

export const useGetPlayer = (id?: number) => {
  const { data: playerData, isLoading: playerLoading } = useGetPlayerQuery(id ? { id } : skipToken)
  const { data: teamData, isLoading: teamLoading } = useGetTeam(playerData?.teamId || undefined)
  if (!playerData || playerLoading || !teamData || teamLoading)
    return { data: null, isLoading: playerLoading || teamLoading }

  const playerWithTeam: FootballPlayerWithTeam = {
    ...playerData,
    team: teamData,
  }

  return { data: playerWithTeam, isLoading: playerLoading }
}
