import { Dimensions } from 'react-native'
import { useEffect, useState } from 'react'
import { BreakpointOrientation, getBreakpointSize } from '@pff-consumer/core-ui'

type DisplayMetrics = {
  width: number
  height: number
  scale: number
  fontScale: number
  orientation: BreakpointOrientation
}

// USE_SSR and defaultDisplayMetrics help with SSR where window is always 0 on the server
// https://dev.to/redbar0n/how-to-combine-react-native-web-responsivity-nextjs-ssr-to-get-seo-a4g
const USE_SSR = process.env.USE_SSR === 'true'
const defaultDisplayMetrics: DisplayMetrics = {
  fontScale: 1,
  height: 0,
  scale: 1,
  width: 0,
  orientation: 'landscape',
}

export type DimensionsSet = {
  windowDimensions: DisplayMetrics
  screenDimensions: DisplayMetrics
}

export type DevicesSet = {
  isXS: boolean
  isSM: boolean
  isMD: boolean
  isLG: boolean
  isXL: boolean
  isXXL: boolean
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

const decorateDimensions = (dimensions: Omit<DisplayMetrics, 'orientation'>): DisplayMetrics => {
  const { height, width } = dimensions
  const orientation = height > width ? 'portrait' : 'landscape'

  return { ...dimensions, orientation }
}

export const defaultDimensions = {
  windowDimensions: decorateDimensions(Dimensions.get('window')),
  screenDimensions: decorateDimensions(Dimensions.get('screen')),
}

export const useDimensions = (): DimensionsSet => {
  const [screenDimensions, setScreenDimensions] = useState<DisplayMetrics>(
    USE_SSR ? defaultDisplayMetrics : decorateDimensions(Dimensions.get('screen'))
  )
  const [windowDimensions, setWindowDimensions] = useState<DisplayMetrics>(
    USE_SSR ? defaultDisplayMetrics : decorateDimensions(Dimensions.get('window'))
  )

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window, screen }) => {
      setWindowDimensions(decorateDimensions(window))
      setScreenDimensions(decorateDimensions(screen))
    })
    setWindowDimensions(decorateDimensions(Dimensions.get('screen')))
    setScreenDimensions(decorateDimensions(Dimensions.get('window')))
    return () => subscription?.remove()
  }, [])

  return { screenDimensions, windowDimensions }
}

export const useBreakpoints = (): DevicesSet => {
  const [windowDimensions, setWindowDimensions] = useState<DisplayMetrics>(decorateDimensions(Dimensions.get('window')))
  const { width } = windowDimensions
  const breakpointSize = getBreakpointSize(width)

  const [isXS, setXS] = useState(breakpointSize === 'xs')
  const [isSM, setSM] = useState(breakpointSize === 'sm')
  const [isMD, setMD] = useState(breakpointSize === 'md')
  const [isLG, setLG] = useState(breakpointSize === 'lg')
  const [isXL, setXL] = useState(breakpointSize === 'xl')
  const [isXXL, setXXL] = useState(breakpointSize === 'xxl')

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setWindowDimensions(decorateDimensions(window))
    })
    return () => subscription?.remove()
  }, [])

  useEffect(() => {
    setXS(getBreakpointSize(width) === 'xs')
    setSM(getBreakpointSize(width) === 'sm')
    setMD(getBreakpointSize(width) === 'md')
    setLG(getBreakpointSize(width) === 'lg')
    setXL(getBreakpointSize(width) === 'xl')
    setXXL(getBreakpointSize(width) === 'xxl')
  }, [width])

  return {
    isXS,
    isSM,
    isMD,
    isLG,
    isXL,
    isXXL,
    isMobile: isXS || isSM,
    isTablet: isMD,
    isDesktop: isLG || isXL || isXXL,
  }
}
