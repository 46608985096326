import type { ManualLeagueSettings } from '@pff-consumer/schema/fantasy-mds/manual-league-settings'
import { DraftTypes, type MDSLeagueSettings } from '@pff-consumer/schema'
import { getBenchSpots } from '@pff-consumer/utils'
import { getPPRValue } from './get-ppr-value'

export const transformManualSettings = (settings: ManualLeagueSettings, leagueSettings: MDSLeagueSettings) => {
  const leagueCpuWeighting = new Array(settings.numberOfTeams).fill(0.5)

  return {
    starting_WR: settings.starters.wr,
    starting_RB: settings.starters.rb,
    starting_TE: settings.starters.te,
    starting_QB: settings.starters.qb,
    starting_flex: settings.starters.flex,
    starting_superflex: settings.starters.superflex,
    starting_WRRB: settings.starters.wrrb_flex,
    starting_WRTE: settings.starters.rec_flex,
    starting_K: settings.starters.k,
    starting_DST: settings.starters.dst,
    fantasy_players_in_league: settings.numberOfTeams,
    user_pick: settings.userPick,
    rounds: settings.rounds,
    bench_spots: getBenchSpots(settings.starters, settings.rounds),
    third_round_reversal: settings.draftType === DraftTypes.THIRD_ROUND_REVERSAL ? 1 : 0,
    linear_drafting: settings.draftType === DraftTypes.LINEAR ? 1 : 0,
    ppr: getPPRValue(settings.scoringType),
    projection_weight_jahnke: 1,
    projection_weight_experts: 1,
    projection_weight_pff_simulation: 1,
    user_strategy_zero_rb: leagueSettings.user_strategy_zero_rb ? 1 : 0,
    user_strategy_late_qb: leagueSettings.user_strategy_late_qb ? 1 : 0,
    user_strategy_stacking: leagueSettings.user_strategy_stacking ? 1 : 0,
    team_affinity_aversion_strength: 1,
    cpu_stacking: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    cpu_wr: leagueCpuWeighting,
    cpu_rb: leagueCpuWeighting,
    cpu_qb: leagueCpuWeighting,
    cpu_te: leagueCpuWeighting,
    cpu_team_affinity: leagueSettings.cpu_team_affinity,
    cpu_team_aversion: leagueSettings.cpu_team_aversion,
  }
}
