import { isNumber } from 'lodash'

export type BreakpointSize = 'mini' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
export type BreakpointOrientation = 'landscape' | 'portrait'
export type BreakpointRange = {
  min: number
  max: number
}

const breakpointRanges: { [Size in BreakpointSize]: BreakpointRange } = {
  mini: { min: 0, max: 390 },
  xs: { min: 391, max: 420 },
  sm: { min: 421, max: 640 },
  md: { min: 641, max: 960 },
  lg: { min: 961, max: 1280 },
  xl: { min: 1281, max: 1600 },
  xxl: { min: 1440, max: 4000 },
}

// eslint-disable-next-line consistent-return
export const getBreakpointRange = (breakpointSize: BreakpointSize): BreakpointRange => {
  // eslint-disable-next-line default-case
  switch (breakpointSize) {
    case 'mini':
      return breakpointRanges.mini
    case 'xs':
      return breakpointRanges.xs
    case 'sm':
      return breakpointRanges.sm
    case 'md':
      return breakpointRanges.md
    case 'lg':
      return breakpointRanges.lg
    case 'xl':
      return breakpointRanges.xl
    case 'xxl':
      return breakpointRanges.xxl
  }
}

export const getBreakpointMaxOrValue = (breakpointSize: BreakpointSize | number): BreakpointRange['max'] | number =>
  isNumber(breakpointSize) ? breakpointSize : getBreakpointRange(breakpointSize).max

export const getBreakpointMinOrValue = (breakpointSize: BreakpointSize | number): BreakpointRange['min'] | number =>
  isNumber(breakpointSize) ? breakpointSize : getBreakpointRange(breakpointSize).min

export const getBreakpointSize = (width: number): BreakpointSize | string => {
  switch (true) {
    case width >= breakpointRanges.mini.min && width <= breakpointRanges.mini.max:
      return 'mini'
    case width >= breakpointRanges.xs.min && width <= breakpointRanges.xs.max:
      return 'xs'
    case width >= breakpointRanges.sm.min && width <= breakpointRanges.sm.max:
      return 'sm'
    case width >= breakpointRanges.md.min && width <= breakpointRanges.md.max:
      return 'md'
    case width >= breakpointRanges.lg.min && width <= breakpointRanges.lg.max:
      return 'lg'
    case width >= breakpointRanges.xl.min:
      return 'xl'
    // Custom breakpoint to support conditional rendering for 1440
    case width >= breakpointRanges.xxl.min:
      return 'xxl'
    default:
      return 'xs'
  }
}
