// Check if the date is expired
// Note: We have to force the date to be the end of the specified day so we don't expire it a day early
export function isExpired(date?: string): boolean {
  if (!date) return true

  const expireDate = new Date(date)
  expireDate.setUTCHours(23)
  expireDate.setUTCMinutes(59)
  expireDate.setUTCSeconds(59)

  return expireDate.getTime() < Date.now() // compares epoch timestamp
}
