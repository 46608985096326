import { removeUserFromHeap } from './heap'

export const removeUser = (): void => {
  try {
    removeUserFromHeap()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug(e)
  }
}
