import { jwtDecode, type JwtPayload } from 'jwt-decode'
import type { GrootAuthToken } from '@pff-consumer/schema'

interface ConsumerApiJwtPayload extends JwtPayload {
  token: GrootAuthToken
}

// jwtDecode expects the token passed to it to be a valid one
// it throws an error if an invalid or empty token is passed for decoding
// to avoid app crashes we are catching the error returning empty string for invalid tokens
export const getGrootTokenFromJwt = (token: string) => {
  try {
    const decodedJwtPayload = jwtDecode<ConsumerApiJwtPayload>(token)
    return decodedJwtPayload.token.access_token
  } catch (err) {
    return ''
  }
}
