import { BettingPropKeyArray } from '../players/player-betting'

interface GameScriptAudit {
  content: string
}

export interface GameScriptUpdate {
  templateId: string
  gameId: number
  playerId: number
  header: string
  text: string
  displayOrder: number
  rank: number
  audit: GameScriptAudit
}

export interface GameScriptDB {
  id: number
  game_id: number
  header: string
  text: string
  rank: string
  display_order: number
  template_id: string
  is_approved: string
}

export interface WarehouseGameScript {
  id: number
  is_approved: boolean
  game_id: number
  header: string
  text: string
  rank: string
  away_team: string
  home_team: string
  player_team: string
  player_id: number
  first_name: string
  last_name: string
  position: string
  bettingPropKeys: BettingPropKeyArray | null
}

export interface WarehouseGameScriptGame {
  game_id: number
  season_id: number
  week_abbreviation: string
  week_id: number
  away_team: string
  home_team: string
  away_team_nickname: string
  home_team_nickname: string
}

export enum GameScriptType {
  Pregame = 'pregame',
  Live = 'live',
}

export enum MetricDirection {
  High = 'high',
  Low = 'low',
}

export interface Sample {
  header: string
  body: string
}

export interface TemplateMetric {
  positionType: string
  metricKey: string
  metricDirection: MetricDirection
  useInScript: boolean
  rankingWeight: number
  gtThreshold?: number | null
  ltThreshold?: number | null
  equalsIn?: string[] | null
  bettingPropKeys?: BettingPropKeyArray | null
}

export interface GameScriptTemplate {
  id: string
  name: string
  situation: string
  samples: Sample[]
  scriptType: GameScriptType
  metrics: TemplateMetric[]
}

export interface GameScriptTemplateUpdate extends Omit<GameScriptTemplate, 'id'> {}

export interface WarehouseGameScriptTemplateUpdate extends Omit<GameScriptTemplateUpdate, 'samples'> {
  samples: string[]
}

export interface WarehouseGameScriptTemplate {
  id: string
  name: string
  situation: string
  samples: string[]
  script_type: GameScriptType
  metric_position_type: string
  metric_key: string
  gt_threshold: string
  lt_threshold: string
  equals_in: string[]
  ranking_weight: string
  use_in_script: boolean
  metric_direction: MetricDirection
}

export interface WarehouseGameScriptTemplateGrouped {
  id: string
  name: string
  situation: string
  samples: string[]
  script_type: GameScriptType
  metrics: TemplateMetric[]
}
