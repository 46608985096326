export enum InSeasonTabIndex {
  MY_TEAM = 0,
  RANKING = 1,
  START_SIT = 2,
  MATCHUPS = 3,
  WAIVER_TARGETS = 4,
}

export enum FantasyUrlPaths {
  MY_LEAGUES = 'fantasy/leagues',
  MY_TEAM_DASHBOARD = '/fantasy/my-team',
  SYNC_LEAGUES = '/fantasy/league-sync?step=1',
  MOBILE_SYNC_LEAGUES = '/mobile-app/fantasy/league-sync',
}
