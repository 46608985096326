import { PostSeasonWeeksMap } from '@pff-consumer/schema'
import { getWeekDescriptionFromWeekAbv } from './get-week-desc-from-week-abv'

export const getEventWeeks = () => {
  const regularWeeks = Array.from({ length: 18 }, (v, i) => ({
    label: getWeekDescriptionFromWeekAbv((i + 1).toString()),
    value: (i + 1).toString(),
  }))

  // We want to filter out the duplicate number post season keys so we do not get duplicate weeks in dropdown
  const postWeeks = Object.keys(PostSeasonWeeksMap)
    .filter((key) => Number.isNaN(Number(key)))
    .map((weekAbv) => {
      return { label: getWeekDescriptionFromWeekAbv(weekAbv), value: weekAbv }
    })

  return [...regularWeeks, ...postWeeks]
}
