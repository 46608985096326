import FuzzySearch from 'fuzzy-search'
import { Matchup } from '@pff-consumer/schema/sdk/matchups'

export const getMatchupsFilteredByName = (matchups: Matchup[], searchTerm: string) => {
  // Create new searcher whenever the data changes
  const fieldsToSearch = ['player.firstName', 'player.lastName']

  const fuzzySearcher = new FuzzySearch(matchups || [], fieldsToSearch, {
    caseSensitive: false,
  })

  // Stop fuzzy searching breaking when users use apostrophes that are in players names, so strip them out and fuzzy will still work
  const prunedSearchTerm = searchTerm.replaceAll('’', '').replaceAll(`'`, '').replaceAll('`', '')
  const filteredResults = fuzzySearcher.search(prunedSearchTerm)
  return filteredResults
}
