interface TeamColor {
  primary: string
  secondary: string
}

type TeamColors = {
  [key: string]: TeamColor
}

export const teamColors: TeamColors = {
  ARI: {
    primary: '#97233F',
    secondary: '#FFB612',
  },
  ARZ: {
    primary: '#97233F',
    secondary: '#FFB612',
  },
  ATL: {
    primary: '#000000',
    secondary: '#A71930',
  },
  BAL: {
    primary: '#241773',
    secondary: '#000000',
  },
  BLT: {
    primary: '#241773',
    secondary: '#000000',
  },
  BUF: {
    primary: '#00338D',
    secondary: '#C60C30',
  },
  CAR: {
    primary: '#101820',
    secondary: '#0085CA',
  },
  CHI: {
    primary: '#0B162A',
    secondary: '#C83803',
  },
  CIN: {
    primary: '#000000',
    secondary: '#FB4F14',
  },
  CLE: {
    primary: '#311D00',
    secondary: '#FF3C00',
  },
  CLV: {
    primary: '#311D00',
    secondary: '#FF3C00',
  },
  DAL: {
    primary: '#041E42',
    secondary: '#869397',
  },
  DEN: {
    primary: '#002244',
    secondary: '#FB4F14',
  },
  DET: {
    primary: '#0076B6',
    secondary: '#B0B7BC',
  },
  GB: {
    primary: '#203731',
    secondary: '#FFB612',
  },
  HOU: {
    primary: '#03202F',
    secondary: '#A71930',
  },
  HST: {
    primary: '#03202F',
    secondary: '#A71930',
  },
  IND: {
    primary: '#002C5F',
    secondary: '#A2AAAD',
  },
  JAC: {
    primary: '#006778',
    secondary: '#D7A22A',
  },
  JAX: {
    primary: '#006778',
    secondary: '#D7A22A',
  },
  KC: {
    primary: '#E31837',
    secondary: '#FFB81C',
  },
  LV: {
    primary: '#000000',
    secondary: '#A5ACAF',
  },
  LAC: {
    primary: '#0080C6',
    secondary: '#FFC20E',
  },
  LAR: {
    primary: '#003594',
    secondary: '#FFD100',
  },
  LA: {
    primary: '#003594',
    secondary: '#FFD100',
  },
  MIA: {
    primary: '#008E97',
    secondary: '#FC4C02',
  },
  MIN: {
    primary: '#4F2683',
    secondary: '#FFC62F',
  },
  NE: {
    primary: '#002244',
    secondary: '#C60C30',
  },
  NO: {
    primary: '#101820',
    secondary: '#D3BC8D',
  },
  NYG: {
    primary: '#0B2265',
    secondary: '#A71930',
  },
  NYJ: {
    primary: '#125740',
    secondary: '#000000',
  },
  PHI: {
    primary: '#004C54',
    secondary: '#A5ACAF',
  },
  PIT: {
    primary: '#101820',
    secondary: '#FFB612',
  },
  SF: {
    primary: '#AA0000',
    secondary: '#B3995D',
  },
  SEA: {
    primary: '#002244',
    secondary: '#69BE28',
  },
  TB: {
    primary: '#D50A0A',
    secondary: '#FF7900',
  },
  TEN: {
    primary: '#0C2340',
    secondary: '#4B92DB',
  },
  WAS: {
    primary: '#5A1414',
    secondary: '#FFB612',
  },
}
