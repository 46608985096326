export interface TeamInfo {
  abbreviation: string
  nickname: string
}

export enum OffenseMetricPosition {
  TeamOffense = 'team-offense',
  Quarterback = 'quarterback',
  TightEnd = 'tight-end',
  WideReceiver = 'wide-receiver',
  RunningBack = 'running-back',
  OffensiveLine = 'ol',
}

export enum DefenseMetricPosition {
  DefensiveLine = 'dl',
  TeamDefense = 'team-defense',
  Cornerback = 'cornerback',
}

export enum OffensePlayerPosition {
  Quarterback = 'quarterback',
  TightEnd = 'tight-end',
  WideReceiver = 'wide-receiver',
  RunningBack = 'running-back',
  OffensiveLine = 'ol',
}

export enum DefensePlayerPosition {
  Cornerback = 'cornerback',
  DefensiveLine = 'dl',
}

export enum MetricPosition {
  Quarterback = 'quarterback',
  TightEnd = 'tight-end',
  WideReceiver = 'wide-receiver',
  RunningBack = 'running-back',
  DefensiveLine = 'dl',
  OffensiveLine = 'ol',
  TeamDefense = 'team-defense',
  TeamOffense = 'team-offense',
  Cornerback = 'cornerback',
}

export interface PlayerMetric {
  team_abbreviation: string
  team_name: string
  player_id: number
  player: string
  position: string
}

export interface HealthyPlayerMetric extends PlayerMetric {
  injured_player_id: number
}

export interface InjuredPlayerMetric extends PlayerMetric {
  injury_status: string
  last_played_week: number
}

export interface TeamDefensiveMetric {
  defense_abbreviation: string
  defense_name: string
  position: string
}

export interface TeamOffensiveMetric {
  offense_abbreviation: string
  offense_name: string
  position: string
}

export interface WideReceiverMetric extends PlayerMetric, Record<string, any> {}

export interface QuarterbackMetric extends PlayerMetric, Record<string, any> {}

export interface CornerbackMetric extends PlayerMetric, Record<string, any> {}

export interface TightEndMetric extends PlayerMetric, Record<string, any> {}

export interface RunningBackMetric extends PlayerMetric, Record<string, any> {}

export interface PlayersMetric extends PlayerMetric, Record<string, any> {}

export interface InjuredPlayerImpactMetrics extends InjuredPlayerMetric, Record<string, any> {}

export interface HealthyPlayerMetrics extends HealthyPlayerMetric, Record<string, any> {}

export interface TeamDefenseMetric extends TeamDefensiveMetric, Record<string, any> {}

export interface TeamOffenseMetric extends TeamOffensiveMetric, Record<string, any> {}

export interface DefensiveLineMetric extends PlayerMetric {
  season: string
  pass_rush_grade_over_expectation: string
  percentile_pass_rush_grade_over_expectation: string
  franchise_id: string
}

export interface OffensiveLineMetric extends PlayerMetric {
  season: string
  pass_block_grade_over_expectation: string
  percentile_pass_block_grade_over_expectation: string
  franchise_id: string
}

export type MetricColumn =
  | PlayerMetric
  | InjuredPlayerImpactMetrics
  | HealthyPlayerMetrics
  | TeamDefensiveMetric
  | TeamOffensiveMetric

export type MetricColumnWithMetrics =
  | InjuredPlayerImpactMetrics
  | PlayersMetric
  | HealthyPlayerMetrics
  | TeamDefenseMetric
  | TeamOffenseMetric
