import { HostedSites, League } from '@pff-consumer/schema'

export const checkEspnOrYahooLeagueSync = (leagues: League[] | undefined, useYahooExtensionFeatureFlag: boolean) => {
  if (!leagues) {
    return false
  }

  const isEspnLeagueSynced = leagues.some((league) => league.hosted_site === HostedSites.ESPN)
  const isYahooLeagueSynced = leagues.some((league) => league.hosted_site === HostedSites.YAHOO)

  return isEspnLeagueSynced || (isYahooLeagueSynced && useYahooExtensionFeatureFlag)
}
