import { colors } from '@pff-consumer/core-ui/src/lib/colors'

export const getClockBackgroundColor = (isDraftAboutToStart: boolean, hasDraftStarted: boolean) => {
  if (isDraftAboutToStart) {
    return colors.warningBackground
  }
  if (hasDraftStarted) {
    return 'none'
  }
  return colors.activeStateBackground
}
