import { PerformanceMetricStat } from '@pff-consumer/schema'

export const PlayerPositionalStatsMap: Record<string, PerformanceMetricStat[]> = {
  QB: [
    PerformanceMetricStat.PASS_YD,
    PerformanceMetricStat.RUSH_YD,
    PerformanceMetricStat.ANY_TIME_TD,
    PerformanceMetricStat.PASS_TD,
    PerformanceMetricStat.PASS_ATT,
    PerformanceMetricStat.PASS_INT,
  ],
  WR: [
    PerformanceMetricStat.RECV_YD,
    PerformanceMetricStat.RECV_REC,
    PerformanceMetricStat.ANY_TIME_TD,
    PerformanceMetricStat.LONGEST_RECEPTION,
  ],
  TE: [
    PerformanceMetricStat.RECV_YD,
    PerformanceMetricStat.RECV_REC,
    PerformanceMetricStat.ANY_TIME_TD,
    PerformanceMetricStat.LONGEST_RECEPTION,
  ],
  RB: [
    PerformanceMetricStat.RUSH_YD,
    PerformanceMetricStat.RECV_YD,
    PerformanceMetricStat.RECV_REC,
    PerformanceMetricStat.LONGEST_RECEPTION,
    PerformanceMetricStat.ANY_TIME_TD,
  ],
  HB: [
    PerformanceMetricStat.RUSH_YD,
    PerformanceMetricStat.RECV_YD,
    PerformanceMetricStat.RECV_REC,
    PerformanceMetricStat.LONGEST_RECEPTION,
    PerformanceMetricStat.ANY_TIME_TD,
  ],
  FB: [
    PerformanceMetricStat.RUSH_YD,
    PerformanceMetricStat.RECV_YD,
    PerformanceMetricStat.RECV_REC,
    PerformanceMetricStat.LONGEST_RECEPTION,
    PerformanceMetricStat.ANY_TIME_TD,
  ],
}

export const PerformanceMetricToStatKey: Record<string, string> = {
  [PerformanceMetricStat.PASS_YD]: 'Passing Yds',
  [PerformanceMetricStat.RUSH_YD]: 'Rushing Yds',
  [PerformanceMetricStat.ANY_TIME_TD]: 'Touchdowns',
  [PerformanceMetricStat.PASS_TD]: 'Passing TDs',
  [PerformanceMetricStat.PASS_ATT]: 'Pass Attempts',
  [PerformanceMetricStat.PASS_INT]: 'Interceptions',
  [PerformanceMetricStat.RECV_YD]: 'Receiving Yds',
  [PerformanceMetricStat.RECV_REC]: 'Receptions',
  [PerformanceMetricStat.LONGEST_RECEPTION]: 'Long Reception',
  [PerformanceMetricStat.RECV_TD]: 'Receiving Touchdowns',
}

export const PerformanceMetricStatKeyToUnit: Record<string, string> = {
  pass_yd: 'YDS',
  rush_yd: 'YDS',
  any_time_td: 'TDS',
  pass_td: 'TDS',
  pass_att: 'ATT',
  pass_int: 'INT',
  recv_yd: 'YDS',
  recv_rec: 'REC',
  longest_reception: 'YDS',
  recv_td: 'TDS',
}

export const PerformanceMetricScales: Record<string, number[]> = {
  400: [400, 300, 200, 100, 0],
  100: [100, 80, 60, 40, 20, 0],
  60: [60, 50, 40, 30, 20, 10, 0],
  50: [50, 40, 30, 20, 10, 0],
  30: [30, 25, 20, 15, 10, 5, 0],
  20: [20, 15, 10, 5, 0],
  10: [10, 8, 6, 4, 2, 0],
  5: [5, 4, 3, 2, 1, 0],
}

export const PerformancePositionMetricToScale: Record<string, Record<string, number>> = {
  QB: {
    [PerformanceMetricStat.PASS_YD]: 400,
    [PerformanceMetricStat.RUSH_YD]: 50,
    [PerformanceMetricStat.ANY_TIME_TD]: 10,
    [PerformanceMetricStat.PASS_TD]: 10,
    [PerformanceMetricStat.PASS_ATT]: 50,
    [PerformanceMetricStat.PASS_INT]: 5,
  },
  WR: {
    [PerformanceMetricStat.RECV_YD]: 100,
    [PerformanceMetricStat.RECV_REC]: 10,
    [PerformanceMetricStat.ANY_TIME_TD]: 5,
    [PerformanceMetricStat.LONGEST_RECEPTION]: 30,
  },
  TE: {
    [PerformanceMetricStat.RECV_YD]: 100,
    [PerformanceMetricStat.RECV_REC]: 10,
    [PerformanceMetricStat.ANY_TIME_TD]: 5,
    [PerformanceMetricStat.LONGEST_RECEPTION]: 20,
  },
  HB: {
    [PerformanceMetricStat.RUSH_YD]: 100,
    [PerformanceMetricStat.RECV_YD]: 60,
    [PerformanceMetricStat.RECV_REC]: 10,
    [PerformanceMetricStat.LONGEST_RECEPTION]: 50,
    [PerformanceMetricStat.ANY_TIME_TD]: 5,
    [PerformanceMetricStat.RECV_TD]: 5,
  },
  FB: {
    [PerformanceMetricStat.RUSH_YD]: 15,
    [PerformanceMetricStat.RECV_YD]: 40,
    [PerformanceMetricStat.RECV_REC]: 5,
    [PerformanceMetricStat.LONGEST_RECEPTION]: 20,
    [PerformanceMetricStat.ANY_TIME_TD]: 5,
    [PerformanceMetricStat.RECV_TD]: 5,
  },
}
