import { Platform } from 'react-native'

const uniqueDeviceIdModule = Platform.select({
  // eslint-disable-next-line import/extensions, global-require
  ios: () => require('./native/index.ts'),
  // eslint-disable-next-line import/extensions, global-require
  android: () => require('./native/index.ts'),
  default: () => ({
    useUniqueDeviceId: () => ({
      initUniqueDeviceId: () => Promise.resolve(null),
      uniqueDeviceId: null,
    }),
    getUniqueDeviceIdFromStorage: () => '',
  }),
})()

export const { useUniqueDeviceId } = uniqueDeviceIdModule
export const { getUniqueDeviceIdFromStorage } = uniqueDeviceIdModule
