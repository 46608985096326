import { createUseStyles as jssCreateUseStyles, Styles } from 'react-jss'
import { WebUiTheme } from './theme'

type UseStylesFunction<StylesT, PropsT> = (props: PropsT) => Record<keyof StylesT & string, string>

export function createUseStyles<StylesT, PropsT = void>(
  styles: Styles<keyof StylesT, PropsT, WebUiTheme> | ((theme: WebUiTheme) => Styles<keyof StylesT, PropsT>)
): UseStylesFunction<StylesT, PropsT> {
  const useStyles = jssCreateUseStyles<keyof StylesT & string, PropsT, WebUiTheme>(styles)
  // @ts-expect-error
  return useStyles
}
