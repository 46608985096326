export interface LandingPageButton {
  text: string
  link: string
}

export enum LandingPageFeaturePlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface LandingPageFeature {
  title: string
  description: string
  button: LandingPageButton
}

export type LandingPageFeaturePlacementDictionary = Record<LandingPageFeaturePlacement, LandingPageFeature[]>
export interface LandingPageContent {
  pageTopic: string
  title: string | null
  description: string | null
  button?: LandingPageButton
  features: LandingPageFeaturePlacementDictionary
}
