import { launchDarklyClient } from '@pff-consumer/feature-flags'
import { includes } from 'lodash'
import { mapAnalyticsEventPropertiesForHeap } from '../shared/map-heap-events'

import type { AnalyticsEventProperties, EventName } from '../shared/analytics-types'

const launchDarklyEvents: EventName[] = ['Subscribe Flow Conversion']

const checkIsLaunchDarklyEvent = (eventName: EventName): boolean => includes(launchDarklyEvents, eventName)
// @ts-expect-error
const hasLaunchDarklyClient = launchDarklyClient != null && 'track' in launchDarklyClient

export const sendLaunchDarklyAnalyticsEvent = (
  eventName: EventName,
  eventProperties: Partial<AnalyticsEventProperties>
): void => {
  if (!hasLaunchDarklyClient) {
    // eslint-disable-next-line no-console
    console.log('Launch Darkly Client does not exist')
    return
  }

  // Filter to only events we care about
  if (!checkIsLaunchDarklyEvent(eventName)) {
    // eslint-disable-next-line no-console
    console.log('Event is not a LaunchDarkly event', eventName)
    return
  }

  try {
    // @ts-expect-error
    launchDarklyClient?.track(eventName, mapAnalyticsEventPropertiesForHeap(eventProperties))
    // eslint-disable-next-line no-console
    console.log('sendLaunchDarklyAnalyticsEvent', eventName, eventProperties)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error sendLaunchDarklyAnalyticsEvent', e)
  }
}
