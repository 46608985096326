import {
  FantasyRanker,
  FantasySort,
  FantasyExperiences,
  FantasyPlayerHeadShotLocations,
  PlayerStatsModalLocation,
  IdpRankingsExperiences,
} from './fantasy'

type UserAnalyticsData = {
  pffUserId?: string | null
  subscription_type: string | null
  subscription_status: string | null
  location_state: string | null
  location_country: string | null
  device: string
  tool?: string
  skipNextJsAuth?: boolean
}

type StartSitPlayerSelectedData = {
  isPlayer1: boolean
  player_name: string
}

type StartSitPlayerRemovedData = {
  isPlayer1: boolean
  player_name: string
}

type StartSitPlayerSwappedData = {
  isPlayer1: boolean
  player_name: string
}

type StartSitExecutedData = {
  player_one_name: string
  player_two_name: string
}
type FilterAppliedData = {
  filter_position?: string[] | string
  filter_tags?: string[]
  sort_ranker?: string[] | string
  sort_scoring?: string
  sort_league_type?: string
  location: 'fantasy' | 'filter screen' | string
  experience?: FantasyExperience
  tool?: string
}

type PlayerAnalysisData = {
  player_name: string
  player_rank?: number | string
  player_adp_rank?: number
  player_projection_rank?: number
  player_tags: string
  fantasy_expert_ranker: string
  analysis_type: 'fantasy player' | string
  location: 'fantasy' | string
}

type PlayerHeadshotData = {
  player_name: string | undefined
  location: 'fantasy' | string
  experience: FantasyExperience | undefined
  player_headshot_location: FantasyPlayerHeadShotLocation | undefined
}

type PlayerSuggestionInteraction = {
  round: number
  player_name: string
  playerPosition: string
  pick_score: number
  skipNextJsAuth?: boolean
  tool?: string
}

export type DraftAnalyticsInfo = {
  platformName?: string
  device?: string
  elementLocation?: string
  elementName?: string
  tool?: string
  grade?: string
  numberOfBenchSpots: number
  numberOfTeams: number
  yourDraftPickNumber: number
  numberOfDraftRounds: number
  leagueScoringType: string
  draftType: string
  draftStrategy: string
  startingPositionCountQB: number
  startingPositionCountRB: number
  startingPositionCountWR: number
  startingPositionCountTE: number
  startingPositionCountFlex: number
  startingPositionCountSuperFlex: number
  startingPositionCountDST: number
  startingPositionCountK: number
  opponentStrategyFavoriteTeams?: boolean
  opponentStrategyAvoidedTeams?: boolean
  hasKeepers: boolean
}

export type LiveDraftAnalyticsInfo = DraftAnalyticsInfo & {
  draftStatus: string
}

export type PaywallDraftAnalyticsInfo = DraftAnalyticsInfo & {
  location: string
}

export type FantasyCtaInfo = DraftAnalyticsInfo & {
  ctaName: string
  ctaLocation: FMDSPaywallLocation
}

type FantasyDraftResultErrorAnalyticsInfo = DraftAnalyticsInfo & {
  experience: FantasyExperience
  error: string
}

export type LDARoundStartedInfo = {
  platformName: string
  draft_round: number
  numberOfDraftRounds: number
  roundCompletionRate: number
}

export type LeagueResyncModalViewedInfo = {
  skipNextJsAuth: boolean
}

export type LDAPlayerDraftedInfo = {
  experience: FantasyExperience
  platformName: string
  player_rank: number
  drafted_player_pick_grade?: number
  availabilityPercent?: number
  draft_round: number
  numberOfDraftRounds: number
  playerPosition: string
  player_suggested: number | 'null'
  player_name: string
}

type FantasyOnboardingScreenViewedInfo = {
  experience: FantasyExperience
  platformName: string
}

type ResultPageActions = {
  device?: string
  elementLocation: string
  elementName: string
  tool?: string
  completedDraftGrade: string
}

type PlayerDrafted = {
  draft_round: number
  player_suggested: boolean
  selected_player_number?: number
  drafted_player_name: string
  drafted_player_position: string
  drafted_player_pick_grade: number
}

type SuggestedPlayer = {
  draft_round: number
  suggested_player_number: number
  suggested_player_name: string
  suggested_player_position: string
  suggested_player_pick_grade: number
}

type CurrentRoundPlayer = SuggestedPlayer & {
  pick_status: 'on clock' | 'off clock'
}

type LDAError = {
  error: string
  leagueId: string
  provider: string
}

export type FantasyExperience =
  | FantasyExperiences.FANTASY_WEEKLY_RANKINGS
  | FantasyExperiences.FANTASY_DRAFT_RANKINGS
  | FantasyExperiences.FANTASY_START_SIT
  | FantasyExperiences.FANTASY_MATCHUPS
  | FantasyExperiences.FANTASY_WAIVER_TARGETS
  | FantasyExperiences.LDA
  | FantasyExperiences.FMDS

export type FantasyPlayerHeadShotLocation =
  | FantasyPlayerHeadShotLocations.PLAYER_COMPARE_ORIGINAL_PLAYER
  | FantasyPlayerHeadShotLocations.PLAYER_COMPARE_AVAILABLE_PLAYER
  | FantasyPlayerHeadShotLocations.PLAYER_COMPARE_COMPARE_ROW
  | FantasyPlayerHeadShotLocations.PLAYER_COMPARE_SELECTED_PLAYER
  | FantasyPlayerHeadShotLocations.RANK_CARD
  | FantasyPlayerHeadShotLocations.START_SIT_CALCULATOR_PANE
  | FantasyPlayerHeadShotLocations.START_SIT_RESULT
  | FantasyPlayerHeadShotLocations.START_SIT_PLAYERS_LIST
  | FantasyPlayerHeadShotLocations.ANALYSTS_SELECTION
  | FantasyPlayerHeadShotLocations.PROJECTIONS

type PlayerSortingData = {
  sort_field:
    | FantasySort.SORT_BY_RANK
    | FantasySort.SORT_BY_ADP
    | FantasySort.SORT_BY_PROJ
    | FantasySort.SORT_BY_PICK_GRADE
    | FantasySort.SORT_BY_AVAILABILITY
    | FantasySort.SORT_BY_AUCTION_VALUE
    | FantasySort.SORT_BY_POS_RANK
    | FantasySort.SORT_BY_MATCHUP_SCORE
  sort_direction: 'asc' | 'desc' | true | false
  location: 'fantasy' | string
  experience: FantasyExperience
}

type DraftPickData = {
  draft_pick: string
}

type DraftAnalystData = {
  analyst: FantasyRanker
}

type RankCardExpandedData = {
  player_name: string
}

type UnknownPlayerDrafted = {
  drafted_player_id: string
  drafted_player_provider: string
  drafted_player_league_id: number
}

type PlayerModalOpened = {
  player_modal_location: PlayerStatsModalLocation
  player_modal_player_id: number
  player_modal_player_name: string
}

type ModalViewInfo = {
  modal_type: string
  modal_location: string
  chrome_extension_version?: number
}

export interface LiveDraftYahooOAuthError {
  error_message: string
  league_id: number
  pff_user_id: string
}

export interface LiveDraftYahooOauthRedirect {
  leagueId: number
}

export interface MyTeamDashboardYahooOauthRedirect {
  leagueId: number
}

export interface LiveDraftWSDisconnected {
  error_code: number
  error_message: string
  pff_user_id: string
}

type SearchStartedInfo = {
  experience?: FantasyExperiences
  search_term?: string
}

type RankingsPaywallInfo = {
  experience: FantasyExperiences | IdpRankingsExperiences
}

export const MISSING_USER_ANALYTICS_DATA: UserAnalyticsData = {
  subscription_type: null,
  subscription_status: null,
  location_state: null,
  location_country: null,
  device: '',
}

export type FMDSPaywallLocation = 'FMDS Next Round Availability Locked' | 'FMDS Cheat Sheet Upsell'

export const FANTASY_ANALYTICS_EVENTS = {
  FILTER_APPLIED: 'Filter Applied',
  PLAYERS_SORTED: 'Players Sorted',
  SEARCH_STARTED: 'Search Started',
  RANK_CARD_EXPANDED: 'Rank Card Expanded',
  START_SIT_EXECUTED: 'Start Sit Analysis Executed',
  START_SIT_TAB_SELECTED: 'Start Sit Tab Selected',
  START_SIT_PLAYER_SELECTED: 'Start Sit Player Selected',
  START_SIT_PLAYER_REMOVED: 'Start Sit Player Removed',
  START_SIT_PLAYER_SWAPPED: 'Start Sit Player Swapped',
  MY_TEAM_SELECTED: 'My Team Selected',
  WEEKLY_RANKINGS_SELECTED: 'Weekly Rankings Selected',
  MATCHUPS_SELECTED: 'Matchups Selected',
  WAIVER_TARGETS_SELECTED: 'Waiver Targets Selected',
  PLAYER_HEADSHOT_SELECTED: 'Player Headshot Selected',
  PLAYER_SUGGESTION_INTERACTION: 'Player Suggestion Interaction',
  SYNCED_LEAGUE_SELECTED: 'Synced League Selected',
  MANUAL_LEAGUE_SELECTED: 'Manual Settings Selected',
  COPY_IMAGE_SELECTED: 'Copy Image Selected',
  DOWNLOAD_IMAGE_SELECTED: 'Download Image Selected',
  START_NEW_DRAFT_SELECTED: 'Start New Draft Selected',
  CURRENT_ROUND_PLAYER_SELECTED: 'Current Round Player Selected',
  PLAYER_DRAFTED: 'Player Drafted',
  UNKNOWN_PLAYER_DRAFTED: 'Unknown Player Drafted',
  PLAYER_MODAL_OPENED: 'Player Modal Opened',
  LDA_ERROR: 'LDA Error',
  MOCK_DRAFT_STARTED: 'Mock Draft Started',
  MOCK_DRAFT_COMPLETED: 'Mock Draft Completed',
  LIVE_DRAFT_STARTED: 'Live Draft Started',
  LIVE_DRAFT_COMPLETED: 'Live Draft Completed',
  FANTASY_DRAFT_RESULT_ERROR: 'Fantasy Draft Result Error',
  LDA_ROUND_STARTED: 'Live Draft Assistant Round Started',
  FMDS_PAYWALL_VIEWED: 'FMDS Paywall Viewed',
  FANTASY_ONBOARDING_SCREEN_VIEWED: 'Fantasy Onboarding Screen Viewed',
  FANTASY_UPDATE_EXTENSION_MODAL_VIEWED: 'Fantasy Update Extension Modal Viewed',
  LEAGUE_RESYNC_MODAL_VIEWED: 'Fantasy League Re-Sync Modal Viewed',
  LIVE_DRAFT_ERROR: 'Live Draft Error',
  LDA_YAHOO_OAUTH_REDIRECT_REQUIRED: 'LDA Yahoo OAuth Redirect Required',
  MTD_YAHOO_OAUTH_REDIRECT_REQUIRED: 'My Team Dashboard Yahoo OAuth Redirect Required',
  LIVE_DRAFT_WS_DISCONNECTED: 'Live Draft WS Disconnected',
  LIVE_DRAFT_WS_MESSAGE: 'Live Draft WS Message',
  PAYWALL_VIEWED: 'Paywall Viewed',
} as const

export type FantasyAnalyticsEvents = (typeof FANTASY_ANALYTICS_EVENTS)[keyof typeof FANTASY_ANALYTICS_EVENTS]

export type FantasyAnalyticsEventProperties = FilterAppliedData &
  PlayerSortingData &
  PlayerAnalysisData &
  DraftPickData &
  IdpRankingsExperiences &
  DraftAnalystData &
  StartSitPlayerSelectedData &
  StartSitPlayerSwappedData &
  StartSitExecutedData &
  StartSitPlayerRemovedData &
  RankCardExpandedData &
  PlayerHeadshotData &
  PlayerSuggestionInteraction &
  DraftAnalyticsInfo &
  LiveDraftAnalyticsInfo &
  ResultPageActions &
  UnknownPlayerDrafted &
  PlayerModalOpened &
  PlayerDrafted &
  FantasyDraftResultErrorAnalyticsInfo &
  LDAPlayerDraftedInfo &
  LDARoundStartedInfo &
  PaywallDraftAnalyticsInfo &
  FantasyOnboardingScreenViewedInfo &
  ModalViewInfo &
  LiveDraftYahooOAuthError &
  LiveDraftYahooOauthRedirect &
  MyTeamDashboardYahooOauthRedirect &
  LiveDraftWSDisconnected &
  SearchStartedInfo

export type FantasyAnalyticsEventArgs =
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.FILTER_APPLIED, eventProperties: UserAnalyticsData & FilterAppliedData]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.PLAYERS_SORTED, eventProperties: UserAnalyticsData & PlayerSortingData]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.SEARCH_STARTED, eventProperties: UserAnalyticsData & SearchStartedInfo]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.START_SIT_EXECUTED,
      eventProperties: UserAnalyticsData & StartSitExecutedData
    ]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.START_SIT_TAB_SELECTED, eventProperties: UserAnalyticsData]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.START_SIT_PLAYER_SELECTED,
      eventProperties: UserAnalyticsData & StartSitPlayerSelectedData
    ]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.START_SIT_PLAYER_REMOVED,
      eventProperties: UserAnalyticsData & StartSitPlayerRemovedData
    ]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.START_SIT_PLAYER_SWAPPED,
      eventProperties: UserAnalyticsData & StartSitPlayerSwappedData
    ]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.RANK_CARD_EXPANDED,
      eventProperties: UserAnalyticsData & RankCardExpandedData
    ]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.WAIVER_TARGETS_SELECTED, eventProperties: UserAnalyticsData]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.WEEKLY_RANKINGS_SELECTED, eventProperties: UserAnalyticsData]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.MY_TEAM_SELECTED, eventProperties: UserAnalyticsData]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.MATCHUPS_SELECTED, eventProperties: UserAnalyticsData]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.PLAYER_HEADSHOT_SELECTED,
      eventProperties: UserAnalyticsData & PlayerHeadshotData
    ]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.PLAYER_SUGGESTION_INTERACTION,
      eventProperties: PlayerSuggestionInteraction
    ]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.MOCK_DRAFT_STARTED, eventProperties: DraftAnalyticsInfo]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.MOCK_DRAFT_COMPLETED, eventProperties: DraftAnalyticsInfo]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.LIVE_DRAFT_STARTED, eventProperties: LiveDraftAnalyticsInfo]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.LIVE_DRAFT_COMPLETED, eventProperties: LiveDraftAnalyticsInfo]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.COPY_IMAGE_SELECTED, eventProperties: ResultPageActions]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.DOWNLOAD_IMAGE_SELECTED, eventProperties: ResultPageActions]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.START_NEW_DRAFT_SELECTED, eventProperties: ResultPageActions]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.CURRENT_ROUND_PLAYER_SELECTED, eventProperties: CurrentRoundPlayer]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.PLAYER_DRAFTED, eventProperties: PlayerDrafted | LDAPlayerDraftedInfo]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.UNKNOWN_PLAYER_DRAFTED, eventProperties: UnknownPlayerDrafted]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.LDA_ERROR, eventProperties: LDAError]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.PLAYER_MODAL_OPENED, eventProperties: PlayerModalOpened]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.FANTASY_DRAFT_RESULT_ERROR,
      eventProperties: FantasyDraftResultErrorAnalyticsInfo
    ]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.LDA_ROUND_STARTED, eventProperties: LDARoundStartedInfo]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.FMDS_PAYWALL_VIEWED, eventProperties: PaywallDraftAnalyticsInfo]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.FANTASY_ONBOARDING_SCREEN_VIEWED,
      eventProperties: FantasyOnboardingScreenViewedInfo
    ]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.FANTASY_UPDATE_EXTENSION_MODAL_VIEWED, eventProperties: ModalViewInfo]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.LEAGUE_RESYNC_MODAL_VIEWED,
      eventProperties: LeagueResyncModalViewedInfo
    ]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.LIVE_DRAFT_ERROR, eventProperties: LiveDraftYahooOAuthError]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.LDA_YAHOO_OAUTH_REDIRECT_REQUIRED,
      eventProperties: LiveDraftYahooOauthRedirect
    ]
  | [
      eventName: typeof FANTASY_ANALYTICS_EVENTS.MTD_YAHOO_OAUTH_REDIRECT_REQUIRED,
      eventProperties: MyTeamDashboardYahooOauthRedirect
    ]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.LIVE_DRAFT_WS_DISCONNECTED, eventProperties: LiveDraftWSDisconnected]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.LIVE_DRAFT_WS_MESSAGE, eventProperties: {}]
  | [eventName: typeof FANTASY_ANALYTICS_EVENTS.PAYWALL_VIEWED, eventProperties: RankingsPaywallInfo]
