export const DRAFT_BREAKPOINT_WIDTH = {
  TABBED_VIEW: 1200,
  FILTER_ROW_SMALL: 800,
  RANK_CARD_SMALL: 600,
  RANK_CARD_EXPANDED: 726,
  LOWEST_WIDTH_SUPPORTED: 375,
  SMALL_SCREEN_MEDIAN_WIDTH: 440,
  SMALL_SCREEN_MAX_WIDTH: 700,
  MEDIUM_SCREEN_MAX_WIDTH: 779,
  LARGE_SCREEN_MIN_WIDTH: 1340,
  RESULT_SCREEN_MAX_WIDTH: 740,
  SMALL_SCREEN_BACKGROUND_HEIGHT: 108,
  LARGE_SCREEN_BACKGROUND_HEIGHT: 88,
  SMALL_SCREEN_GRADE_TEXT_MAX_WIDTH: 197,
  OVERALL_GRADE_CONTAINER_MAX_HEIGHT: 48,
  OVERALL_GRADE_CONTAINER_MIN_WIDTH: 76,
  GRADE_CONTAINER_MIN_WIDTH: 53,
  CHEATSHEET_ONE_COLUMN_VIEW: 480,
  RANKINGS_FILTER_ROW_MOBILE_VIEW_MAX_WIDTH: 1024,
  NEXT_ROUND_SUGGESTIONS_SINGLE_ROW_WIDTH: 1260,
  NEXT_ROUND_SUGGESTIONS_FOUR_COLUMN: 1550,
  NEXT_ROUND_SUGGESTIONS_THREE_COLUMN: 900,
  MDS_UPSELL_MODAL_LG_MIN_WIDTH: 770,
  RESULTS_CARD_SMALL_MAX_WIDTH: 640,
  MIN_DESKTOP_WIDTH_PLUS_PADDING: 932,
  MDS_SMALL_MAX_WIDTH: 640,
  MATCHUP_CARD: 798,
}
