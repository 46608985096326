import { ORDERED_FANTASY_POSITIONS, type MixedRosterItem } from '@pff-consumer/schema'

const orderedFantasyPositions = ORDERED_FANTASY_POSITIONS
const LARGE_POSITION_ORDER = 9999

export const getSortedPlayersByPosition = (mixedPlayers: MixedRosterItem[], type: string): MixedRosterItem[] => {
  const players = [...mixedPlayers].sort((a, b) => {
    // For empty roster positions, position can be null
    const posA = (type === 'starters' ? a.fantasyPosition : a.position) || ''
    const posB = type === 'starters' ? b.fantasyPosition : b.position || ''
    // We will push the empty bench positions to be at the last
    const positionA = posA === '' ? LARGE_POSITION_ORDER : orderedFantasyPositions.indexOf(posA)
    const positionB = posB === '' ? LARGE_POSITION_ORDER : orderedFantasyPositions.indexOf(posB)
    return positionA - positionB
  })
  return players
}
