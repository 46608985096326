/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/dot-notation */

import { envConfig } from '../config'

export function getBaseUrl() {
  const { PROXY_ENABLED, CONSUMER_API_URL, CONSUMER_API_MIDDLEWARE_URL } = envConfig
  if (PROXY_ENABLED) {
    const NODE_ENV = process.env['NODE_ENV']
    return NODE_ENV === 'development' ? `${CONSUMER_API_MIDDLEWARE_URL}/` : '/'
  }
  return CONSUMER_API_URL
}
