import type { User } from '@pff-consumer/schema'
import { mapAnalyticsEventPropertiesForHeap } from '../shared/map-heap-events'

import type { AnalyticsEventProperties, EventName } from '../shared/analytics-types'
import { HeapEventProperties } from '../shared/heap-types'

export const HEAP_START_INTERVAL = 500 // 500ms
export const HEAP_START_TIMEOUT = 30000 // 30s
declare global {
  interface Window {
    heap: {
      load: (...args: any[]) => any
      track: (...args: any[]) => any
      addUserProperties: (...args: any[]) => any
      resetIdentity: (...args: any[]) => any
      identify: (...args: any[]) => any
      loaded: boolean
    }
    sessionData?: {
      grootUid?: string
      isPremiumSubscriber?: boolean
    }
  }
}
export const initializeHeap = () => {
  if (process.env.NX_HEAP_APP_ID) {
    if (window.heap) {
      // heap is already loaded when we embed our apps into pff.com
      if (!window.heap.loaded) {
        window.heap.load(process.env.NX_HEAP_APP_ID)
      } else {
        // eslint-disable-next-line no-console
        console.debug('window.heap is already loaded')
      }
    } else {
      // eslint-disable-next-line no-console
      console.debug('window.heap does not exist, please make sure snippet is added to <head>')
    }
  } else {
    // eslint-disable-next-line no-console
    console.debug('NX_HEAP_APP_ID is missing from env variables.')
  }
}

export const addUserToHeap = (user: User) => {
  window.heap.addUserProperties(user)
  window.heap.identify(user.uid)
  // eslint-disable-next-line no-console
  // console.debug('Adding user', user)
}

export const removeUserFromHeap = () => {
  window.heap.resetIdentity()
}
/**
 * The Provider does not embed heap to the page or even initialize the heap sdk
 * it handles taking events before and after heap is loaded and sending all of the
 * events once heap is ready.
 *
 * This is only exported for testing purposes.
 */
export class HeapProvider {
  private startInterval: NodeJS.Timer | undefined

  private startTimeout: NodeJS.Timer | undefined

  private isReady = false

  private isStarted = false

  private queue: [EventName, Partial<HeapEventProperties>][] = []

  // Handles all of the events in the queue
  private processQueue = () => {
    this.queue.forEach(([eventName, mappedProperties]) => {
      window.heap.track(eventName, mappedProperties)
    })

    this.queue.length = 0
  }

  private attemptReady = () => {
    // Keep checking if heap is ready
    if (window?.heap?.track != null) {
      this.isReady = true
      this.processQueue()
      clearInterval(this.startInterval as unknown as number) // TS incorrectly thinks setInterval returns timer and not intervalId (number)
      clearTimeout(this.startTimeout as unknown as number)
    }
  }

  // Sends the event if heap is ready or queues it up
  sendEvent = (eventName: EventName, eventProperties: Partial<AnalyticsEventProperties>) => {
    const mappedProperties = mapAnalyticsEventPropertiesForHeap(eventProperties)
    if (this.isReady) {
      window.heap.track(eventName, mappedProperties)
    } else {
      this.queue.push([eventName, mappedProperties])
    }
  }

  // Checks if heap is ready and then will clear the queue
  start = () => {
    if (this.isReady || this.isStarted) return

    this.isStarted = true

    this.startInterval = setInterval(() => {
      this.attemptReady()
    }, HEAP_START_INTERVAL)

    // If heap fails to load after a certain amount of time
    this.startTimeout = setTimeout(() => {
      clearInterval(this.startInterval as unknown as number) // TS incorrectly thinks setInterval returns timer and not intervalId (number)
      // eslint-disable-next-line no-console
      console.error(`Heap failed to load after ${HEAP_START_TIMEOUT / 1000} seconds`)
    }, HEAP_START_TIMEOUT)

    this.attemptReady()
  }
}

const heapProvider = new HeapProvider()

export const sendHeapAnalyticsEvent = (eventName: EventName, eventProperties: Partial<AnalyticsEventProperties>) => {
  heapProvider.start()
  // eslint-disable-next-line no-console
  console.debug('sendHeapAnalyticsEvent', eventName, eventProperties)
  heapProvider.sendEvent(eventName, eventProperties)
}
