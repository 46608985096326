import { colors } from '@pff-consumer/core-ui'

export const getMatchupGradientColors = (isNeutralGradientBar: boolean = false, gradientBarColors: string[] = []) => {
  const normalGradientColors = [
    colors.matchupBarFirstGradientColor,
    colors.matchupBarSecondGradientColor,
    colors.matchupBarThirdGradientColor,
    colors.matchupBarFourthGradientColor,
    colors.matchupBarFifthGradientColor,
  ]

  const neutralGradientColors = [
    colors.matchupBarNeutralFirstGradientColor,
    colors.matchupBarNeutralSecondGradientColor,
    colors.matchupBarNeutralThirdGradientColor,
    colors.matchupBarNeutralFourthGradientColor,
    colors.matchupBarNeutralFifthGradientColor,
  ]

  if (gradientBarColors.length > 0) {
    return gradientBarColors
  }

  return isNeutralGradientBar ? neutralGradientColors : normalGradientColors
}
