import { DraftedPlayerInfo, DraftersInfo, DraftPickStatus, type LiveDraftPick } from '@pff-consumer/schema'
import type { Keeper } from '@pff-consumer/schema/fantasy-live-draft/keeper'

/**
 * We have multiple data inputs that tell us who has been picked either via user/CPU or via keepers.
 *
 * For user/CPU that is stored as draftRoomPicks. Pre supporting keepers we assumed the draft pick based on the index
 * of the array. Now that we support keepers we can no longer make that assumption. Therefore we need to add all keepers
 * to the draft picks list, and then add any user/CPU picks at the next available slot.
 * We should filter the keepers from the user user/CPU picks - as providers give the picks with the Keepers
 */
export const transformDraftPicks = (
  draftPicks: DraftersInfo[],
  draftRoomPicks: string[],
  keepers: Keeper[],
  playerInfoMap: Record<string, Omit<DraftedPlayerInfo, 'status'>>,
  liveDraftMode: boolean,
  liveDraftPicks: { [key: number]: LiveDraftPick }
) => {
  // Clone original draft picks to maintain immutability
  const transformedPicks = [...draftPicks]

  // Add keepers first
  for (let i = 0; i < keepers.length; i += 1) {
    const keeperPlayerId = keepers[i].playerId
    const indexToInsertInto = keepers[i].pickNumber - 1
    const playerDetails = playerInfoMap[keeperPlayerId]

    transformedPicks[indexToInsertInto] = {
      ...transformedPicks[indexToInsertInto],
      ...playerDetails,
      ...keepers[i],
      status: DraftPickStatus.KEEPER,
    }
  }

  // Filter out keepers from the draft picks
  const draftRoomPicksWithoutKeepers = draftRoomPicks.filter((id) => !keepers.some((player) => player.playerId === id))

  // Add draftPicks
  let draftPickPickIndex = 0
  let loopedPastInProgressPick = false
  for (let j = 0; j < draftPicks.length; j += 1) {
    // Slot already taken
    if (transformedPicks[j].status !== DraftPickStatus.PENDING) {
      // eslint-disable-next-line no-continue
      continue
    }

    const pickNumber: number = j + 1
    const pickInfo = draftPicks[j]
    const currentPickPlayerId = draftRoomPicksWithoutKeepers[draftPickPickIndex]

    // Handle in progress pick
    if (!currentPickPlayerId && !loopedPastInProgressPick) {
      transformedPicks[j] = {
        ...pickInfo,
        status: DraftPickStatus.IN_PROGRESS,
      }
      loopedPastInProgressPick = true
      // eslint-disable-next-line no-continue
      continue
    }

    // Handle past picks
    let playerDetails = playerInfoMap[currentPickPlayerId]

    // Handle when user drafts a player in LDA mode that we have no rankings for
    if (!playerDetails && liveDraftMode) {
      const missingPlayer = liveDraftPicks[pickNumber]
      const [firstName, ...lastNameParts] = missingPlayer?.draftedPlayer?.split(' ') || ''
      const lastName = lastNameParts.join(' ')

      playerDetails = {
        // For Yahoo! we wont be getting the player name. Both drafterPlayer and draftedPlayerId fields will have the
        // Yahoo! player ID only. So instead of displaying the Yahoo! player ID for players not in our ranking,
        // we rather show 'Unknown Player'
        firstName: missingPlayer?.draftedPlayer === missingPlayer?.draftedPlayerId ? 'Unknown Player' : firstName,
        lastName,
        id: Number(missingPlayer?.draftedPlayerId),
        team: '',
        teamName: '',
        teamCity: '',
        position: '',
      }
    }

    transformedPicks[j] = {
      ...playerDetails,
      ...pickInfo,
      status: currentPickPlayerId ? DraftPickStatus.PICKED : DraftPickStatus.PENDING,
    }

    // We cannot use the for loop index because the draftPicks array only has user + CPU picks and not keepers
    draftPickPickIndex += 1
  }

  return transformedPicks
}
