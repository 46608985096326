import {
  BreakpointSize,
  getBreakpointMaxOrValue,
  getBreakpointMinOrValue,
  getBreakpointRange,
} from '@pff-consumer/core-ui'
import type { ThemeBreakpoint, BreakpointOrientation } from '@pff-consumer/core-ui'
import { Styles } from 'react-jss'

const orientationString = (orientation: BreakpointOrientation | undefined): String => {
  return orientation ? ` and (orientation: ${orientation})` : ''
}

export const breakpoint: ThemeBreakpoint<Styles> = {
  down: (breakpointSize: BreakpointSize | number, styles: Styles, orientation?: BreakpointOrientation): Styles => {
    const breakpointValue = getBreakpointMaxOrValue(breakpointSize)

    return {
      [`@media (max-width: ${breakpointValue}px)${orientationString(orientation)}`]: styles,
    }
  },
  up: (breakpointSize: BreakpointSize | number, styles: Styles, orientation?: BreakpointOrientation): Styles | {} => {
    const breakpointValue = getBreakpointMinOrValue(breakpointSize)

    return {
      [`@media (min-width: ${breakpointValue}px)${orientationString(orientation)}`]: styles,
    }
  },
  between: (
    breakpointSizeA: BreakpointSize | number,
    breakpointSizeB: BreakpointSize | number,
    styles: Styles,
    orientation?: BreakpointOrientation
  ): Styles | {} => {
    const minA = getBreakpointMinOrValue(breakpointSizeA)
    const maxB = getBreakpointMaxOrValue(breakpointSizeB)

    return {
      [`@media (min-width: ${minA}px) and (max-width: ${maxB}px)${orientationString(orientation)}`]: styles,
    }
  },
  only: (breakpointSize: BreakpointSize, styles: Styles, orientation?: BreakpointOrientation): Styles | {} => {
    const { min, max } = getBreakpointRange(breakpointSize)

    return {
      [`@media (min-width: ${min}px) and (max-width: ${max}px)${orientationString(orientation)}`]: styles,
    }
  },
}
