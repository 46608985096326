import type {
  PffFontFamilyDEPRECATED,
  FontStyle,
  FontWeight,
  InterFontFamilyDEPRECATED,
  Fonts,
} from '@pff-consumer/core-ui'
import { Platform } from 'react-native'

export interface FontAttributes {
  weight: FontWeight
  style: FontStyle
}

export interface Font extends FontAttributes {
  name: string
}

export const fonts = new Map<string, Font[]>([
  [
    'PFF Serif',
    [
      { name: 'PFFSerif-Bold', weight: '700', style: 'normal' },
      { name: 'PFFSerif-BoldItalic', weight: '700', style: 'italic' },
      { name: 'PFFSerif-Italic', weight: 'normal', style: 'italic' },
      { name: 'PFFSerif-Medium', weight: '500', style: 'normal' },
      { name: 'PFFSerif-MediumItalic', weight: '500', style: 'italic' },
      { name: 'PFFSerif-Regular', weight: 'normal', style: 'normal' },
      { name: 'PFFSerif-SemiBold', weight: '600', style: 'normal' },
      { name: 'PFFSerif-SemiBoldItalic', weight: '600', style: 'italic' },
    ],
  ],
  [
    'PFF Sans',
    [
      { name: 'PFFSans-Bold', weight: '700', style: 'normal' },
      { name: 'PFFSans-BoldItalic', weight: '700', style: 'italic' },
      { name: 'PFFSans-ExtraBold', weight: '800', style: 'normal' },
      { name: 'PFFSans-ExtraBoldItalic', weight: '800', style: 'italic' },
      { name: 'PFFSans-Italic', weight: 'normal', style: 'italic' },
      { name: 'PFFSans-Light', weight: '300', style: 'normal' },
      { name: 'PFFSans-LightItalic', weight: '300', style: 'italic' },
      { name: 'PFFSans-Medium', weight: '500', style: 'normal' },
      { name: 'PFFSans-MediumItalic', weight: '500', style: 'italic' },
      { name: 'PFFSans-Regular', weight: 'normal', style: 'normal' },
      { name: 'PFFSans-SemiBold', weight: '600', style: 'normal' },
      { name: 'PFFSans-SemiBoldItalic', weight: '600', style: 'italic' },
      { name: 'PFFSans-Thin', weight: '100', style: 'normal' },
      { name: 'PFFSans-ThinItalic', weight: '100', style: 'italic' },
    ],
  ],
  [
    'Inter',
    [
      { name: 'Inter-Black', weight: '900', style: 'normal' },
      { name: 'Inter-BlackItalic', weight: '900', style: 'italic' },
      { name: 'Inter-Bold', weight: '700', style: 'normal' },
      { name: 'Inter-BoldItalic', weight: '700', style: 'italic' },
      { name: 'Inter-ExtraBold', weight: '800', style: 'normal' },
      { name: 'Inter-ExtraBoldItalic', weight: '800', style: 'italic' },
      { name: 'Inter-ExtraLight', weight: '200', style: 'normal' },
      { name: 'Inter-ExtraLightItalic', weight: '200', style: 'italic' },
      { name: 'Inter-Italic', weight: 'normal', style: 'italic' },
      { name: 'Inter-Light', weight: '300', style: 'normal' },
      { name: 'Inter-LightItalic', weight: '300', style: 'italic' },
      { name: 'Inter-Medium', weight: '500', style: 'normal' },
      { name: 'Inter-MediumItalic', weight: '500', style: 'italic' },
      { name: 'Inter-Regular', weight: 'normal', style: 'normal' },
      { name: 'Inter-SemiBold', weight: '600', style: 'normal' },
      { name: 'Inter-SemiBoldItalic', weight: '600', style: 'italic' },
      { name: 'Inter-Thin', weight: '100', style: 'normal' },
      { name: 'Inter-ThinItalic', weight: '100', style: 'italic' },
    ],
  ],
  [
    'Avenir',
    [
      { name: 'Avenir-Roman', weight: '400', style: 'normal' },
      { name: 'Avenir-Oblique', weight: '400', style: 'italic' },
      { name: 'Avenir-Black', weight: '800', style: 'normal' },
      { name: 'Avenir-BlackOblique', weight: '800', style: 'italic' },
      { name: 'Avenir-Heavy', weight: '900', style: 'normal' },
      { name: 'Avenir-HeavyOblique', weight: '900', style: 'italic' },
    ],
  ],
  [
    'Roboto',
    [
      { name: 'Roboto-Regular', weight: '400', style: 'normal' },
      { name: 'Roboto-Medium', weight: '500', style: 'normal' },
      { name: 'Roboto-Bold', weight: '700', style: 'normal' },
      { name: 'Roboto-Black', weight: '900', style: 'normal' },
    ],
  ],
  [
    'Roboto Condensed',
    [
      { name: 'RobotoCondensed-Light', weight: '300', style: 'normal' },
      { name: 'RobotoCondensed-Regular', weight: '400', style: 'normal' },
      { name: 'RobotoCondensed-Bold', weight: '700', style: 'normal' },
    ],
  ],
  ['Barlow', [{ name: 'Barlow-Bold', weight: '700', style: 'normal' }]],
])

export const useFontStyles = (
  family: Fonts | PffFontFamilyDEPRECATED | InterFontFamilyDEPRECATED,
  weight: FontWeight = 'normal',
  style: FontStyle = 'normal'
) => {
  const foundFont = fonts.get(family)?.filter((f) => f.style === style && f.weight === weight)[0]
  return Platform.select({
    android: { fontFamily: foundFont?.name },
    ios: {
      fontFamily: family,
      fontWeight: foundFont?.weight,
      fontStyle: foundFont?.style,
    },
    web: {
      fontFamily: family,
      fontWeight: foundFont?.weight,
      fontStyle: foundFont?.style,
    },
  })
}
