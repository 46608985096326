export const transformWaiverTargetsResponse = (response: any) => {
  // @ts-ignore
  const transformedResponse = response.targets.map((entry) => {
    return {
      first_name: entry.firstName,
      last_name: entry.lastName,
      id: entry.playerId,
      position: entry.position,
      team: entry.teamAbbreviation,
      opposition: entry.nextOpponentAbbreviation,
      gameWeek: entry.nextGameWeek,
      matchTime: entry.nextGameTime,
      projection: entry.projection,
      rank: {
        current: entry.rank.current || 'NR',
        position: entry.rank.position || 'NR',
      },
      rankers: null,
      isHomeGame: entry.teamId === entry.homeTeamId,
    }
  })

  return {
    waiverBlurb: response.waiverBlurb,
    currentGameWeek: response.currentGameWeek,
    waiverOfTheWeek: transformedResponse.splice(0, 1)[0],
    targets: transformedResponse,
  }
}
