import { InjuryStatusAbbreviation } from '@pff-consumer/schema'
import { ColorNames } from '@pff-consumer/core-ui'

export const getInjuryStatusColor = (status: string | undefined): ColorNames => {
  switch (status) {
    case InjuryStatusAbbreviation.DOUBTFUL:
      return 'injuryDoubtful'
    case InjuryStatusAbbreviation.OUT:
    case InjuryStatusAbbreviation.INACTIVE:
      return 'injuryOut'
    case InjuryStatusAbbreviation.PROBABLE:
      return 'injuryProbable'
    case InjuryStatusAbbreviation.QUESTIONABLE:
      return 'injuryQuestionable'
    default:
      return 'injuryIn'
  }
}
