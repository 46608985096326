import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { consumerApi } from '../lib/consumer-api/consumer-api'
import { CHECKOUT_STATE_KEY, checkoutSlice } from '../lib/checkout/checkout.slice'
import { fantasySyncApi } from '../index.fantasy'
import { subscriptionApi } from '../lib/subscription/subscription-api'

declare const process: any

const reducers = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [consumerApi.reducerPath]: consumerApi.reducer,
  [fantasySyncApi.reducerPath]: fantasySyncApi.reducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  [CHECKOUT_STATE_KEY]: checkoutSlice.reducer,
})

const devTools = process.env.NODE_ENV === 'development'

const middlewares = [consumerApi.middleware, subscriptionApi.middleware]

export const makeStore = () =>
  configureStore({
    reducer: reducers,
    devTools,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat(middlewares),
  })

export const makeTestStore = (preloadedState?: any) =>
  configureStore({
    reducer: reducers,
    devTools,
    preloadedState,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  })
