import { AddListenerOverloads } from '@reduxjs/toolkit/dist/listenerMiddleware/types'
import { FANTASY_CONSTANTS, FantasyScoringType, InSeasonTabIndex, League } from '@pff-consumer/schema'
import { SelectedLeagueFromMyLeagues } from '@pff-consumer/utils'
import { fantasyInSeasonApi } from '../../../lib/fantasy-in-season-api/fantasy-in-season-api'
import {
  fantasySlice,
  fantasySelectors,
  initialFantasyState,
  selectRecentlySyncedLeague,
  processMatchupData,
} from '../../../lib/fantasy/fantasy.slice'
import { accountSlice } from '../../../lib/account/account.slice'
import type { AppRootState } from '../../store'

const { API_SCORING_TYPE } = FANTASY_CONSTANTS

export const addFantasyInSeasonSpecificListeners = (startListening: AddListenerOverloads<unknown>) => {
  startListening({
    matcher: fantasyInSeasonApi.endpoints.getMyLeaguesForInSeason.matchFulfilled,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      if (action.payload?.leagues && action.payload.leagues.length > 0) {
        // We want to store this in redux so we can create selectors to return all leagues or non manual leagues easier
        listenerApi.dispatch(fantasySlice.actions.updateSyncedLeagues(action.payload.leagues))
      }

      // Don't run on native
      if (typeof localStorage !== 'undefined') {
        // If user navigates from my leagues page by clicking on league, make sure we auto-select that league on my team dashboard
        const leagueSelectedFromMyLeaguesPage = localStorage.getItem(SelectedLeagueFromMyLeagues)
        if (leagueSelectedFromMyLeaguesPage && action.payload?.leagues && action.payload.leagues.length > 0) {
          const leagueToSelect = action.payload.leagues.find(
            (league) => league.id === Number(leagueSelectedFromMyLeaguesPage)
          )

          if (leagueToSelect) {
            listenerApi.dispatch(fantasySlice.actions.updateInSeasonSelectedLeague(leagueToSelect))
            localStorage.removeItem(SelectedLeagueFromMyLeagues)
          }
        }
      }
    },
  })

  // Update inseason scoring type filter on selected league change
  // Get user roster for selected league
  startListening({
    actionCreator: fantasySlice.actions.updateInSeasonSelectedLeague,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()
      // If there is no league selected use default scoring type
      let { scoringType } = initialFantasyState.inSeason.weeklyRankings.filters
      // update scoring type filter if user selects a league
      if (action.payload) {
        const { leagueSettings } = action.payload
        const pprValue = leagueSettings?.recv_receptions1
        switch (pprValue) {
          case 0:
            scoringType = API_SCORING_TYPE[FantasyScoringType.STANDARD]
            break
          case 0.5:
            scoringType = API_SCORING_TYPE[FantasyScoringType.ZERO_POINT_FIVE_PPR]
            break
          default:
            scoringType = API_SCORING_TYPE[FantasyScoringType.PPR]
            break
        }
      } else {
        listenerApi.dispatch(fantasySlice.actions.updateInSeasonRosterPlayers({ starters: [], bench: [] }))
      }
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonWeeklyRankingsFilterScoringType(scoringType))
      // Reset start-sit player selection when user changes selected league
      listenerApi.dispatch(fantasySlice.actions.updateStartSitPlayerAId(null))
      listenerApi.dispatch(fantasySlice.actions.updateStartSitPlayerBId(null))
    },
  })

  startListening({
    matcher: fantasyInSeasonApi.endpoints.getMyTeamData.matchFulfilled,
    effect: async (action, listenerApi) => {
      listenerApi.cancelActiveListeners()
      const { roster, gameWeek, matchup, rankings } = action.payload
      // set default value in cases where these fields aren't in the payload
      const rosterData = roster || { starters: [], bench: [] }
      const gameWeekData = gameWeek || null
      const matchupData = matchup || null
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonRosterPlayers(rosterData))
      listenerApi.dispatch(fantasySlice.actions.updateCurrentGameWeek(gameWeekData))
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonMyTeamMatchup(matchupData))
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonMyTeamPlayerRankingsMap(rankings))
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonMyTeamMatchupLoading(false))
      listenerApi.dispatch(processMatchupData)
    },
  })

  startListening({
    matcher: fantasyInSeasonApi.endpoints.getMyTeamData.matchRejected,
    effect: async (action, listenerApi) => {
      listenerApi.cancelActiveListeners()
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonRosterPlayers({ starters: [], bench: [] }))
      listenerApi.dispatch(fantasySlice.actions.updateCurrentGameWeek(null))
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonMyTeamMatchup(null))
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonMyTeamMatchupLoading(false))
      listenerApi.dispatch(processMatchupData)
    },
  })

  startListening({
    actionCreator: selectRecentlySyncedLeague,
    effect: async (action, listenerApi) => {
      listenerApi.cancelActiveListeners()
      // Manually added leagues are available only for weekly rankings tab
      // If manual league is selected on weekly rankings, when switching to other tabs select recently synced league non manual league
      const state = listenerApi.getState() as AppRootState
      const { selectedLeague, activeTabIndex } = state.fantasy.inSeason
      const showManualLeagues = activeTabIndex === InSeasonTabIndex.RANKING
      let availableSyncedLeagues: League[] = []
      if (showManualLeagues) {
        availableSyncedLeagues = fantasySelectors.getSyncedLeagues(state as AppRootState)
      } else {
        availableSyncedLeagues = fantasySelectors.getNonManualSyncedLeagues(state as AppRootState)
      }

      const isSelectedLeagueAvailable = availableSyncedLeagues.some((league) => league.id === selectedLeague?.id)
      if (selectedLeague && isSelectedLeagueAvailable) {
        return
      }
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonSelectedLeague(availableSyncedLeagues[0]))
    },
  })

  startListening({
    actionCreator: fantasySlice.actions.setInSeasonActiveTabIndex,
    effect: async (action, listenerApi) => {
      listenerApi.cancelActiveListeners()
      const state = listenerApi.getState() as AppRootState
      const { isLeagueUnselected } = state.fantasy.inSeason
      // Don't dispatch selectRecentlySyncedLeague when the user intentionally deselected league
      if (!isLeagueUnselected) {
        listenerApi.dispatch(selectRecentlySyncedLeague)
      }
    },
  })

  // Delete user synced leagues, selected league and user roster when user logs out
  startListening({
    actionCreator: accountSlice.actions.deleteToken,
    effect: async (action, listenerApi) => {
      listenerApi.cancelActiveListeners()
      listenerApi.dispatch(fantasySlice.actions.updateSyncedLeagues([]))
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonSelectedLeague(null))
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonRosterPlayers({ starters: [], bench: [] }))
      listenerApi.dispatch(fantasySlice.actions.updateInSeasonMyTeamMatchup(null))
    },
  })
}
