import { camelCase, startCase } from 'lodash'
import {
  FantasyLeagueType,
  FantasyPosition,
  FantasyDisplayPosition,
  FantasyRanker,
  FantasyScoringType,
  FantasyTag,
  FantasyMatchupPosition,
} from './fantasy'
import { Routes } from '../routes/routes'

export const ROSTER_POSITION_SORT_ORDER = {
  // Have this in the sort order of the roster positions
  [FantasyDisplayPosition.QB]: 1,
  [FantasyDisplayPosition.RB]: 2,
  [FantasyDisplayPosition.WR]: 3,
  [FantasyDisplayPosition.TE]: 4,
  [FantasyDisplayPosition.FLEX]: 5,
  [FantasyDisplayPosition.SUPER_FLEX]: 6,
  [FantasyDisplayPosition.REC_FLEX]: 7,
  [FantasyDisplayPosition.WRRB_FLEX]: 8,
  [FantasyDisplayPosition.K]: 9,
  [FantasyDisplayPosition.DST]: 10,
  [FantasyDisplayPosition.BN]: 11,
}

export const TEAM_ID_MAP: { [key: number]: string } = {
  1: 'ARI',
  2: 'ATL',
  3: 'BAL',
  4: 'BUF',
  5: 'CAR',
  6: 'CHI',
  7: 'CIN',
  8: 'CLE',
  9: 'DAL',
  10: 'DEN',
  11: 'DET',
  12: 'GB',
  13: 'HOU',
  14: 'IND',
  15: 'JAX',
  16: 'KC',
  17: 'MIA',
  18: 'MIN',
  19: 'NE',
  20: 'NO',
  21: 'NYG',
  22: 'NYJ',
  23: 'LV',
  24: 'PHI',
  25: 'PIT',
  26: 'LAR',
  27: 'LAC',
  28: 'SF',
  29: 'SEA',
  30: 'TB',
  31: 'TEN',
  32: 'WAS',
}

const POSITIONS = [
  FantasyDisplayPosition.QB,
  FantasyDisplayPosition.RB,
  FantasyDisplayPosition.WR,
  FantasyDisplayPosition.TE,
  FantasyDisplayPosition.FLEX,
  FantasyDisplayPosition.SUPER_FLEX,
  FantasyDisplayPosition.REC_FLEX,
  FantasyDisplayPosition.WRRB_FLEX,
  FantasyDisplayPosition.K,
  FantasyDisplayPosition.DST,
  FantasyDisplayPosition.DEF,
]

// Note:
// The new flex positions are handled in POSITIONS variable, so we use temporary variable `NO_ADDITIONAL_FLEX_POSITIONS` to manage existing filter positions
// The player position filters with the new additional flex positions will be implemented through FANX-1534
const NO_ADDITIONAL_FLEX_POSITIONS = [
  FantasyPosition.QB,
  FantasyPosition.RB,
  FantasyPosition.WR,
  FantasyPosition.TE,
  FantasyPosition.FLEX,
  FantasyPosition.K,
  FantasyDisplayPosition.DEF,
]

const IDP_POSITIONS = [
  FantasyPosition.ED,
  FantasyPosition.DT,
  FantasyPosition.LB,
  FantasyPosition.CB,
  FantasyPosition.S,
  FantasyPosition.DL,
  FantasyPosition.DB,
]

// Todo: Remove IN_SEASON_POSITIONS when super flex position filter is implemented for draft experience
const IN_SEASON_POSITIONS = [
  FantasyPosition.QB,
  FantasyPosition.RB,
  FantasyPosition.WR,
  FantasyPosition.TE,
  FantasyPosition.FLEX,
  FantasyPosition.SFL,
  FantasyPosition.K,
  FantasyPosition.DEF,
]

const IN_SEASON_MATCHUP_POSITIONS = [FantasyPosition.WR, FantasyPosition.TE]
export const MATCHUP_POSITIONS = [
  FantasyMatchupPosition.QB,
  FantasyMatchupPosition.RB,
  FantasyMatchupPosition.WR,
  FantasyMatchupPosition.TE,
]

export const ORDERED_FANTASY_POSITIONS = [
  FantasyDisplayPosition.QB,
  FantasyDisplayPosition.RB,
  FantasyDisplayPosition.WR,
  FantasyDisplayPosition.TE,
  FantasyDisplayPosition.FLEX,
  FantasyDisplayPosition.SUPER_FLEX,
  FantasyDisplayPosition.WRRB_FLEX,
  FantasyDisplayPosition.REC_FLEX,
  FantasyDisplayPosition.K,
  FantasyDisplayPosition.DST,
  FantasyDisplayPosition.DEF,
  'D',
]

const TAGS = [
  FantasyTag.PFF_VALUE,
  FantasyTag.BREAKOUT,
  FantasyTag.SLEEPER,
  // FantasyTag.LEAGUE_WINNER,
  FantasyTag.BUST,
  FantasyTag.INJURY_HISTORY,
  FantasyTag.ROOKIE,
  // FantasyTag.PLUS_UTILIZATION,
  // FantasyTag.TALENTED_AF,
]

const API_SCORING_TYPE: any = {
  [FantasyScoringType.PPR]: 'ppr',
  [FantasyScoringType.ZERO_POINT_FIVE_PPR]: 'halfPPR',
  [FantasyScoringType.STANDARD]: 'standard',
  [FantasyScoringType.IDP]: 'idp',
}

const SCORING_TYPE = [
  FantasyScoringType.PPR,
  FantasyScoringType.ZERO_POINT_FIVE_PPR,
  FantasyScoringType.STANDARD,
  // TODO: Add back when ready
  // FantasyScoringType.TE_PREMIUM,
]

// TODO: Clean up
const API_LEAGUE_TYPE: any = {
  [FantasyLeagueType.STANDARD]: 'standard',
  [FantasyLeagueType.TWO_QB]: 'superflex',
}

const LEAGUE_TYPE = [
  FantasyLeagueType.STANDARD,
  FantasyLeagueType.TWO_QB,
  // TODO: Add back when ready
  // FantasyLeagueType.DYNASTY_STARTUP,
  // FantasyLeagueType.DYNASTY_ROOKIE,
  // FantasyLeagueType.IDP,
]

// Label generation
const FORMATTED_TAGS = TAGS.map((tag) => {
  if (tag === FantasyTag.PFF_VALUE) {
    return { label: 'PFF Value', value: FantasyTag.PFF_VALUE }
  }
  if (tag === FantasyTag.TALENTED_AF) {
    return { label: 'Talented AF', value: FantasyTag.TALENTED_AF }
  }

  return {
    label: startCase(camelCase(tag)),
    value: tag,
  }
})

export type TransformResponse = {
  label: string
  value: string
  icon?: string
  iconGroup?: string
  customSize?: number
  isDisabled?: boolean
  notClickable?: boolean
}
const transformOption = (value: string): TransformResponse => ({ label: value, value })

export const LABEL_FANTASY_POSITION_DT = 'DT'

export const FANTASY_LABELS = {
  POSITIONS: NO_ADDITIONAL_FLEX_POSITIONS.map(transformOption).map((item) => {
    if (item.value === FantasyPosition.DEF) {
      return {
        label: FantasyPosition.DST,
        value: item.value,
      }
    }

    return item
  }),
  IDP_POSITIONS: IDP_POSITIONS.map(transformOption).map((item) => {
    if (item.value === FantasyPosition.DT) {
      return {
        label: LABEL_FANTASY_POSITION_DT,
        value: item.value,
      }
    }

    return item
  }),
  // Todo: Remove IN_SEASON_POSITIONS when super flex position filter is implemented for draft experience
  IN_SEASON_POSITIONS: IN_SEASON_POSITIONS.map(transformOption).map((item) => {
    if (item.value === FantasyPosition.DEF) {
      return {
        label: FantasyPosition.DST,
        value: item.value,
      }
    }
    return item
  }),
  IN_SEASON_MATCHUP_POSITIONS: IN_SEASON_MATCHUP_POSITIONS.map(transformOption),
  MATCHUP_POSITIONS: MATCHUP_POSITIONS.map(transformOption),
  SCORING_TYPE: SCORING_TYPE.map((value: string): { label: string; value: string } => ({
    label: value,
    value: API_SCORING_TYPE[value],
  })),
  LEAGUE_TYPE: LEAGUE_TYPE.map((value: string): { label: string; value: string } => ({
    label: value,
    value: value === FantasyLeagueType.TWO_QB ? value : API_LEAGUE_TYPE[value],
  })),
  TAGS: FORMATTED_TAGS.map(({ label, value }): { label: string; value: string; icon: string; iconGroup: string } => ({
    label,
    icon: value.replaceAll('_', '-'),
    // TODO: FIX - We get circular dependency issues if we reference the SVGGroup directly here🙄
    iconGroup: 'fantasy',
    value,
  })),
}

export const RANKINGS_SCORING_TYPES = [
  {
    title: 'REDRAFT',
    options: [
      { name: 'PPR', value: API_SCORING_TYPE[FantasyScoringType.PPR] },
      { name: 'Half PPR', value: API_SCORING_TYPE[FantasyScoringType.ZERO_POINT_FIVE_PPR] },
      { name: 'NON-PPR', value: API_SCORING_TYPE[FantasyScoringType.STANDARD] },
      { name: 'IDP', value: API_SCORING_TYPE[FantasyScoringType.IDP] },
    ],
  },
]

const EXCLUDED_COMPARE_POSITIONS = [FantasyPosition.K, FantasyPosition.DEF]

// TODO For now removing the My Team tab. Will include it when it is ready
const IN_SEASON_HEADER_TABS = ['My Team', 'Rankings', 'Start/Sit', 'Matchups', 'Waiver Targets']
const MY_TEAM_HEADER_TABS = ['Matchups', 'Waiver Targets']
const SEASON: number = 2024
const NUM_OF_GAMES_PER_TEAM: number = 17

const THIRD_PARTY_ERRORS = {
  // internal error cases
  SYNCED_LEAGUE_NOT_FOUND: `Synced league not found.`,
  UNSUPPORTED_FANTASY_PROVIDER: `Unsupported fantasy provider.`,
  // special cases in UI
  UNSUPPORTED_POSITION: 'League contains unsupported position.',
  USERS_TEAM_UNKNOWN: `Couldn't determine team, please resync league.`,
  // not found errors
  LEAGUE_NOT_FOUND: `League not found.`,
  ROSTER_NOT_FOUND: `Roster not found.`,
  OPPONENT_ROSTER_NOT_FOUND: `Opponent roster not found.`,
  MATCHUP_NOT_FOUND: `Matchup not found for provided week.`,
  // out of season errors
  LEAGUE_NOT_IN_SEASON: `The requested league isn't in season.`,
  DRAFT_NOT_IN_SEASON: `League does not have a draft this season.`,
  // missing secrets
  MISSING_ESPN_SECRETS: `Request missing required ESPN access tokens.`,
  MISSING_YAHOO_SECRETS: `Request missing required Yahoo access tokens.`,
  // Yahoo specific
  YAHOO_FAILED_REFRESH: `Failed to refresh Yahoo access tokens.`,
  YAHOO_TOKEN_SAVE: `Failed to save Yahoo access tokens.`,
  YAHOO_URL_REQUIRED: 'A valid Yahoo league URL is required.',
  YAHOO_CANNOT_DETERMINE_LEAGUE_ID: `Could not determine the league ID from the provided Yahoo URL.`,
  YAHOO_TEAM_CANNOT_OWN: `Your Yahoo account does not own a team on this league.`,
  // ESPN specific
  ESPN_KEEPERS_NON_SNAKE_DRAFT: 'Cannot calculate keeper pick positions for non-snake drafts.',
  ESPN_URL_REQUIRED: 'A valid ESPN league URL is required.',
  ESPN_CANNOT_DETERMINE_LEAGUE_ID: 'Could not determine the league ID from the provided ESPN URL.',
  // Sleeper specific
  SLEEPER_USER_NOT_FOUND: `Sleeper user not found.`,
  SLEEPER_DRAFT_NOT_FOUND: `Sleeper draft not found.`,
  SLEEPER_DRAFT_PICKS_NOT_FOUND: 'Sleeper draft picks not found.',
  SLEEPER_LEAGUE_ID_REQUIRED: 'A valid Sleeper league ID is required.',
  SLEEPER_LEAGUE_USERNAME_REQUIRED: 'A valid Sleeper username is required.',
  SLEEPER_LEAGUE_NOT_FOUND: 'Sleeper league not found.',
  // other
  // METHOD_NOT_IMPLEMENTED - used by methods not supported by all providers
  METHOD_NOT_IMPLEMENTED: `Method not implemented.`,
}

const IDP_RANKINGS_URL = 'https://www.pff.com/fantasy/week/rankings?scoring=preset_ppr_idp_bal'

export const FANTASY_CONSTANTS = {
  TEAMS: TEAM_ID_MAP,
  POSITIONS,
  IDP_POSITIONS,
  TAGS,
  SCORING_TYPE,
  LEAGUE_TYPE,
  API_SCORING_TYPE,
  API_LEAGUE_TYPE,
  EXCLUDED_COMPARE_POSITIONS,
  IN_SEASON_HEADER_TABS,
  IN_SEASON_POSITIONS,
  FANTASY_RANKERS: FantasyRanker,
  SEASON,
  NUM_OF_GAMES_PER_TEAM,
  MY_TEAM_HEADER_TABS,
  THIRD_PARTY_ERRORS,
  IDP_RANKINGS_URL,
}

export const FANTASY_PREP_OPTIONS = [
  {
    title: 'Rankings',
    path: Routes.Rankings,
    subText: 'Award winning rankings by PFF analyst Nathan Jahnke.',
  },
  {
    title: 'Live Draft Assistant',
    path: Routes.LiveDraftAssistant,
    subText: 'Enter draft room to begin to prep for your draft by favoriting players.',
  },
  {
    title: 'Fantasy Mock Draft Simulator',
    path: Routes.MockDraftSimulator,
    subText: 'Simulate your fantasy draft to prepare your pick strategy.',
  },
  {
    title: 'Cheat Sheet',
    path: Routes.Cheatsheet,
    subText: 'Prepare for your draft by prepping your cheat sheet.',
  },
  {
    title: 'Fantasy News & Articles',
    path: Routes.Articles,
    subText: 'Prepare to dominate your fantasy league with up-to-date fantasy news and content.',
  },
]

export const MODAL_LOCATION = {
  FMDS: 'FMDS',
  LDA: 'LDA',
  LEAGUE_SYNC: 'League Sync',
}

export const HAS_SEEN_SYNC_LEAGUE_MODAL = 'hasSeenSyncLeagueModal'
export const HAS_SEEN_HIGH_TRAFFIC_MODAL = 'hasSeenHighTrafficModal'

export const PAYWALL_REDIRECT_URL = 'https://www.pff.com/join?product=plus&referrer=fmds'
export const CHROME_EXTENSION_URL =
  'https://chromewebstore.google.com/detail/pff-for-your-fantasy-leag/enpmekoogcpafokplcfodchijhmnmbdm'
