import { FootballPlayer } from '../football'

export enum InjuryStatus {
  Probable = 'probable',
  Questionable = 'questionable',
  Doubtful = 'doubtful',
  Out = 'out',
  Active = 'active',
}

export enum TwitterNewsType {
  Injury = 'injury',
  General = 'general',
}

export interface InjuryDepthChart {
  playerId: number
  playerFirstName: string | null
  playerLastName: string | null
  depthOrder: number | null
}
export interface TweetInformation {
  playerId: number
  playerFirstName: string
  playerLastName: string
  tweetId: string
  text: string
  authorUsername: string
  createdAt: string
  newsType: string
}

export interface InjuryTweetInformationWithPlayerData extends InjuryTweetInformation {
  player: FootballPlayer
  replacementPlayer?: FootballPlayer
}

export interface InjuryTweetInformation extends TweetInformation {
  newsType: TwitterNewsType.Injury
  depthChart: InjuryDepthChart[]
  injuryStatus: InjuryStatus | null
}

export interface Injuries {
  firstName: string
  lastName: string
  playerId: number
  position: string
  status: string
  declaredInactive: boolean
}

export interface InjuryData {
  eventId: number
  injuries: Injuries[]
}

export interface PlayersInjuryRecord {
  [key: number]: InjuryStatus
}
