import { MixedRosterItem } from '@pff-consumer/schema'
import { GradeFacet, PlayerMatchup, PlayerPosition } from '@pro-football-focus/pffkit-js-core'
import { getPlayerByPosition } from '../get-players-by-position'

export const getFilteredMatchups = (
  matchups: PlayerMatchup[],
  starters?: MixedRosterItem[],
  bench?: MixedRosterItem[],
  position?: PlayerPosition,
  facet?: GradeFacet
): PlayerMatchup[] => {
  let filteredMatchups: PlayerMatchup[] = matchups

  if (position) {
    filteredMatchups = filteredMatchups.filter((matchup: PlayerMatchup) => matchup.player.position === position)
  }

  if (facet) {
    filteredMatchups = filteredMatchups.filter((matchup: PlayerMatchup) => matchup.playerGrade.facet === facet)
  }

  if (starters && bench && position) {
    const players = [...getPlayerByPosition(starters, position), ...getPlayerByPosition(bench, position)]

    filteredMatchups = filteredMatchups.filter((matchup: PlayerMatchup) => players.includes(matchup.player.id))
  }

  return filteredMatchups
}
