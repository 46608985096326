import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

export const WEB_VIEW_KEY = 'webview'

export interface WebViewState {
  activeUrl: string
  hasShownFooter: boolean
  hasShownShareButton: boolean
  isOpen: boolean
  shareableUrl?: string
}

export const webViewAdapter = createEntityAdapter<WebViewState>()

export const initialWebViewState: WebViewState = webViewAdapter.getInitialState({
  activeUrl: '',
  hasShownFooter: true,
  hasShownShareButton: false,
  isOpen: false,
})

export const webViewSlice = createSlice({
  name: WEB_VIEW_KEY,
  initialState: initialWebViewState,
  reducers: {
    openWebView: (state, { payload }) => {
      state.isOpen = true
      state.activeUrl = payload.url
      state.hasShownFooter = payload?.hasShownFooter === undefined ? true : payload?.hasShownFooter
      state.hasShownShareButton = payload?.hasShownShareButton === undefined ? false : payload?.hasShownShareButton
      state.shareableUrl = payload?.shareableUrl
    },
    closeWebView: (state) => {
      state.isOpen = false
      state.activeUrl = ''
    },
  },
})

export const webViewReducer = webViewSlice.reducer
export const webViewActions = webViewSlice.actions

export const getwebViewState = (rootState: { [x: string]: WebViewState }): WebViewState => {
  return rootState[WEB_VIEW_KEY]
}

export const webViewSelectors = {
  getUrl: createSelector(getwebViewState, (state: WebViewState) => state.activeUrl),
  getIsOpen: createSelector(getwebViewState, (state: WebViewState) => state.isOpen),
  getHasShownFooter: createSelector(getwebViewState, (state: WebViewState) => state.hasShownFooter),
  getHasShownShareButton: createSelector(getwebViewState, (state: WebViewState) => state.hasShownShareButton),
  getShareableUrl: createSelector(getwebViewState, (state: WebViewState) => state.shareableUrl),
}
