import type { RecurlyError } from '@recurly/recurly-js'
import { useDispatch, useSelector } from 'react-redux'
import {
  CheckoutResponseError,
  SubscriptionPlanType,
  WindowDimensionsType,
  SubscriptionPlans,
} from '@pff-consumer/schema'
import { checkoutSelectors, checkoutActions } from './checkout.slice'

export const checkoutHooks = {
  useSelectedPlan: () => useSelector(checkoutSelectors.getSelectedPlan),
  usePromoCode: () => useSelector(checkoutSelectors.getPromoCode),
  useWindowDimensions: () => useSelector(checkoutSelectors.getWindowDimensions),
  useBreakpoints: () => useSelector(checkoutSelectors.getBreakpoints),
  useEmail: () => useSelector(checkoutSelectors.getEmail),
  useEmailErrorMessage: () => useSelector(checkoutSelectors.getEmailErrorMessage),
  useGrootUid: () => useSelector(checkoutSelectors.getGrootUid),
  useIsEmailValid: () => useSelector(checkoutSelectors.getIsEmailValid),
  useNewsLetterPreference: () => useSelector(checkoutSelectors.getNewsLetterPreference),
  useCheckoutError: () => useSelector(checkoutSelectors.getCheckoutError),
  usePaymentError: () => useSelector(checkoutSelectors.getPaymentError),
  useMonthlyPlan: () => useSelector(checkoutSelectors.getMonthlyPlan),
  useVariant: () => useSelector(checkoutSelectors.getVariant),
  useExpirationDate: () => useSelector(checkoutSelectors.getExpirationDate),
  useIsCancelSubscriptionModalOpen: () => useSelector(checkoutSelectors.getIsCancelSubscriptionModalOpen),
  useSubscriptionPlan: () => useSelector(checkoutSelectors.getSubscriptionPlan),

  useCheckoutActions: () => {
    const dispatch = useDispatch()
    return {
      clearPaymentError: () => {
        dispatch(checkoutActions.clearPaymentError())
      },
      updateSelectedPlan: (payload: SubscriptionPlanType) => {
        dispatch(checkoutActions.updateSelectedPlan(payload))
      },
      updatePromoCode: (payload: string) => {
        dispatch(checkoutActions.updatePromoCode(payload))
      },
      updateWindowDimensions: (payload: WindowDimensionsType) => {
        dispatch(checkoutActions.updateDimensions(payload))
      },
      updatePaymentError: (payload: RecurlyError) => {
        dispatch(checkoutActions.updatePaymentError(payload))
      },
      updateCheckoutError: (payload: CheckoutResponseError) => {
        dispatch(checkoutActions.updateCheckoutError(payload))
      },
      setDefaultEmail: (payload?: string) => {
        dispatch(checkoutActions.setDefaultEmail(payload))
      },
      updateEmail: (payload: string) => {
        dispatch(checkoutActions.updateEmail(payload))
        dispatch(checkoutActions.validateEmail())
      },
      validateEmail: () => {
        dispatch(checkoutActions.validateEmail())
      },
      setGrootUid: (payload: string) => {
        dispatch(checkoutActions.setGrootUid(payload))
      },
      toggleNewLetterPreference: () => {
        dispatch(checkoutActions.toggleNewsLetterPreference())
      },
      setNewLetterPreference: (payload: boolean) => {
        dispatch(checkoutActions.setNewsLetterPreference(payload))
      },
      updateMonthlyPlan: (plan: SubscriptionPlans) => {
        dispatch(checkoutActions.updateMonthlyPlan(plan))
      },
      updateVariant: (variant: string) => {
        dispatch(checkoutActions.updateVariant(variant))
      },
      updateExpirationDate: (date: string) => {
        dispatch(checkoutActions.updateExpirationDate(date))
      },
      updateIsCancelSubscriptionModalOpen: (payload: boolean) => {
        dispatch(checkoutActions.updateIsCancelSubscriptionModalOpen(payload))
      },
      updateSubscriptionPlan: (payload: string) => {
        dispatch(checkoutActions.updateSubscriptionPlan(payload))
      },
    }
  },
}
