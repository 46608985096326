import { getBreakpointRange } from '@pff-consumer/core-ui'

export const getDeviceProperty = (platform: string) => {
  if (platform === 'android' || platform === 'ios') {
    return 'mobile app'
  }
  if (platform === 'web' && window) {
    const tablet = getBreakpointRange('md')
    const desktop = getBreakpointRange('lg')

    if (window.innerWidth < tablet.min) {
      return 'mobile web'
    }
    if (window.innerWidth < desktop.min) {
      return 'tablet'
    }
    return 'desktop'
  }

  return `unknown-${platform}`
}
