import { debounce } from 'lodash'
import { useEffect, useState } from 'react'

type WindowSize = {
  width: number
  height: number
}

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-inner-declarations
      const debouncedResize = debounce(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }, 100)

      window.addEventListener('resize', debouncedResize)

      // Call handler right away so state gets updated with initial window size
      debouncedResize()

      return () => window.removeEventListener('resize', debouncedResize)
    }
    return () => undefined
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}
