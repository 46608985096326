import { AddListenerOverloads } from '@reduxjs/toolkit/dist/listenerMiddleware/types'
import type { GetNextCpuPicksRequest, MDSLeagueSettings } from '@pff-consumer/schema'
import { transformSyncedSettings } from '@pff-consumer/utils'
import { fantasySlice, mutateRankings, startMds } from '../../lib/fantasy/fantasy.slice'
import { fantasyLdaMdsApi } from '../../lib/fantasy-lda-mds/fantasy-lda-mds-api'
import { transformManualSettings } from '../utils/transform-manual-settings'
import { transformLeagueSettingsForMds } from '../utils/transform-league-settings-for-mds'
import type { RootState } from '../store.fantasy'

const FALLBACK_DELAY_TIME = 500

export const addFantasyMDSSpecificListeners = (startListening: AddListenerOverloads<unknown>) => {
  // Step:
  startListening({
    actionCreator: startMds,
    effect: async (_action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      const state = listenerApi.getState() as RootState
      const { selectedSyncedLeague, leagueSettings } = state.fantasy.draftRoom

      listenerApi.dispatch(fantasySlice.actions.resetAllPlayerAnimations())
      listenerApi.dispatch(fantasySlice.actions.updateMdsEndingEarly(false))
      if (selectedSyncedLeague) {
        const clonedLeague = { ...selectedSyncedLeague }
        const transformedSettings = transformSyncedSettings(clonedLeague, leagueSettings as MDSLeagueSettings)
        listenerApi.dispatch(fantasySlice.actions.updateDraftRoomLeagueSettings(transformedSettings))
      } else {
        const { manualLeagueSettings } = state.fantasy.draftRoom
        const transformedSettings = transformManualSettings(manualLeagueSettings, leagueSettings as MDSLeagueSettings)
        listenerApi.dispatch(fantasySlice.actions.updateDraftRoomLeagueSettings(transformedSettings))
        listenerApi.dispatch(fantasySlice.actions.updateLeagueKeepers([]))
      }
    },
  })

  startListening({
    actionCreator: fantasySlice.actions.updateMdsEndingEarly,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      // Do nothing
      if (action.payload === false) {
        return
      }

      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomSuggestionsLoading(true))

      const state = listenerApi.getState() as RootState
      const { draftPicks, leagueSettings, leagueKeepers, pickInfo, userPicks, userPicksPickGrades } =
        state.fantasy.draftRoom
      let cutoffRound = pickInfo.round
      const numOfPicks = draftPicks.length
      const numberOfFantasyPlayers = leagueSettings.fantasy_players_in_league

      if (numOfPicks % numberOfFantasyPlayers !== 0) {
        cutoffRound -= 1
      }
      const cutoffNumberOfPicks = cutoffRound * numberOfFantasyPlayers

      const slicedDraftPicks = draftPicks.slice(0, cutoffNumberOfPicks)
      const slicedUserPicks = userPicks.slice(0, cutoffRound)
      const slicedPickGrades = userPicksPickGrades.slice(0, cutoffRound)

      // Make sure we slice keepers off as well
      const slicedKeepers = leagueKeepers.filter((keeper) => keeper.pickNumber <= cutoffNumberOfPicks)

      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomEnded(true))
      listenerApi.dispatch(fantasySlice.actions.updateIsUserOnTheClock(false))

      // Transform drafted info in store
      listenerApi.dispatch(fantasySlice.actions.updateLeagueKeepers(slicedKeepers))
      listenerApi.dispatch(fantasySlice.actions.updateAllMdsDraftPicks(slicedDraftPicks))
      listenerApi.dispatch(fantasySlice.actions.updateAllUserPicks(slicedUserPicks))
      listenerApi.dispatch(fantasySlice.actions.updateAllUserPicksPickGrades(slicedPickGrades))

      // We want to end at the earlier round to make sure the round is complete and has all picks
    },
  })

  // Step: Whenever CPU makes their picks, replace all draftedPicks object
  startListening({
    matcher: fantasyLdaMdsApi.endpoints.getNextCpuPicks.matchFulfilled,
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      const delayTime =
        typeof window !== 'undefined' && window?.mdsDelayTime
          ? window?.mdsDelayTime || FALLBACK_DELAY_TIME
          : FALLBACK_DELAY_TIME
      // If draft is over make it clear
      if (action.payload.message === 'draft over now') {
        listenerApi.dispatch(fantasySlice.actions.updateDraftRoomEnded(true))
        listenerApi.dispatch(fantasySlice.actions.updateIsUserOnTheClock(false))
      } else {
        // @ts-expect-error
        const { newPicks } = action.payload
        const state = listenerApi.getState() as RootState
        const { draftPicks, leagueSettings, pickInfo } = state.fantasy.draftRoom

        const numberOfFantasyPlayers = leagueSettings.fantasy_players_in_league
        let numberOfPicksCompleted = draftPicks.length
        const currentRound = pickInfo.round
        // Stagger CPU picks to make it more fun
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < newPicks.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          await listenerApi.delay(delayTime)
          const pick = newPicks[i]
          listenerApi.dispatch(fantasySlice.actions.updateMdsDraftPicks(pick))
          numberOfPicksCompleted += 1
          // When the CPU picks happen, it finishes one round and goes to the next, we should update the current round number
          if (numberOfPicksCompleted % numberOfFantasyPlayers === 1) {
            listenerApi.dispatch(fantasySlice.actions.updateDraftRoomCurrentRound(currentRound + 1))
          }
        }

        listenerApi.dispatch(fantasySlice.actions.updateDraftRoomSuggestionsLoading(true))
        listenerApi.dispatch(mutateRankings())

        // If draft is over after cpu picks we do not want to get next suggestions
        if (action.payload.message === 'draft over after CPU picks') {
          listenerApi.dispatch(fantasySlice.actions.updateDraftRoomEnded(true))
          listenerApi.dispatch(fantasySlice.actions.updateIsUserOnTheClock(false))
        } else {
          listenerApi.dispatch(fantasySlice.actions.fetchNextPickInfo())
          listenerApi.dispatch(fantasySlice.actions.updateIsUserOnTheClock(true))
        }
      }
      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomDrafting(false))
    },
  })

  // Step: Listen to when a user makes a pick and call the right endpoint
  startListening({
    actionCreator: fantasySlice.actions.updateUserPicks,
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState() as RootState
      const { draftPicks, leagueSettings, leagueKeepers, mdsEndingEarly, players } = state.fantasy.draftRoom

      // Can cancel other running instances
      listenerApi.cancelActiveListeners()

      // Guard against doing API calls if user has ended the draft early
      if (mdsEndingEarly) {
        return
      }

      const pickGrade = state.fantasy.draftRoom.playerPickGrades[action.payload] || 0
      const data = {
        allPlayers: players,
        draftedPlayers: draftPicks.concat(action.payload),
        leagueSettings: transformLeagueSettingsForMds(leagueSettings as MDSLeagueSettings, leagueKeepers),
      } as GetNextCpuPicksRequest

      listenerApi.dispatch(fantasySlice.actions.updateDraftRoomDrafting(true))
      listenerApi.dispatch(fantasySlice.actions.updateUserPicksPickGrades(pickGrade))
      listenerApi.dispatch(fantasySlice.actions.updateIsUserOnTheClock(false))
      listenerApi.dispatch(fantasySlice.actions.updateMdsDraftPicks(action.payload))
      listenerApi.dispatch(fantasyLdaMdsApi.endpoints.getNextCpuPicks.initiate({ body: data }))
    },
  })
}
