/**
 * 🚨🚨🚨
 * DO NOT ADD OR EDIT THESE WITHOUT THE EXPLICIT PERMISSION OF EITHER ANDY BREEN OR MIKE SPITZ
 * 🚨🚨🚨
 */

export type OpacityType = typeof Opacity
export enum Opacity {
  transparent = 0,
  semiTransparent = 0.5,
  partiallyOpaque = 0.8,
  onHover = 0.65,
  opaque = 1,
}
