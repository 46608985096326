import { colors, colorPalette } from '@pff-consumer/core-ui'

export const combinedColors = {
  ...colors,
  backgroundColor: colorPalette.white,
  barChartAxis: colorPalette.gray200,
  barChartLegend: colorPalette.gray900,
  barChartPathBase: colorPalette.gray100,
  barChartPathLoser: colorPalette.gray300,
  barChartPathWinner: colorPalette.green300,
  borderColor: colorPalette.gray100,
  buttonInverseTextColor: colorPalette.white,
  clickableBackgroundColor: colorPalette.gray100,
  clickableActiveBackground: colorPalette.blue100,
  darkSeparator: colorPalette.gray800,
  inactiveTabColor: colorPalette.gray500,
  inverseBackgroundColor: colorPalette.black,
  inverseTextColor: colorPalette.gray1000,
  modalCloseIconFill: colorPalette.black,
  matchupActivePillBackground: colorPalette.blue300,
  matchupActivePillBorder: colorPalette.blue300,
  matchupActivePillText: colorPalette.white,
  matchupBadgeBackground: colorPalette.gray100,
  matchupBarFirstGradientColor: colorPalette.gray200,
  matchupBorderColor: colorPalette.gray200,
  matchupInactivePillBorder: colorPalette.gray900,
  matchupInactivePillText: colorPalette.gray900,
  matchupPositionBackground: colorPalette.gray100,
  matchupTextIcon: colorPalette.gray1000,
  pillBorderColor: colorPalette.gray600,
  pillSelectedBackgroundColor: colorPalette.blue100,
  playerExtraInfoTextColor: colorPalette.gray800,
  playerHeadshotBackground: colorPalette.gray300,
  playerPositionTextColor: colorPalette.gray800,
  playerSearchClearTextColor: colorPalette.gray400,
  playerTagBackgroundColor: colorPalette.white,
  playerTagBorderColor: colorPalette.gray800,
  playerTagFillColor: colorPalette.gray800,
  playerPositionBorderColor: colorPalette.gray800,
  positionQuickFilter: colorPalette.gray900,
  positionQuickFilterSelected: colorPalette.blue300,
  rankCardPlayerGradeDescriptionColor: colorPalette.gray600,
  rankCardPlayerGradeValueColor: colorPalette.gray900,
  rankCardSortBackgroundColor: colorPalette.white,
  secondaryTextColor: colorPalette.gray800,
  sortIconColor: colorPalette.black,
  suggestionBackground: colorPalette.blue100,
  textColor: colorPalette.black,
  textLink: colorPalette.blue300,
  toggleOffBackgroundColor: colorPalette.gray600,
  toggleOnBackgroundColor: colorPalette.blue300,
  primaryButtonBackground: colorPalette.blue300,
  primaryButtonBorder: colorPalette.blue300,
  primaryButtonColor: colorPalette.white,
  primaryButtonBackgroundActive: colorPalette.blue400,
  primaryButtonBorderActive: colorPalette.blue400,
  primaryButtonColorActive: colorPalette.white,
  secondaryButtonBackground: 'transparent',
  secondaryButtonBorder: colorPalette.blue300,
  secondaryButtonColor: colorPalette.blue300,
  secondaryButtonBackgroundActive: 'transparent',
  secondaryButtonBorderActive: colorPalette.blue400,
  secondaryButtonColorActive: colorPalette.blue400,
  tertiaryButtonBackground: 'transparent',
  tertiaryButtonBorder: 'transparent',
  tertiaryButtonColor: colorPalette.blue300,
  tertiaryButtonBackgroundActive: 'transparent',
  tertiaryButtonBorderActive: 'transparent',
  tertiaryButtonColorActive: colorPalette.blue400,
  abandonButtonBackground: 'transparent',
  abandonButtonBorder: colorPalette.red300,
  abandonButtonColor: colorPalette.red300,
  abandonButtonBackgroundActive: 'transparent',
  abandonButtonBorderActive: colorPalette.red400,
  abandonButtonColorActive: colorPalette.red400,
  formButtonBorder: colorPalette.gray800,
}
