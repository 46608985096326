import { DraftStrategyTypes } from '@pff-consumer/schema/fantasy-mds/draft-strategy-types'

export const getDraftStrategy = (lateQB: number, stacking: number, zeroRB: number) => {
  if (!lateQB && !stacking && !zeroRB) {
    return 'null'
  }

  const draftStrategyTypes: string[] = []

  if (lateQB) {
    draftStrategyTypes.push(DraftStrategyTypes.LATE_ROUND_QB)
  }
  if (stacking) {
    draftStrategyTypes.push(DraftStrategyTypes.QB_WR_TE_STACKING)
  }
  if (zeroRB) {
    draftStrategyTypes.push(DraftStrategyTypes.ZERO_RB)
  }

  return draftStrategyTypes.join(',')
}
