import { InjuryStatusAbbreviation } from '@pff-consumer/schema'

export const getInjuryStatusForMobile = (status: string | null) => {
  switch (status) {
    case InjuryStatusAbbreviation.DOUBTFUL:
      return 'D'
    case InjuryStatusAbbreviation.PROBABLE:
      return 'P'
    case InjuryStatusAbbreviation.QUESTIONABLE:
      return 'Q'
    default:
      return status
  }
}
