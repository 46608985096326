import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

export interface AppState {
  isInternetReachable: boolean
}
export const APP_STATE_KEY = 'app'

export const appAdapter = createEntityAdapter<AppState>()
export const initialAppState: AppState = appAdapter.getInitialState({
  isInternetReachable: true,
})

export const appSlice = createSlice({
  name: APP_STATE_KEY,
  initialState: initialAppState,
  reducers: {
    updateInternetStatus: (state, { payload }) => {
      state.isInternetReachable = payload
    },
  },
})

export const appReducer = appSlice.reducer
export const { updateInternetStatus } = appSlice.actions

export const getAppState = (rootState: { [x: string]: AppState }): AppState => {
  return rootState[APP_STATE_KEY]
}

export const appSelectors = {
  getInternetStatus: createSelector(getAppState, (state: AppState) => state.isInternetReachable),
}
