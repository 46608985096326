import { HostedSites, FantasyProvider, FantasyProviderConfig } from '@pff-consumer/schema'
import type { HostedSite } from '@pff-consumer/schema'

export const getFantasyProviderIconName = (hostedSite: HostedSite) => {
  switch (hostedSite) {
    case HostedSites.YAHOO:
      return FantasyProviderConfig[FantasyProvider.YAHOO].shortLogo
    case HostedSites.ESPN:
      return FantasyProviderConfig[FantasyProvider.ESPN].shortLogo
    case HostedSites.SLEEPER:
      return FantasyProviderConfig[FantasyProvider.SLEEPER].shortLogo
    default:
      return FantasyProviderConfig[FantasyProvider.MANUAL].shortLogo
  }
}
