import { PlayerPropKey, DetailedEventViewWidgetType } from '@pff-consumer/schema'

export const PLAYER_PROPS_FILTER_ORDER: PlayerPropKey[] = [
  'pass_yd',
  'pass_td',
  'pass_att',
  'pass_comp',
  'pass_int',
  'recv_rec',
  'recv_yd',
  'rush_att',
  'rush_yd',
  'rush_recv_yd',
  'any_time_td',
  'first_td',
  'two_plus_td',
  'three_plus_td',
  'most_pass_yd',
  'most_rush_yd',
  'first_team_td_scorer',
  'first_td_second_half',
  'last_td',
  'first_half_td',
  'second_half_td',
  'alt_pass_yd',
  'alt_rush_yd',
  'alt_recv_yd',
  'first_completion',
  'longest_pass',
  'longest_reception',
  'first_reception',
  'longest_rush',
  'first_rush_att',
  'pass_rush_yd',
  'sack',
  'kick_points',
]

export const PROPS_WITH_JUST_OVER: PlayerPropKey[] = ['alt_pass_yd', 'alt_rush_yd', 'alt_recv_yd']

export const PLAYER_PROPS_DISPLAY_ORDER: PlayerPropKey[] = [
  'recv_rec',
  'recv_yd',
  'rush_att',
  'rush_yd',
  'rush_recv_yd',
  'pass_comp',
  'pass_td',
  'pass_att',
  'pass_int',
  'pass_yd',
  'any_time_td',
  'first_td',
  'two_plus_td',
  'three_plus_td',
  'most_pass_yd',
  'most_rush_yd',
  'first_team_td_scorer',
  'first_td_second_half',
  'last_td',
  'first_half_td',
  'second_half_td',
  'alt_pass_yd',
  'alt_rush_yd',
  'alt_recv_yd',
  'first_completion',
  'longest_pass',
  'longest_reception',
  'first_reception',
  'longest_rush',
  'first_rush_att',
  'pass_rush_yd',
  'sack',
  'kick_points',
]

export const POPULAR_PROPS: PlayerPropKey[] = [
  'any_time_td',
  'first_td',
  'two_plus_td',
  'three_plus_td',
  'pass_td',
  'pass_yd',
  'rush_yd',
  'recv_yd',
  'alt_pass_yd',
  'alt_recv_yd',
  'alt_recv_yd',
]

export const TD_SCORER_PROPS: PlayerPropKey[] = [
  'any_time_td',
  'first_td',
  'two_plus_td',
  'three_plus_td',
  'first_team_td_scorer',
  'first_td_second_half',
  'last_td',
  'first_half_td',
  'second_half_td',
]

export const PASSING_PROPS: PlayerPropKey[] = [
  'pass_td',
  'pass_yd',
  'pass_att',
  'pass_comp',
  'pass_int',
  'most_pass_yd',
  'alt_pass_yd',
  'first_completion',
  'longest_pass',
  'pass_rush_yd',
]

export const RECEIVING_PROPS: PlayerPropKey[] = [
  'recv_rec',
  'recv_yd',
  'alt_recv_yd',
  'longest_reception',
  'first_reception',
]

export const RUSHING_PROPS: PlayerPropKey[] = [
  'rush_att',
  'rush_yd',
  'most_rush_yd',
  'alt_rush_yd',
  'longest_rush',
  'first_rush_att',
  'rush_recv_yd',
]

// TODO: Investigate how this can be removed for better code maintainability
export const PROPS_WITH_OVER_UNDER: PlayerPropKey[] = [
  'pass_yd',
  'pass_att',
  'pass_int',
  'pass_comp',
  'recv_rec',
  'recv_yd',
  'rush_yd',
  'rush_att',
  'rush_recv_yd',
  'pass_td',
  'pass_rush_yd',
  'longest_pass',
  'longest_rush',
  'longest_reception',
  'first_reception',
  'first_rush_att',
  'first_completion',
  'kick_points',
]

export const PLAYER_PROP_DISPLAY_TEXTS: { [key in PlayerPropKey]: { short: string; medium: string; long: string } } = {
  // TD Scorer Props in order
  any_time_td: {
    short: 'Any Time TD',
    medium: 'Any Time TD',
    long: 'Any Time Touchdown Scorer',
  },
  first_td: {
    short: '1st TD',
    medium: '1st TD',
    long: 'First Touchdown Scorer',
  },
  two_plus_td: {
    short: 'Score 2+ TD',
    medium: 'Score 2+ TD',
    long: 'To score 2+ Touchdowns',
  },
  three_plus_td: {
    short: 'Score 3+ TD',
    medium: 'Score 3+ TD',
    long: 'To score 3+ Touchdowns',
  },
  first_team_td_scorer: {
    short: '1st Team TD Scorer',
    medium: '1st Team TD Scorer',
    long: '1st Team Touchdown Scorer',
  },
  first_td_second_half: {
    short: '1st 2nd Half TD Scorer',
    medium: '1st 2nd Half TD Scorer',
    long: '1st 2nd Half Touchdown Scorer',
  },
  last_td: {
    short: 'Last TD Scorer',
    medium: 'Last TD Scorer',
    long: 'Last TD Scorer',
  },
  first_half_td: {
    short: 'Any Time 1st Half TD',
    medium: 'Any Time 1st Half TD',
    long: 'Any Time 1st Half TD Scorer',
  },
  second_half_td: {
    short: 'Any Time 2nd Half TD',
    medium: 'Any Time 2nd Half TD',
    long: 'Any Time 2nd Half TD Scorer',
  },

  // Passing Props in order
  pass_td: {
    short: 'Passing TDs',
    medium: 'Passing Touchdowns',
    long: 'Passing Touchdowns',
  },
  pass_yd: {
    short: 'Passing Yards',
    medium: 'Passing Yards',
    long: 'Passing Yards',
  },
  pass_att: {
    short: 'Pass Attempts',
    medium: 'Passing Attempts',
    long: 'Passing Attempts',
  },
  pass_comp: {
    short: 'Pass Completions',
    medium: 'Passing Completions',
    long: 'Passing Completions',
  },
  pass_int: {
    short: 'Interceptions',
    medium: 'Passing Interceptions',
    long: 'Passing Interceptions',
  },
  most_pass_yd: {
    short: 'Most Pass Yds',
    medium: 'Most Passing Yards',
    long: 'Most Passing Yards of Game',
  },
  alt_pass_yd: {
    short: 'Alt Pass Yds',
    medium: 'Alt Passing Yards',
    long: 'Alt Passing Yards',
  },
  first_completion: {
    short: 'First Completion',
    medium: 'First Completion',
    long: 'First Completion',
  },
  longest_pass: {
    short: 'Longest Pass',
    medium: 'Longest Pass',
    long: 'Longest Pass',
  },
  pass_rush_yd: {
    short: 'Pass+Rush',
    medium: 'Passing + Rushing',
    long: 'Passing + Rushing',
  },

  // Receiving Props in order
  recv_rec: {
    short: 'Receptions',
    medium: 'Receptions',
    long: 'Receptions',
  },
  recv_yd: {
    short: 'Receiving Yards',
    medium: 'Receiving Yards',
    long: 'Receiving Yards',
  },
  alt_recv_yd: {
    short: 'Alt Recv Yards',
    medium: 'Alt Receiving Yards',
    long: 'Alt Receiving Yards',
  },
  longest_reception: {
    short: 'Longest Reception',
    medium: 'Longest Reception',
    long: 'Longest Reception',
  },
  first_reception: {
    short: 'First Reception',
    medium: 'First Reception',
    long: 'First Reception',
  },

  // Rushing Props in order
  rush_att: {
    short: 'Rush Attempts',
    medium: 'Rushing Attempts',
    long: 'Rushing Attempts',
  },
  rush_yd: {
    short: 'Rush Yards',
    medium: 'Rushing Yards',
    long: 'Rushing Yards',
  },
  most_rush_yd: {
    short: 'Most Rush Yds',
    medium: 'Most Rushing Yards',
    long: 'Most Rushing Yards of Game',
  },
  alt_rush_yd: {
    short: 'Alt Rush Yds',
    medium: 'Alt Rushing Yards',
    long: 'Alt Rushing Yards',
  },
  first_rush_att: {
    short: 'First Rush Attempts',
    medium: 'First Rush Attempts',
    long: 'First Rush Attempts',
  },
  rush_recv_yd: {
    short: 'Rushing + Receiving',
    medium: 'Rushing and Receiving Yards',
    long: 'Rushing and Receiving Yards',
  },
  longest_rush: {
    short: 'Longest Rush',
    medium: 'Longest Rush',
    long: 'Longest Rush',
  },

  // Other Player Props that are not being ingested from Fanduel at the moment, atleast to FE
  kick_points: {
    short: 'Kicking Points',
    medium: 'Kicking Points',
    long: 'Kicking Points',
  },

  sack: {
    short: 'Sacks',
    medium: 'Defensive Sacks',
    long: 'Defensive Sacks',
  },
  tackle_assist: {
    short: 'Tackle + Assist',
    medium: 'Tackle and Assist',
    long: 'Tackle and Assist',
  },
}

export const WIDGET_PROPKEY_MAP: Record<DetailedEventViewWidgetType, PlayerPropKey[]> = {
  'TD Scorer Props': TD_SCORER_PROPS,
  'Passing Props': PASSING_PROPS,
  'Receiving Props': RECEIVING_PROPS,
  'Rushing Props': RUSHING_PROPS,
  Popular: POPULAR_PROPS,
}

export const SINGLE_MARKET_PAGE_SIZE = 5
export const OVER_UNDER_MARKET_PAGE_SIZE = 10
