import { chain, partition } from 'lodash'
import { PlayerProp, PlayerPropBetsGroup, PlayerPropKey, PlayerPropBet } from '@pff-consumer/schema'
import { getPlayerPropName } from './get-player-prop-name'
import { PROPS_WITH_OVER_UNDER, PLAYER_PROPS_DISPLAY_ORDER, PROPS_WITH_JUST_OVER } from './player-prop-constants'

export function convertPropBetToPlayerPropMarkets(playerProps: PlayerPropBet[]) {
  return chain(playerProps)
    .groupBy((propBet) =>
      PROPS_WITH_OVER_UNDER.includes(propBet.market) ? propBet.sportsbookMarketId : propBet.sportsbookOptionId
    )
    .map((betPair) => {
      if (betPair.length >= 1 && PROPS_WITH_OVER_UNDER.includes(betPair[0].market)) {
        const [[overBet], [underBet]] = partition(betPair, (bet) => bet.option === 'over')
        if (overBet && underBet) {
          return {
            eventId: underBet.eventId,
            playerId: underBet.playerId,
            overBet,
            underBet,
          }
        }
        return null
      }
      const singleBet = { ...betPair[0], value: parseFloat(betPair[0]?.value?.toString()) }

      return {
        eventId: singleBet.eventId,
        playerId: singleBet.playerId,
        singleBet,
      }
    })
    .compact()
    .orderBy(['playerId', 'singleBet.value'], ['desc', 'asc'])
    .value()
}

export function groupPropBetsByPropKey(playerPropBets: PlayerPropBetsGroup) {
  return chain(Object.entries(playerPropBets))
    .map(([propKey, { playerProps, ...rest }]) => {
      const playerPropMarket = propKey as PlayerPropKey
      const playerPropMarkets = convertPropBetToPlayerPropMarkets(playerProps)
      if (playerPropMarkets.length) {
        return {
          header: getPlayerPropName(playerPropMarket, 'long') || propKey,
          data: playerPropMarkets,
          market: playerPropMarket,
          ...rest,
        }
      }
      return null
    })
    .compact()
    .value()
    .slice()
    .filter((group) => group.header && group.header !== 'undefined')
    .sort((a, b) => PLAYER_PROPS_DISPLAY_ORDER.indexOf(a.market) - PLAYER_PROPS_DISPLAY_ORDER.indexOf(b.market))
}

export function formatOverUnderValue(propBet: Pick<PlayerProp, 'propKey' | 'option' | 'value'>): string {
  if ([...PROPS_WITH_OVER_UNDER, ...PROPS_WITH_JUST_OVER].includes(propBet.propKey)) {
    return `${propBet.option === 'under' ? 'U' : 'O'} ${propBet.value}`
  }

  return ''
}
