/**
 * By default redux toolkit/reselect only does a shallow comparison. That means that when we use arrays/objects we need
 * to create our own deep equal selector that does a deep comparison to prevent unnecessary re-renders.
 *
 * NB: The JSON.stringify pattern means that if you are storing any functions on objects in redux (pls no) then they
 * don't get checked
 */
import { createSelectorCreator, defaultMemoize } from 'reselect'
import { isEqual } from 'lodash'

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)
