import { FantasyPosition } from '@pff-consumer/schema/fantasy/fantasy'
import { LABEL_FANTASY_POSITION_DT } from '@pff-consumer/schema'
import { getLongName } from '../player/getLongName'
import { formatUpdatedTime } from '../format/format-date'

export const transformWeeklyRankingsResponse = (response: any, meta: any, arg: any = {}) => {
  const { scoringType } = arg

  // @ts-ignore
  const transformed = response.rankings.map((entry) => {
    const rankers = entry?.rankers?.[scoringType]

    const firstName = entry.position === FantasyPosition.DEF ? entry.city : entry.firstName
    const lastName = entry.position === FantasyPosition.DEF ? entry.name : entry.lastName

    return {
      first_name: firstName,
      last_name: lastName,
      // TODO verify if we start using this more - currently used to fix fuzzy search in start/sit
      long_name: getLongName(firstName, lastName),
      id: entry.playerId,
      position: entry.position === FantasyPosition.DT ? LABEL_FANTASY_POSITION_DT : entry.position,
      team: entry.teamAbbreviation || entry.abbreviation, // Players have teamAbbreviation, Defenses have abbreviation
      opposition: entry.nextOpponentAbbreviation,
      isHomeGame: entry.teamId === entry.homeTeamId,
      gameWeek: entry.nextGameWeek,
      matchTime: entry.nextGameTime,
      projection: entry.projection,
      matchup: entry.matchup,
      tags: entry.tags || [],
      rank: entry.rank,
      rankers,
      dob: entry.birthDate,
      bye: entry.byeWeek,
      height: entry.height,
      weight: entry.weight,
    }
  })

  return {
    lastUpdatedAt: formatUpdatedTime(response.lastUpdatedAt),
    currentGameWeek: response.currentGameWeek,
    rankings: transformed,
  }
}
