import { HostedSites } from './league'

export enum CurrentProgressState {
  IN_PROGRESS = 'progress-loading',
  SUCCESS = 'progress-success',
  ERROR = 'progress-error',
}
export enum FantasyProvider {
  SLEEPER = 'Sleeper',
  ESPN = 'ESPN',
  YAHOO = 'Yahoo!',
  MANUAL = 'Manual',
}

export const HostedSiteToFantasyProvider = {
  [HostedSites.SLEEPER]: FantasyProvider.SLEEPER,
  [HostedSites.ESPN]: FantasyProvider.ESPN,
  [HostedSites.YAHOO]: FantasyProvider.YAHOO,
  [HostedSites.NONE]: FantasyProvider.MANUAL,
}

type FantasyProviderConfigType = {
  [key: string]: {
    name: string
    logo: string
    shortLogo: string
    syncHeaderText: string
    syncExplainerText: string
    inputText: string
    placeholderExplainerText: string
    uid: string
    explainerImage?: string
    additionalText?: string
    required?: boolean
  }
}
export const FantasyProviderConfig: FantasyProviderConfigType = {
  [FantasyProvider.MANUAL]: {
    name: 'Manual',
    logo: 'manual-league-logo',
    shortLogo: 'manual-short-icon',
    syncHeaderText: 'Add Your League',
    syncExplainerText: `You can select from one of our pre-defined scoring systems.`,
    inputText: '',
    placeholderExplainerText: '',
    uid: 'league url',
  },
  [FantasyProvider.ESPN]: {
    name: 'ESPN',
    logo: 'espn-long-icon',
    shortLogo: 'espn-short-icon',
    syncHeaderText: 'Sync with ESPN',
    syncExplainerText: `Enter your ESPN League ID to sync your leagues.`,
    inputText: 'ESPN League URL',
    placeholderExplainerText: 'https://fantasy.espn.com/football/league?leagueId=123456789',
    uid: 'league url',
    required: true,
  },
  [FantasyProvider.YAHOO]: {
    name: 'Yahoo',
    logo: 'yahoo-long-icon',
    shortLogo: 'yahoo-short-icon',
    syncHeaderText: 'Sync with Yahoo',
    syncExplainerText: `Please enter your Yahoo league URL that ends with your league ID. Please see example below.`,
    inputText: 'Enter league URL',
    placeholderExplainerText: 'https://football.fantasysports.yahoo.com/f1/123456',
    uid: 'league url',
    explainerImage: 'images/yahoo-league-url.webp',
    additionalText:
      'If you have a custom league URL, please go into your Yahoo league settings to get your league id and follow the URL format above.',
    required: true,
  },
  [FantasyProvider.SLEEPER]: {
    name: 'Sleeper',
    logo: 'sleeper-long-icon',
    shortLogo: 'sleeper-short-icon',
    syncHeaderText: 'Sleeper Fantasy Football',
    syncExplainerText: 'Enter your sleeper username.',
    inputText: 'Sleeper username',
    placeholderExplainerText: 'Your Sleeper username',
    uid: 'username',
  },
}
