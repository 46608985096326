import { Styles } from 'react-jss'
import { generateWebFontFaces } from './fonts'
import { NORMALIZE } from './normalize'

export const generateWebUiGlobalStyles = ({ fontUrlPath }: { fontUrlPath: string }): Styles => ({
  '@global': {
    ...NORMALIZE,
    ...generateWebFontFaces(fontUrlPath),
  },
})
