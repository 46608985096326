import type { ConnectInsight, FootballPlayer, GameScript } from '@pff-consumer/schema'

export function getInsightWithPlayer(connectInsights: ConnectInsight[], players: FootballPlayer[]) {
  // Prepare Player information player insights
  const playerIdMap = players.reduce(
    (acc: Record<number, FootballPlayer>, player) => ({ ...acc, [player.playerId]: player }),
    {}
  )

  const playerInsights = connectInsights.map((insights) => ({
    ...insights,
    player: playerIdMap[insights.playerId],
  }))

  return { playerInsights, players }
}

export function getGameScriptWithPlayer(connectInsights: GameScript[], players: FootballPlayer[]) {
  // Prepare Player information player insights
  const playerIdMap = players.reduce(
    (acc: Record<number, FootballPlayer>, player) => ({ ...acc, [player.playerId]: player }),
    {}
  )

  const keyInsights = connectInsights.map((insights) => ({
    ...insights,
    player: {
      ...playerIdMap[insights.players[0].playerId],
      teamAbbreviation: insights.players[0].playerTeam,
    },
  }))

  return { keyInsights, players }
}
