import { LiveDraftPicksStore } from '@pff-consumer/schema'
import { Keeper } from '@pff-consumer/schema/fantasy-live-draft/keeper'

import { getLatestLiveDraftPickWithoutKeepers } from './get-latest-live-draft-pick-without-keepers'

export const getCurrentLiveDraftRoundAndPickNumber = (
  picks: LiveDraftPicksStore,
  keepers: Keeper[],
  noOfTeamsInTheLeague: number
) => {
  const keeperPicks = [...keepers]

  const latestPick = getLatestLiveDraftPickWithoutKeepers(picks, keepers)
  const keeperPickNumbers = keeperPicks.map((players) => players.pickNumber)

  let nextLiveDraftPick = 1
  if (latestPick && latestPick.draftPick) {
    nextLiveDraftPick = latestPick.draftPick + 1
  }

  // if nextLiveDraftPick is keeper pick then move to keeper pick + 1
  if (keeperPickNumbers.length && keeperPickNumbers.includes(nextLiveDraftPick)) {
    nextLiveDraftPick += 1
  }

  const currentRoundPickNumber = nextLiveDraftPick % noOfTeamsInTheLeague || noOfTeamsInTheLeague
  let liveDraftCurrentRound = Math.floor(nextLiveDraftPick / noOfTeamsInTheLeague) + 1
  const isLastPickOfTheRound = nextLiveDraftPick % noOfTeamsInTheLeague === 0
  if (isLastPickOfTheRound) {
    liveDraftCurrentRound -= 1
  }

  return { currentRoundPickNumber, liveDraftCurrentRound }
}
