/**
 * 🚨🚨🚨🚨🚨🚨🚨🚨
 * DO NOT ADD OR CHANGE THESE VALUES. THESE ARE NOT FOR ENGINEERING TO MODIFY. DO NOT REFERENCE THESE DIRECTLY WITHIN THE COMPONENTS/STYLES
 *
 * ONLY CHANGE/MODIFY THESE WITH EXPLICIT PERMISSION FROM DESIGN. THIS SHOULD ONLY HAPPEN WHEN THEY TELL US THE COLOR SCHEME IS CHANGING.
 * 🚨🚨🚨🚨🚨🚨🚨🚨
 */
type GrayPalette =
  | 'gray100'
  | 'gray200'
  | 'gray300'
  | 'gray400'
  | 'gray500'
  | 'gray600'
  | 'gray700'
  | 'gray800'
  | 'gray900'
  | 'gray1000'
type YellowPalette = 'yellow100' | 'yellow200' | 'yellow300' | 'yellow400' | 'yellow500'
type RedPalette = 'red100' | 'red200' | 'red300' | 'red400' | 'red500'
type BluePalette = 'blue100' | 'blue200' | 'blue300' | 'blue400' | 'blue500'
type CyanPalette = 'cyan100' | 'cyan200' | 'cyan300' | 'cyan400' | 'cyan500'
type GreenPalette = 'green100' | 'green200' | 'green300' | 'green400' | 'green500'
type OrangePalette = 'orange100' | 'orange200' | 'orange300' | 'orange400' | 'orange500'
type WhitePalette = 'white'
type BlackPalette = 'black'

export type ColorPalette = GrayPalette &
  YellowPalette &
  RedPalette &
  BluePalette &
  CyanPalette &
  GreenPalette &
  WhitePalette &
  BlackPalette &
  OrangePalette

const whites: Record<WhitePalette, string> = {
  white: '#FFFFFF',
}

const blacks: Record<BlackPalette, string> = {
  black: '#000000',
}

const grays: Record<GrayPalette, string> = {
  gray100: '#EDEDED',
  gray200: '#E1E1E1',
  gray300: '#D3D3D3',
  gray400: '#C4C4C4',
  gray500: '#B3B3B3',
  gray600: '#A0A0A0',
  gray700: '#898989',
  gray800: '#6C6C6C',
  gray900: '#3F3F3F',
  gray1000: '#262626',
}

const yellows: Record<YellowPalette, string> = {
  yellow100: '#FFF4D4',
  yellow200: '#FFDD7F',
  yellow300: '#FFC629',
  yellow400: '#997719',
  yellow500: '#332808',
}

const reds: Record<RedPalette, string> = {
  red100: '#FCE9E7',
  red200: '#F19387',
  red300: '#E2260E',
  red400: '#711307',
  red500: '#170401',
}

const blues: Record<BluePalette, string> = {
  blue100: '#D1E1FF',
  blue200: '#74A5FF',
  blue300: '#1769FF',
  blue400: '#0E3F99',
  blue500: '#051533',
}

const cyans: Record<CyanPalette, string> = {
  cyan100: '#DAF4F7',
  cyan200: '#8FDEE7',
  cyan300: '#44C8D7',
  cyan400: '#297881',
  cyan500: '#0E282B',
}

const greens: Record<GreenPalette, string> = {
  green100: '#D0F3E1',
  green200: '#71DCA6',
  green300: '#12C56A',
  green400: '#0B7640',
  green500: '#042715',
}

const orange: Record<OrangePalette, string> = {
  orange100: '#FFDCCC',
  orange200: '#FF9766',
  orange300: '#FF5100',
  orange400: '#993100',
  orange500: '#331000',
}

export const colorPalette = {
  ...whites,
  ...blacks,
  ...grays,
  ...yellows,
  ...reds,
  ...blues,
  ...cyans,
  ...greens,
  ...orange,
}
