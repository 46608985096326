import { DraftTypes } from '@pff-consumer/schema'

export function getDraftTypeExplainer(type: string) {
  switch (type) {
    case DraftTypes.SNAKE:
      return 'Snake order means each round the order reverses.'
    case DraftTypes.LINEAR:
      return 'Linear order means the order remains the same each round.'
    case DraftTypes.THIRD_ROUND_REVERSAL:
      return '3RR order means the order of the draft reverses at the start of the 3rd round and continues as a regular snake draft.'
    default:
      return ''
  }
}
