export enum ProgressTypes {
  COMPLETE = 'complete',
  SETTINGS = 'settings',
  DRAFTERS = 'drafters',
  DRAFTING = 'drafting',
}
export type ProgressType = `${ProgressTypes}`

export interface SavedDraftJson {
  players: DraftPlayer[]
  teams: DraftTeam[]
}

export interface SavedDraft {
  id: number
  league_id: number
  name: string
  json: SavedDraftJson | null
  draft_order: number
  adp_source_name: string | null
  ranking_source_name: string | null
  ranker_name: string | null
  progress: ProgressType
  created_at?: string
  updated_at?: string
}

export interface SavedDraftUpsert {
  league_id: number
  draft_order: number
  adp_source_name?: string
  ranking_source_name?: string
  ranker_name?: string
  name: string
}

export interface DraftPlayer {
  bye_week?: number
  drafter_name: string
  pff_ranking?: number
  pick_number: number
  player_id: string
  player_name?: string
  position?: string
  round_number?: number
  slug?: string
  team_name?: string
}

export interface DraftTeam {
  name: string
  draft_order: number
  roster?: DraftPlayer[]
}

export interface SavedDraftResult {
  snake: boolean
  rounds: number
  num_keepers: number
  draft_order: number
  teams: DraftTeam[]
}

export interface SavedDraftAPIResponseSettingsOrComplete {
  saved_draft: SavedDraft
  keepers_entered: boolean
}

export interface UpdateSavedDraftSettings
  extends Pick<SavedDraft, 'ranker_name' | 'ranking_source_name' | 'adp_source_name'> {
  progress?: ProgressType
}
export interface UpdateSavedDraftJson {
  players?: DraftPlayer[]
  teams?: DraftTeam[]
}
