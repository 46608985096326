import type { FeatureFlagLensType, UseFeatureFlagHookResponse } from './shared/feature-flag-types'

const featureFlagModule: {
  FeatureFlagLens: FeatureFlagLensType
  useFeatureFlags: () => UseFeatureFlagHookResponse
  withFeatureFlagProvider: () => (a: any) => any
} = require('./feature-flag-module')

export const launchDarklyClient =
  'launchDarklyClient' in featureFlagModule ? featureFlagModule.launchDarklyClient : undefined
export const { useFeatureFlags } = featureFlagModule
export const { FeatureFlagLens } = featureFlagModule
export const { withFeatureFlagProvider } = featureFlagModule
