export const accountEndpoints = {
  signIn: () => {
    return { url: `/accounts/v3/login` }
  },
  recaptchaSignIn: () => {
    return { url: `/accounts/v2/login` }
  },
  createAccount: () => {
    return { url: `/accounts/v3/signup` }
  },
  recaptchaCreateAccount: () => {
    return { url: `/accounts/v2/signup` }
  },
  user: () => {
    return { url: `/accounts/v3/user` }
  },
  appUser: ({ uid }: { uid: string }) => {
    return { url: `/accounts/v1/subscription/status/${uid}` }
  },
  apple: () => {
    return { url: `/accounts/v1/login/apple` }
  },
  device: () => {
    return { url: `/accounts/v1/device` }
  },
  answerSurvey: ({ surveyId }: { surveyId: string }) => {
    return { url: `/accounts/v1/survey/${surveyId}/answer` }
  },
  activateSubscription: () => {
    return { url: `/accounts/v3/subscribe` }
  },
  checkout: () => {
    return { url: `/accounts/v1/checkout` }
  },
  complete: () => {
    return { url: `/accounts/v1/subscribe/complete` }
  },
  getToken: () => {
    return { url: `accounts/v1/user/token` }
  },
  cancelSubscription: () => {
    return { url: `/accounts/v1/user/subscription/cancel` }
  },
  getSubscriptionDetails: () => {
    return { url: `/accounts/v1/user/subscription/details` }
  },
}
