import { InjuryStatus, InjuryStatusAbbreviationMap, PlayersInjuryRecord } from '@pff-consumer/schema'

export const addPlayerInjuryStatusToWeeklyRankingsData = (
  rankings: any[],
  playersInjuryStatusMap: PlayersInjuryRecord
) => {
  if (rankings.length === 0 || Object.entries(playersInjuryStatusMap).length === 0) {
    return rankings
  }

  // Map injury status to weekly rankings using the playerStatusMap
  return rankings.map((player) => {
    const playerData = { ...player }
    const status = playersInjuryStatusMap[playerData.id] || InjuryStatus.Active
    playerData.status = InjuryStatusAbbreviationMap[status]
    return playerData
  })
}
