export interface SeasonStat {
  statKey: string
  statValue: number
  rank: number
  rankTotal: number
}

export interface PlayerSeasonStat {
  playerId: number
  firstName: string
  lastName: string
  position: string
  teamId: number
  teamAbbreviation: string
  stats: SeasonStat[]
}

export enum SeasonStatPosition {
  QB = 'QB',
  RB = 'RB',
  HB = 'HB',
  FB = 'FB',
  WR = 'WR',
  TE = 'TE',
  T = 'T',
  G = 'G',
  C = 'C',
  DI = 'DI',
  ED = 'ED',
  LB = 'LB',
  CB = 'CB',
  S = 'S',
  K = 'K',
  P = 'P',
}
