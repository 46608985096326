import { colors } from '@pff-consumer/core-ui'

const GRADE_UPPER_RANGE = 80
const GRADE_MIDDLE_RANGE = 65
const GRADE_LOWER_RANGE = 50

export function getGradeRange(grade: number) {
  if (grade < GRADE_LOWER_RANGE)
    return { color: colors.playerGradePoor, backgroundColor: colors.playerGradePoorBackground }
  if (grade === GRADE_LOWER_RANGE || (grade > GRADE_LOWER_RANGE && grade < GRADE_MIDDLE_RANGE))
    return { color: colors.playerGradeAverage, backgroundColor: colors.playerGradeAverageBackground }
  if (grade === GRADE_MIDDLE_RANGE || (grade > GRADE_MIDDLE_RANGE && grade < GRADE_UPPER_RANGE))
    return { color: colors.playerGradeGood, backgroundColor: colors.playerGradeGoodBackground }
  return { color: colors.playerGradeGreat, backgroundColor: colors.playerGradeGreatBackground }
}
