import type { Analytics } from '../shared/analytics-types'
import { initializeAnalytics } from './initialize-analytics'
import { sendAnalyticsEvent } from './send-analytics-event'
import { addUser } from './add-user-properties'
import { removeUser } from './remove-user'

export const useAnalytics = () => {
  const analytics: Analytics = {
    initializeAnalytics,
    sendAnalyticsEvent,
    addAnalyticsUser: addUser,
    removeAnalyticsUser: removeUser,
  }

  return analytics
}
