import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

export const BOTTOM_DRAWER_KEY = 'bottomdrawer'
type BottomDrawerSubscriptWorkflow = 'betting'

export interface BottomDrawerState {
  isOpen: boolean
  subscriptionWorkflow?: BottomDrawerSubscriptWorkflow
}

export interface OpenBottomDrawerPayload {
  subscriptionWorkflow?: BottomDrawerSubscriptWorkflow
}

export const bottomDrawerAdapter = createEntityAdapter<BottomDrawerState>()

export const initialBottomDrawerState: BottomDrawerState = bottomDrawerAdapter.getInitialState({
  isOpen: false,
  subscriptionWorkflow: undefined,
})

export const bottomDrawerSlice = createSlice({
  name: BOTTOM_DRAWER_KEY,
  initialState: initialBottomDrawerState,
  reducers: {
    openBottomDrawer: (state, { payload }: { payload: OpenBottomDrawerPayload }) => {
      state.isOpen = true
      state.subscriptionWorkflow = payload.subscriptionWorkflow
    },
    closeBottomDrawer: (state) => {
      state.isOpen = false
      state.subscriptionWorkflow = undefined
    },
  },
})

export const bottomDrawerReducer = bottomDrawerSlice.reducer
export const bottomDrawerActions = bottomDrawerSlice.actions

export const getbottomDrawerState = (rootState: { [x: string]: BottomDrawerState }): BottomDrawerState => {
  return rootState[BOTTOM_DRAWER_KEY]
}

export const bottomDrawerSelectors = {
  getIsOpen: createSelector(getbottomDrawerState, (state: BottomDrawerState) => state.isOpen),
  getSubscriptionWorkflow: createSelector(
    getbottomDrawerState,
    (state: BottomDrawerState) => state.subscriptionWorkflow
  ),
}
