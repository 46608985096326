export interface QbAction {
  reset: boolean
  play_id: number
  dropback_depth: number
  time_to_pressure: number
  time_to_throw: number
  dropback_type: string
  moved: boolean
  scramble_drill: boolean
  pump: boolean
  play_action: boolean
  scramble_position_type: string
}

export interface PlaySpot {
  left: number
  yard_line: number
}

export interface PlayDetail {
  rpo: boolean
  play_id: number
  defensive_line_shift: boolean
  four_minute_drill: boolean
  garbage_time: boolean
  is_fake: boolean
  is_last_play_of_game: boolean
  is_base_last_play_of_half?: boolean
  is_player_participation_last_play_of_half?: boolean
  is_onside_conversion_attempt: boolean
  lined_up_as: string
  net_yards: number
  no_huddle: boolean
  no_play: boolean
  notes: any
  notes_resolved: boolean
  play_led_to_type: any
  play_type: string
  player_participation_motion: boolean
  player_participation_notes: any
  player_participation_video_missing: boolean
  quarterback_position_type: string
  regular_play_type: string
  sideline_check: boolean
  special_play_type: any
  tempo: boolean
  three_minute_drill: boolean
  trick_look: boolean
  trick_play: boolean
  two_minute_drill: boolean
  video_missing: boolean
  yards: number
  spot: PlaySpot
}

export interface PassResult {
  play_id: number
  yards_after_contact: number | null
  yards_before_contact: number | null
  yards_in_air: number | null
  yards_after_catch: number | null
  intended_receiver_player_id: number | null
  primary_coverage_player_id: number | null
  secondary_coverage_player_id: number | null
  passer_player_id: number | null
  pass_is: string
  catchable: boolean | null
  incompletion_type: string | null
  route_type: string | null
  turn_of_center: string | null
  screen: boolean | null
  first_contact: PlaySpot
  busted: boolean | null
  hail_mary: boolean | null
  play_action_concept: string | null
  launch: PlaySpot
  target_point: PlaySpot
  tackle: PlaySpot
  missed_tackle: PlaySpot
}

export interface RunResult {
  actual_run_position_type: string
  blocked: boolean
  first_contact: {
    left: number
    yard_line: number
  }
  intended_run_position_type: string
  missed_tackle: {
    left: number
    yard_line: number
  }
  play_id: number
  rb_direction: string
  run_position_change_reason_type: string
  runner_player_id: number
  tackle: {
    left: number
    yard_line: number
  }
  yards_after_contact: number
  yards_before_contact: number
}

export interface Timeout {
  id: number
  reason: string
  sort_order: number
  team_id: number | null
  play_id: number
}

export interface AdvancedPlayerParticipation {
  depth: number
  id: number
  interim_depth: number | null
  interim_width: number | null
  is_reviewed: boolean
  motion: boolean
  motion_type: string | null
  player_participation_id: number
  starting_depth: number | null
  starting_width: number | null
  width: number | null
}

export interface PlayerRoute {
  all_route_type: string
  chip_block: boolean
  fake: string | null
  fake_screen: boolean
  fell_down: boolean
  flea_flicker: boolean
  id: number
  no_route: boolean
  pass_block: string | null
  player_participation_id: number
  release: string | null
  route_depth: number
  rub_route: boolean
  run_play: boolean
  screen_block: boolean
}

export interface PlayerParticipation {
  advanced_player_participation: AdvancedPlayerParticipation | null
  assumed_participant: boolean
  bunched: boolean
  creeper: boolean
  double_teamed: boolean
  double_teamer: boolean
  id: number
  injured: boolean
  is_reviewed: boolean
  line_of_scrimmage: boolean
  off_def: 'O' | 'D'
  play_id: number
  player_blockings: any[]
  player_coverage: any
  player_id: number
  player_role_type: string
  player_role_type_id: number
  player_route: PlayerRoute | null
  position: string
  press_coverage: boolean
  punt_recovery_eligible: boolean
  release: string | null
  roster_id: number
  stance: number
  team_id: number
}

export interface GameScore {
  away_team: number
  home_team: number
}

export enum Down {
  FirstDown = '1',
  SecondDown = '2',
  ThirdDown = '3',
  FourthDown = '4',
  Kick = 'K',
  PointAfterTouchdown = 'PAT',
  TwoPointConversion = '2PT',
}

export enum ScoringType {
  Touchdown = 'TD',
  FieldGoal = 'FG',
  PointAfter = 'PAT',
  TwoPointConversion = '2PT',
  Safety = 'S',
}

export interface Scoring {
  play_id: number
  points: number
  scorer_player_id: number
  team_id: number
  type: ScoringType
}

export enum PatType {
  KickGood = 'KG',
  KickNoGood = 'KN',
  PassGood = 'PG',
  PassNoGood = 'PN',
  RunGood = 'RG',
  RunNoGood = 'RN',
}

export interface PointAfterTouchdown {
  blocker_player_id: number | null
  caught_on: {
    left: number | null
    yard_line: number | null
  }
  first_contact: {
    left: number | null
    yard_line: number | null
  }
  kick_team_recovered_player_id: number | null
  kicker_player_id: number | null
  outcome: unknown
  play_id: number
  point_decision: unknown
  returned_to: {
    left: number | null
    yard_line: number | null
  }
  returned_yards: number
  returner_player_id: number | null
  sub_type: string
  successful: boolean
  type: PatType
}

export enum PenaltyEnforcement {
  'Offset' = 'OFF',
  'Accepted' = 'ACC',
  'Declined' = 'DEC',
  'Superceded' = 'SUP',
  'Uncalled' = 'UNC',
}

interface PenaltyOfficial {
  id: number
  official_type: string
  penalty_id: number
  sort_order: number
}

export interface Penalty {
  caused_foul_player_id: number
  ejected: boolean
  id: number
  penalty_enforcement: PenaltyEnforcement
  penalty_grade: number
  penalty_officials: PenaltyOfficial[]
  penalty_post_snap: boolean
  penalty_targeting: string
  penalty_type: string
  play_id: number
  player_id: number
  team_id: number
  yards: number
}

export interface Fumble {
  fumbled_on: {
    left: number
    yard_line: number
  }
  fumbler_player_id: number
  fumbler_team_id: number
  last_touched_by: boolean
  last_touched_by_player_id: number
  last_touched_by_team_id: number
  play_id: number
  recovered_on: {
    left: number
    yard_line: number
  }
  recovered_player_id: number
  recovered_team_id: number
  returned_to: {
    left: number
    yard_line: number
  }
  returned_yards: number
  sort_order: number
}

export interface Lateral {
  player_id: number
  yards: number
}

export interface ContestedTarget {
  id: number
  play_id: number
  player_id: number
  sort_order: number
}

export interface PlayGrade {
  away_grade: number | null
  away_play_type: string | null
  away_player_id: number
  comment: string
  home_grade: number
  home_play_type: string | null
  home_player_id: number
  id: number
  play_id: number
}

export interface FootballPlay {
  id: number
  down: number
  timeouts: Timeout[]
  sequence: number
  group_id: string
  quarter: number
  score: GameScore | null
  game_id: number
  play: number
  penalties: Penalty[]
  grades: PlayGrade[]
  gsis_id: number
  sportradar_id: string
  xfl_id: any
  qb_charting: any
  play_detail: PlayDetail
  challenges: any[]
  qb_action: QbAction
  pass_result?: PassResult
  run_result?: RunResult
  run_concepts: any[]
  laterals: Lateral[]
  sacks: any[]
  open_field_kicks: any[]
  fumbles: Fumble[]
  gameclock: number
  play_clock: number
  possession_team_id: number
  fieldposition: number
  contested_targets: ContestedTarget[]
  distance: number
  drive: number
  stopped_clock: boolean
  possession_team: { id: number }
  player_participations?: PlayerParticipation[]
  scoring?: Scoring
  pat?: PointAfterTouchdown
}
