import { ScoringTypes } from '@pff-consumer/schema/fantasy-mds/scoring-types'

export const getScoringType = (reception: number | undefined) => {
  // Type casted to number due to inconsistent data types with "state.draftRoom.selectedSyncedLeague.leagueSettings?.recv_receptions1"
  const pointsPerReception = Number(reception)

  if (pointsPerReception === 0) {
    return ScoringTypes.NON_PPR
  }
  if (pointsPerReception === 0.5) {
    return ScoringTypes.HALF_PPR
  }

  return ScoringTypes.PPR
}
