import { FantasyPosition } from '@pff-consumer/schema'
import { translateAvailabilityPercentage } from '../translate-availability-percentage'

const getIndex = (playerIndex: number) => {
  switch (playerIndex) {
    case 2:
      return 'second '
    case 3:
      return 'third '
    case 4:
      return 'fourth '
    default:
      return ''
  }
}

const isLastPickInSecondLastRoundInSnakeDraft = (
  draftRounds: number,
  pickNumber: number,
  currentDraftRound: number,
  fantasyPlayersInLeague: number
): boolean => {
  const isSecondLastRound = currentDraftRound === draftRounds - 1
  const isLastPickInTheRound = pickNumber % fantasyPlayersInLeague === 0

  return isSecondLastRound && isLastPickInTheRound
}

const getDescription = (playerNameToUse: string, index: string, points: number, position: string) => {
  if (points > 0) {
    return `${playerNameToUse} is the ${index}best option with your current pick. ${playerNameToUse} is projected to score ${points} more points than the projected available options at ${position} in the next round.`
  }
  return `${playerNameToUse} is the ${index}best option with your current pick.`
}

export const getPlayerDescription = (
  lastName: string,
  position: string,
  team: string,
  points: number,
  playerIndex: number,
  nextPlayerName: string,
  currentPlayerAvailability: number,
  nextPlayerAvailability: number,
  pickNumber: number,
  draftRounds: number,
  currentDraftRound: number,
  fantasyPlayersInLeague: number,
  isLastRound: boolean
) => {
  const playerNameToUse = position === FantasyPosition.DST ? `${team} DST` : lastName
  const index = getIndex(playerIndex + 1)
  const playerAvailabilityText = translateAvailabilityPercentage(currentPlayerAvailability)
  const nextPlayerAvailabilityText = translateAvailabilityPercentage(nextPlayerAvailability)

  const nextRoundDescription = `There is ${playerAvailabilityText}% chance ${playerNameToUse} will be available the next round. If you choose ${playerNameToUse}, PFF projects that your best pick in the next round will be ${nextPlayerName}, who has a ${nextPlayerAvailabilityText}% chance of being available.`
  const shortenedDescription = `${playerNameToUse} is projected to contribute ${points} points to your team`

  const isLastPickInSecondLastRound = isLastPickInSecondLastRoundInSnakeDraft(
    draftRounds,
    pickNumber,
    currentDraftRound,
    fantasyPlayersInLeague
  )

  if (isLastPickInSecondLastRound || isLastRound) {
    return shortenedDescription
  }

  if (nextPlayerName === '') {
    return `${getDescription(playerNameToUse, index, points, position)}`
  }
  return `${getDescription(playerNameToUse, index, points, position)} ${nextRoundDescription}`
}
