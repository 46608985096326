import type { PositionGrade } from '@pff-consumer/schema/player-grade'
import { getOrdinalNumber } from '../stats/getOrdinalNumber'
import { getPositionFullName } from './get-position-full-name'

export function getPlayerGradeToolTipText(playerName: string, position: string, playerGrade?: PositionGrade) {
  const positionName = getPositionFullName(position)
  if (!playerGrade || !positionName) return undefined
  const { gradeRank, gradeRankTotal, facet, gradeValue } = playerGrade
  // Make the position text generic
  return `${playerName} has a ${facet} grade of ${gradeValue}, ranked ${getOrdinalNumber(
    gradeRank
  )} out of ${gradeRankTotal} ${positionName}s`
}
