import type { IncomingMessage } from 'http'
import { getCountryFromIp } from './get-country-from-ip'

const US_COUNTRY_CODE = 'US'

export async function getIsUSUser(req: IncomingMessage | undefined) {
  const forwarded = req?.headers['x-forwarded-for']
  const ip = typeof forwarded === 'string' ? forwarded.split(/, /)[0] : req?.socket.remoteAddress
  const country = await getCountryFromIp(ip)
  return country ? country === US_COUNTRY_CODE : false
}
