import { FantasyDisplayPositionLabels, type FantasyDisplayPositionKey, type DropdownOption } from '@pff-consumer/schema'
import { getOptions } from './get-options'

export const getPositionDropdownOptions = (position: string): DropdownOption[] => {
  switch (FantasyDisplayPositionLabels[position as FantasyDisplayPositionKey]) {
    case FantasyDisplayPositionLabels.QB: {
      return getOptions(2, false)
    }
    case FantasyDisplayPositionLabels.RB: {
      return getOptions(3, false)
    }
    case FantasyDisplayPositionLabels.WR: {
      return getOptions(4, false)
    }
    case FantasyDisplayPositionLabels.TE: {
      return getOptions(2, false)
    }
    case FantasyDisplayPositionLabels.FLEX: {
      return getOptions(3, true)
    }
    case FantasyDisplayPositionLabels.REC_FLEX: {
      return getOptions(4, true)
    }
    case FantasyDisplayPositionLabels.WRRB_FLEX: {
      return getOptions(3, true)
    }
    case FantasyDisplayPositionLabels.SUPERFLEX: {
      return getOptions(2, true)
    }
    case FantasyDisplayPositionLabels.DST: {
      return getOptions(2, true)
    }
    case FantasyDisplayPositionLabels.K: {
      return getOptions(2, true)
    }
    default:
      return []
  }
}
