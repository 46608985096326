export type Fonts = 'Avenir' | 'PFF Sans' | 'Roboto' | 'Barlow' | 'Roboto Condensed'

export type FontWeight = 'normal' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'
export type FontStyle = 'normal' | 'italic'

export const PRIMARY_FONT: Fonts = 'Avenir'
export const SECONDARY_FONT: Fonts = 'PFF Sans'
export const WEB_PRIMARY_FONT: Fonts = 'Roboto'
export const WEB_SECONDARY_FONT: Fonts = 'Barlow'
export const WEB_TERTIARY_FONT: Fonts = 'Roboto Condensed'
