export enum ConferenceTypes {
  AFC = 'AFC',
  NFC = 'NFC',
}

export enum DivisionTypes {
  North = 'North',
  South = 'South',
  West = 'West',
  East = 'East',
}

const AFC_EAST = {
  conference: ConferenceTypes.AFC,
  division: DivisionTypes.East,
}
const AFC_WEST = {
  conference: ConferenceTypes.AFC,
  division: DivisionTypes.West,
}
const AFC_NORTH = {
  conference: ConferenceTypes.AFC,
  division: DivisionTypes.North,
}
const AFC_SOUTH = {
  conference: ConferenceTypes.AFC,
  division: DivisionTypes.South,
}
const NFC_EAST = {
  conference: ConferenceTypes.NFC,
  division: DivisionTypes.East,
}
const NFC_WEST = {
  conference: ConferenceTypes.NFC,
  division: DivisionTypes.West,
}
const NFC_NORTH = {
  conference: ConferenceTypes.NFC,
  division: DivisionTypes.North,
}
const NFC_SOUTH = {
  conference: ConferenceTypes.NFC,
  division: DivisionTypes.South,
}

export const TEAM_CONFERENCE_DIVISION_INFO: {
  [teamAbv: string]: { conference: ConferenceTypes; division: DivisionTypes }
} = {
  BUF: AFC_EAST,
  MIA: AFC_EAST,
  NE: AFC_EAST,
  NYJ: AFC_EAST,

  CIN: AFC_NORTH,
  BAL: AFC_NORTH,
  PIT: AFC_NORTH,
  CLE: AFC_NORTH,

  JAX: AFC_SOUTH,
  TEN: AFC_SOUTH,
  IND: AFC_SOUTH,
  HOU: AFC_SOUTH,

  KC: AFC_WEST,
  LAC: AFC_WEST,
  LV: AFC_WEST,
  DEN: AFC_WEST,

  PHI: NFC_EAST,
  DAL: NFC_EAST,
  NYG: NFC_EAST,
  WAS: NFC_EAST,

  MIN: NFC_NORTH,
  DET: NFC_NORTH,
  GB: NFC_NORTH,
  CHI: NFC_NORTH,

  TB: NFC_SOUTH,
  CAR: NFC_SOUTH,
  NO: NFC_SOUTH,
  ATL: NFC_SOUTH,

  SF: NFC_WEST,
  SEA: NFC_WEST,
  LAR: NFC_WEST,
  ARI: NFC_WEST,
}

export type MappedTeamInfo = {
  name: string
  city: string
  abbreviation: string
}

export type MappedTeams = {
  [key: string]: MappedTeamInfo[]
}
