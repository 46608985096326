import { round } from 'lodash'

// Utility to get the latest drafted player ID
export const getLatestDraftedPlayerId = (userPicks: string[]): string => {
  return userPicks.pop() || ''
}

// Utility to find the index of a suggested player
export const findSuggestedPlayerIndex = (suggestions: any[], playerId: string): number => {
  return suggestions.findIndex((suggestion) => suggestion.playerId === playerId)
}

// Utility to calculate draft grade
export const calculateDraftGrade = (pickGrades: any, playerId: string, suggestedPlayer: any): number => {
  const pickGrade = pickGrades[playerId] || 0
  return suggestedPlayer ? suggestedPlayer.pickGrade : round(pickGrade * 100)
}

// Utility to get availability percentage
export const getAvailabilityPercent = (nextRoundAvailability: any, numberId: number, suggestedPlayer: any): number => {
  return suggestedPlayer ? suggestedPlayer.availability : nextRoundAvailability[numberId]?.[0] || 0
}
