export const ABILENE_CHRISTIAN_WILDCATS = 'abilene-christian-wildcats'
export const AIKEN_FALCONS = 'aiken-falcons'
export const AIR_FORCE_FALCONS = 'air-force-falcons'
export const AKRON_ZIPS = 'akron-zips'
export const ALABAMA_AM_BULLDOGS = 'alabama-am-bulldogs'
export const ALABAMA_CRIMSON_TIDE = 'alabama-crimson-tide'
export const ALABAMA_STATE_HORNETS = 'alabama-state-hornets'
export const ALBANY_GREAT_DANES = 'albany-great-danes'
export const ALBANY_STATE_GOLDEN_RAMS = 'albany-state-golden-rams'
export const ALCORN_STATE_BRAVES = 'alcorn-state-braves'
export const ALDERSON_BROADDUS_BATTLERS = 'alderson-broaddus-battlers'
export const ALTER_KNIGHTS = 'alter-knights'
export const AMERICAN_NFLPA = 'american-nflpa'
export const ANDERSON_REDSKINS = 'anderson-redskins'
export const APPALACHIAN_STATE_MOUNTAINEERS = 'appalachian-state-mountaineers'
export const ARIZONA_CARDINALS = 'arizona-cardinals'
export const ARIZONA_HOTSHOTS = 'arizona-hotshots'
export const ARIZONA_STATE_SUN_DEVILS = 'arizona-state-sun-devils'
export const ARIZONA_WILDCATS = 'arizona-wildcats'
export const ARKANSAS_PINE_BLUFF_GOLDEN_LIONS = 'arkansas-pine-bluff-golden-lions'
export const ARKANSAS_RAZORBACKS = 'arkansas-razorbacks'
export const ARKANSAS_STATE_RED_WOLVES = 'arkansas-state-red-wolves'
export const ARMY_BLACK_KNIGHTS = 'army-black-knights'
export const ATLANTA_FALCONS = 'atlanta-falcons'
export const ATLANTA_LEGENDS = 'atlanta-legends'
export const AUBURN_TIGERS = 'auburn-tigers'
export const AUSTIN_PEAY_GOVERNORS = 'austin-peay-governors'
export const BACONE_COLLEGE_WARRIORS = 'bacone-college-warriors'
export const BADIN_RAMS = 'badin-rams'
export const BALL_STATE_CARDINALS = 'ball-state-cardinals'
export const BALTIMORE_RAVENS = 'baltimore-ravens'
export const BAYLOR_BEARS = 'baylor-bears'
export const BEAVERCREEK_BEAVERS = 'beavercreek-beavers'
export const BEECHWOOD_TIGERS = 'beechwood-tigers'
export const BELLEVUE_TIGERS = 'bellevue-tigers'
export const BELMONT_BISON = 'belmont-bison'
export const BENEDICT_COLLEGE_TIGERS = 'benedict-college-tigers'
export const BETHUNE_COOKMAN_WILDCATS = 'bethune-cookman-wildcats'
export const BIRMINGHAM_IRON = 'birmingham-iron'
export const BLUEFIELD_COLLEGE_RAMS = 'bluefield-college-rams'
export const BOISE_STATE_BRONCOS = 'boise-state-broncos'
export const BOONE_COUNTY_REBELS = 'boone-county-rebels'
export const BOSTON_COLLEGE_EAGLES = 'boston-college-eagles'
export const BOWIE_STATE_BULLDOGS = 'bowie-state-bulldogs'
export const BOWLING_GREEN_FALCONS = 'bowling-green-falcons'
export const BROSSART_MUSTANGS = 'brossart-mustangs'
export const BROWN_BEARS = 'brown-bears'
export const BRYAN_STATION_DEFENDERS = 'bryan-station-defenders'
export const BRYANT_UNIVERSITY_BULLDOGS = 'bryant-university-bulldogs'
export const BUCKNELL_BISON = 'bucknell-bison'
export const BUFFALO_BILLS = 'buffalo-bills'
export const BUFFALO_BULLS = 'buffalo-bulls'
export const BUTLER_BULLDOGS = 'butler-bulldogs'
export const BYU_COUGARS = 'byu-cougars'
export const CABELL_MIDLAND_KNIGHTS = 'cabell-midland-knights'
export const CAL_POLY_MUSTANGS = 'cal-poly-mustangs'
export const CALIFORNIA_GOLDEN_BEARS = 'california-golden-bears'
export const CAMPBELL_COUNTY_CAMELS = 'campbell-county-camels'
export const CAMPBELL_FIGHTING_CAMELS = 'campbell-fighting-camels'
export const CAROLINA_PANTHERS = 'carolina-panthers'
export const CARROLL_COUNTY_PANTHERS = 'carroll-county-panthers'
export const CARROLL_PATRIOTS = 'carroll-patriots'
export const CATHEDRAL_FIGHTING_IRISH = 'cathedral-fighting-irish'
export const CENTERVILLE_ELKS = 'centerville-elks'
export const CENTRAL_ARKANSAS_BEARS = 'central-arkansas-bears'
export const CENTRAL_CONNECTICUT_STATE_BLUE_DEVILS = 'central-connecticut-state-blue-devils'
export const CENTRAL_MICHIGAN_CHIPPEWAS = 'central-michigan-chippewas'
export const CENTRAL_STATE_MARAUDERS = 'central-state-marauders'
export const CENTRAL_WASHINGTON_WILDCATS = 'central-washington-wildcats'
export const CHAMINADE_JULIENNE_EAGLES = 'chaminade-julienne-eagles'
export const CHARLESTON_SOUTHERN_BUCCANEERS = 'charleston-southern-buccaneers'
export const CHARLOTTE_49ERS = 'charlotte-49ers'
export const CHATTANOOGA_MOCS = 'chattanooga-mocs'
export const CHICAGO_BEARS = 'chicago-bears'
export const CHOWAN_HAWKS = 'chowan-hawks'
export const CINCINNATI_BEARCATS = 'cincinnati-bearcats'
export const CINCINNATI_BENGALS = 'cincinnati-bengals'
export const CITADEL_BULLDOGS = 'citadel-bulldogs'
export const CLARK_ATLANTA_PANTHERS = 'clark-atlanta-panthers'
export const CLARKSON_ONTARIO_CHARGERS = 'clarkson-ontario-chargers'
export const CLEMSON_TIGERS = 'clemson-tigers'
export const CLEVELAND_BROWNS = 'cleveland-browns'
export const CLEVELAND_ST_IGNATIUS_WILDCATS = 'cleveland-st-ignatius-wildcats'
export const COASTAL_CAROLINA_CHANTICLEERS = 'coastal-carolina-chanticleers'
export const COLERAIN_CARDINALS = 'colerain-cardinals'
export const COLGATE_RAIDERS = 'colgate-raiders'
export const COLORADO_BUFFALOES = 'colorado-buffaloes'
export const COLORADO_STATE_RAMS = 'colorado-state-rams'
export const COLUMBIA_LIONS = 'columbia-lions'
export const CONCORDIA_CARDINALS = 'concordia-cardinals'
export const CONNECTICUT_HUSKIES = 'connecticut-huskies'
export const CONNER_COUGARS = 'conner-cougars'
export const COOPER_JAGUARS = 'cooper-jaguars'
export const CORBIN_REDHOUNDS = 'corbin-redhounds'
export const CORNELL_BIG_RED = 'cornell-big-red'
export const COVINGTON_CATHOLIC_COLONELS = 'covington-catholic-colonels'
export const CUMBERLAND_PHOENIX = 'cumberland-phoenix'
export const DALLAS_COWBOYS = 'dallas-cowboys'
export const DALLAS_RENEGADES = 'dallas-renegades'
export const DARTMOUTH_BIG_GREEN = 'dartmouth-big-green'
export const DAVIDSON_WILDCATS = 'davidson-wildcats'
export const DAYTON_FLYERS = 'dayton-flyers'
export const DAYTON_GREEN_DEVILS = 'dayton-green-devils'
export const DC_DEFENDERS = 'dc-defenders'
export const DELAWARE_FIGHTIN_BLUE_HENS = 'delaware-fightin-blue-hens'
export const DELAWARE_STATE_HORNETS = 'delaware-state-hornets'
export const DENVER_BRONCOS = 'denver-broncos'
export const DETROIT_LIONS = 'detroit-lions'
export const DIXIE_HEIGHTS_COLONELS = 'dixie-heights-colonels'
export const DRAKE_BULLDOGS = 'drake-bulldogs'
export const DUKE_BLUE_DEVILS = 'duke-blue-devils'
export const DUNBAR_WOLVERINES = 'dunbar-wolverines'
export const DUQUESNE_DUKES = 'duquesne-dukes'
export const EAST_CAROLINA_PIRATES = 'east-carolina-pirates'
export const EAST_CENTRAL_TROJANS = 'east-central-trojans'
export const EAST_SHRINE = 'east-shrine'
export const EAST_TENNESSEE_STATE_BUCCANEERS = 'east-tennessee-state-buccaneers'
export const EASTERN_ILLINOIS_PANTHERS = 'eastern-illinois-panthers'
export const EASTERN_KENTUCKY_COLONELS = 'eastern-kentucky-colonels'
export const EASTERN_MICHIGAN_EAGLES = 'eastern-michigan-eagles'
export const EASTERN_WASHINGTON_EAGLES = 'eastern-washington-eagles'
export const EDGEWOOD_COUGARS = 'edgewood-cougars'
export const EDWARD_WATERS_TIGERS = 'edward-waters-tigers'
export const ELDER_PANTHERS = 'elder-panthers'
export const ELIZABETH_CITY_STATE_VIKINGS = 'elizabeth-city-state-vikings'
export const ELON_PHOENIX = 'elon-phoenix'
export const FAIRFIELD_INDIANS = 'fairfield-indians'
export const FAIRMONT_FIREBIRDS = 'fairmont-firebirds'
export const FAYETTEVILLE_STATE_BRONCOS = 'fayetteville-state-broncos'
export const FENWICK_FALCONS = 'fenwick-falcons'
export const FLORIDA_AM_RATTLERS = 'florida-am-rattlers'
export const FLORIDA_ATLANTIC_OWLS = 'florida-atlantic-owls'
export const FLORIDA_GATORS = 'florida-gators'
export const FLORIDA_INTERNATIONAL_GOLDEN_PANTHERS = 'florida-international-golden-panthers'
export const FLORIDA_STATE_SEMINOLES = 'florida-state-seminoles'
export const FLORIDA_TECH_PANTHERS = 'florida-tech-panthers'
export const FORDHAM_RAMS = 'fordham-rams'
export const FRANKLIN_GRIZZLIES = 'franklin-grizzlies'
export const FRESNO_STATE_BULLDOGS = 'fresno-state-bulldogs'
export const FURMAN_PALADINS = 'furman-paladins'
export const GALLATIN_COUNTY_WILDCATS = 'gallatin-county-wildcats'
export const GAMBLE_MONTESSORI_GATORS = 'gamble-montessori-gators'
export const GARDNER_WEBB_RUNNIN_BULLDOGS = 'gardner-webb-runnin-bulldogs'
export const GEORGE_ROGERS_CREEK_CARDINALS = 'george-rogers-creek-cardinals'
export const GEORGETOWN_HOYAS = 'georgetown-hoyas'
export const GEORGIA_BULLDOGS = 'georgia-bulldogs'
export const GEORGIA_SOUTHERN_EAGLES = 'georgia-southern-eagles'
export const GEORGIA_STATE_PANTHERS = 'georgia-state-panthers'
export const GEORGIA_TECH_YELLOW_JACKETS = 'georgia-tech-yellow-jackets'
export const GRAMBLING_STATE_TIGERS = 'grambling-state-tigers'
export const GRANT_COUNTY_BRAVES = 'grant-county-braves'
export const GREEN_BAY_PACKERS = 'green-bay-packers'
export const HAMILTON_BIG_BLUE = 'hamilton-big-blue'
export const HAMPTON_PIRATES = 'hampton-pirates'
export const HARRISON_COUNTY_THOROBREDS = 'harrison-county-thorobreds'
export const HARRISON_WILDCATS = 'harrison-wildcats'
export const HARVARD_CRIMSON = 'harvard-crimson'
export const HAWAII_WARRIORS = 'hawaii-warriors'
export const HIGHLANDS_BLUEBIRDS = 'highlands-bluebirds'
export const HILLCREST_RAMS = 'hillcrest-rams'
export const HINSDALE_CENTRAL_RED_DEVILS = 'hinsdale-central-red-devils'
export const HOLMES_BULLDOGS = 'holmes-bulldogs'
export const HOLY_CROSS_CRUSADERS = 'holy-cross-crusaders'
export const HOLY_CROSS_INDIANS = 'holy-cross-indians'
export const HOUSTON_BAPTIST_HUSKIES = 'houston-baptist-huskies'
export const HOUSTON_COUGARS = 'houston-cougars'
export const HOUSTON_ROUGHNECKS = 'houston-roughnecks'
export const HOUSTON_TEXANS = 'houston-texans'
export const HOWARD_BISON = 'howard-bison'
export const HUGHES_BIG_RED = 'hughes-big-red'
export const IDAHO_STATE_BENGALS = 'idaho-state-bengals'
export const IDAHO_VANDALS = 'idaho-vandals'
export const ILLINOIS_FIGHTING_ILLINI = 'illinois-fighting-illini'
export const ILLINOIS_STATE_REDBIRDS = 'illinois-state-redbirds'
export const INCARNATE_WORD_CARDINALS = 'incarnate-word-cardinals'
export const INDIANA_HOOSIERS = 'indiana-hoosiers'
export const INDIANA_STATE_SYCAMORES = 'indiana-state-sycamores'
export const INDIANAPOLIS_COLTS = 'indianapolis-colts'
export const INTL_SCHOOL_OF_BROWARD_PUMAS = 'intl-school-of-broward-pumas'
export const IOWA_HAWKEYES = 'iowa-hawkeyes'
export const IOWA_STATE_CYCLONES = 'iowa-state-cyclones'
export const JACKSON_STATE_TIGERS = 'jackson-state-tigers'
export const JACKSONVILLE_DOLPHINS = 'jacksonville-dolphins'
export const JACKSONVILLE_JAGUARS = 'jacksonville-jaguars'
export const JACKSONVILLE_STATE_GAMECOCKS = 'jacksonville-state-gamecocks'
export const JAMES_MADISON_DUKES = 'james-madison-dukes'
export const JOHNSON_CENTRAL_SMITH_GOLDEN_BULLS = 'johnson-central-smith-golden-bulls'
export const KANSAS_CITY_CHIEFS = 'kansas-city-chiefs'
export const KANSAS_JAYHAWKS = 'kansas-jayhawks'
export const KANSAS_STATE_WILDCATS = 'kansas-state-wildcats'
export const KENNESAW_STATE_OWLS = 'kennesaw-state-owls'
export const KENT_STATE_GOLDEN_FLASHES = 'kent-state-golden-flashes'
export const KENTUCKY_STATE_THOROBREDS = 'kentucky-state-thorobreds'
export const KENTUCKY_WESLEYAN_PANTHERS = 'kentucky-wesleyan-panthers'
export const KENTUCKY_WILDCATS = 'kentucky-wildcats'
export const KINGS_KNIGHTS = 'kings-knights'
export const LA_SALLE_LANCERS = 'la-salle-lancers'
export const LAFAYETTE_GENERALS = 'lafayette-generals'
export const LAFAYETTE_LEOPARDS = 'lafayette-leopards'
export const LAKEWOOD_ST_EDWARD_EAGLES = 'lakewood-st-edward-eagles'
export const LAKOTA_EAST_THUNDERHAWKS = 'lakota-east-thunderhawks'
export const LAKOTA_WEST_FIREBIRDS = 'lakota-west-firebirds'
export const LAMAR_CARDINALS = 'lamar-cardinals'
export const LAS_VEGAS_RAIDERS = 'las-vegas-raiders'
export const LEBANON_WARRIORS = 'lebanon-warriors'
export const LEHIGH_MOUNTAIN_HAWKS = 'lehigh-mountain-hawks'
export const LIBERTY_FLAMES = 'liberty-flames'
export const LIMA_SENIOR_SPARTANS = 'lima-senior-spartans'
export const LINCOLN_GOLDEN_LIONS = 'lincoln-golden-lions'
export const LINCOLN_LIONS = 'lincoln-lions'
export const LITTLE_MIAMI_PANTHERS = 'little-miami-panthers'
export const LIVINGSTONE_BLUE_BEARS = 'livingstone-blue-bears'
export const LLOYD_JUGGERNAUTS = 'lloyd-juggernauts'
export const LOS_ANGELES_CHARGERS = 'los-angeles-chargers'
export const LOS_ANGELES_RAMS = 'los-angeles-rams'
export const LOS_ANGELES_WILDCATS = 'los-angeles-wildcats'
export const LOUISIANA_MONROE_WARHAWKS = 'louisiana-monroe-warhawks'
export const LOUISIANA_RAGIN_CAJUNS = 'louisiana-ragin-cajuns'
export const LOUISIANA_TECH_BULLDOGS = 'louisiana-tech-bulldogs'
export const LOUISVILLE_CARDINALS = 'louisville-cardinals'
export const LOVELAND_TIGERS = 'loveland-tigers'
export const LSU_TIGERS = 'lsu-tigers'
export const LUDLOW_PANTHERS = 'ludlow-panthers'
export const MAINE_BLACK_BEARS = 'maine-black-bears'
export const MALONE_PIONEERS = 'malone-pioneers'
export const MANCHESTER_GREYHOUNDS = 'manchester-greyhounds'
export const MARIST_RED_FOXES = 'marist-red-foxes'
export const MARS_HILL_LIONS = 'mars-hill-lions'
export const MARSHALL_THUNDERING_HERD = 'marshall-thundering-herd'
export const MARYLAND_TERRAPINS = 'maryland-terrapins'
export const MASON_COMETS = 'mason-comets'
export const MASSACHUSETTS_MINUTEMEN = 'massachusetts-minutemen'
export const MCKENDREE_BEARCATS = 'mckendree-bearcats'
export const MCNEESE_STATE_COWBOYS = 'mcneese-state-cowboys'
export const MCNICHOLAS_ROCKETS = 'mcnicholas-rockets'
export const MEADE_COUNTY_GREEN_WAVES = 'meade-county-green-waves'
export const MEADOWDALE_LIONS = 'meadowdale-lions'
export const MEMPHIS_EXPRESS = 'memphis-express'
export const MEMPHIS_TIGERS = 'memphis-tigers'
export const MERCER_BEARS = 'mercer-bears'
export const MERRIMACK_WARRIORS = 'merrimack-warriors'
export const MIAMI_DOLPHINS = 'miami-dolphins'
export const MIAMI_FL_HURRICANES = 'miami-fl-hurricanes'
export const MIAMI_OH_REDHAWKS = 'miami-oh-redhawks'
export const MIAMISBURG_VIKINGS = 'miamisburg-vikings'
export const MICHIGAN_STATE_SPARTANS = 'michigan-state-spartans'
export const MICHIGAN_WOLVERINES = 'michigan-wolverines'
export const MIDDLE_TENNESSEE_BLUE_RAIDERS = 'middle-tennessee-blue-raiders'
export const MIDDLETOWN_MIDDIES = 'middletown-middies'
export const MILES_GOLDEN_BEARS = 'miles-golden-bears'
export const MILFORD_EAGLES = 'milford-eagles'
export const MINNESOTA_GOLDEN_GOPHERS = 'minnesota-golden-gophers'
export const MINNESOTA_VIKINGS = 'minnesota-vikings'
export const MISSISSIPPI_REBELS = 'mississippi-rebels'
export const MISSISSIPPI_STATE_BULLDOGS = 'mississippi-state-bulldogs'
export const MISSISSIPPI_VALLEY_STATE_DELTA_DEVILS = 'mississippi-valley-state-delta-devils'
export const MISSOURI_ST_MINERS = 'missouri-st-miners'
export const MISSOURI_STATE_BEARS = 'missouri-state-bears'
export const MISSOURI_TIGERS = 'missouri-tigers'
export const MOELLER_FIGHTING_CRUSADERS = 'moeller-fighting-crusaders'
export const MONMOUTH_HAWKS = 'monmouth-hawks'
export const MONTANA_GRIZZLIES = 'montana-grizzlies'
export const MONTANA_STATE_BOBCATS = 'montana-state-bobcats'
export const MOREHEAD_STATE_EAGLES = 'morehead-state-eagles'
export const MORGAN_STATE_BEARS = 'morgan-state-bears'
export const MOUNT_HEALTHY_FIGHTING_OWLS = 'mount-healthy-fighting-owls'
export const MURRAY_STATE_RACERS = 'murray-state-racers'
export const NATIONAL_NFLPA = 'national-nflpa'
export const NAVY_MIDSHIPMEN = 'navy-midshipmen'
export const NEBRASKA_CORNHUSKERS = 'nebraska-cornhuskers'
export const NEVADA_WOLF_PACK = 'nevada-wolf-pack'
export const NEW_ENGLAND_PATRIOTS = 'new-england-patriots'
export const NEW_HAMPSHIRE_WILDCATS = 'new-hampshire-wildcats'
export const NEW_MEXICO_HIGHLANDS_COWBOYS = 'new-mexico-highlands-cowboys'
export const NEW_MEXICO_LOBOS = 'new-mexico-lobos'
export const NEW_MEXICO_STATE_AGGIES = 'new-mexico-state-aggies'
export const NEW_ORLEANS_SAINTS = 'new-orleans-saints'
export const NEW_YORK_GIANTS = 'new-york-giants'
export const NEW_YORK_GUARDIANS = 'new-york-guardians'
export const NEW_YORK_JETS = 'new-york-jets'
export const NEWPORT_CATHOLIC_THOROUGHBREDS = 'newport-catholic-thoroughbreds'
export const NEWPORT_WILDCATS = 'newport-wildcats'
export const NICHOLLS_STATE_COLONELS = 'nicholls-state-colonels'
export const NORFOLK_STATE_SPARTANS = 'norfolk-state-spartans'
export const NORTH_ALABAMA_LIONS = 'north-alabama-lions'
export const NORTH_CAROLINA_AT_AGGIES = 'north-carolina-at-aggies'
export const NORTH_CAROLINA_CENTRAL_EAGLES = 'north-carolina-central-eagles'
export const NORTH_CAROLINA_STATE_WOLFPACK = 'north-carolina-state-wolfpack'
export const NORTH_CAROLINA_TAR_HEELS = 'north-carolina-tar-heels'
export const NORTH_DAKOTA_FIGHTING_SIOUX = 'north-dakota-fighting-sioux'
export const NORTH_DAKOTA_STATE_BISON = 'north-dakota-state-bison'
export const NORTH_GREENVILLE_CRUSADERS = 'north-greenville-crusaders'
export const NORTH_SENIOR = 'north-senior'
export const NORTH_TEXAS_MEAN_GREEN = 'north-texas-mean-green'
export const NORTHERN_ARIZONA_LUMBERJACKS = 'northern-arizona-lumberjacks'
export const NORTHERN_COLORADO_BEARS = 'northern-colorado-bears'
export const NORTHERN_ILLINOIS_HUSKIES = 'northern-illinois-huskies'
export const NORTHERN_IOWA_PANTHERS = 'northern-iowa-panthers'
export const NORTHMONT_THUNDERBOLTS = 'northmont-thunderbolts'
export const NORTHWEST_KNIGHTS = 'northwest-knights'
export const NORTHWESTERN_STATE_DEMONS = 'northwestern-state-demons'
export const NORTHWESTERN_WILDCATS = 'northwestern-wildcats'
export const NOTRE_DAME_FIGHTING_IRISH = 'notre-dame-fighting-irish'
export const OAK_HILLS_HIGHLANDERS = 'oak-hills-highlanders'
export const OAKLAND_RAIDERS = 'oakland-raiders'
export const OHIO_BOBCATS = 'ohio-bobcats'
export const OHIO_STATE_BUCKEYES = 'ohio-state-buckeyes'
export const OKLAHOMA_PANHANDLE_STATE_AGGIES = 'oklahoma-panhandle-state-aggies'
export const OKLAHOMA_SOONERS = 'oklahoma-sooners'
export const OKLAHOMA_STATE_COWBOYS = 'oklahoma-state-cowboys'
export const OLD_DOMINION_MONARCHS = 'old-dominion-monarchs'
export const OREGON_DUCKS = 'oregon-ducks'
export const OREGON_STATE_BEAVERS = 'oregon-state-beavers'
export const ORLANDO_APOLLOS = 'orlando-apollos'
export const OWEN_COUNTY_REBELS = 'owen-county-rebels'
export const PENN_STATE_NITTANY_LIONS = 'penn-state-nittany-lions'
export const PENNSYLVANIA_QUAKERS = 'pennsylvania-quakers'
export const PHILADELPHIA_EAGLES = 'philadelphia-eagles'
export const PIKEVILLE_BEARS = 'pikeville-bears'
export const PITTSBURGH_PANTHERS = 'pittsburgh-panthers'
export const PITTSBURGH_STEELERS = 'pittsburgh-steelers'
export const POINT_SKYHAWKS = 'point-skyhawks'
export const PONITZ_CAREER_TECH_GOLDEN_PANTHERS = 'ponitz-career-tech-golden-panthers'
export const PORTLAND_STATE_VIKINGS = 'portland-state-vikings'
export const PRAIRIE_VIEW_AM_PANTHERS = 'prairie-view-am-panthers'
export const PRESBYTERIAN_BLUE_HOSE = 'presbyterian-blue-hose'
export const PRINCETON_TIGERS = 'princeton-tigers'
export const PRINCETON_VIKINGS = 'princeton-vikings'
export const PURCELL_MARIAN_CAVALIERS = 'purcell-marian-cavaliers'
export const PURDUE_BOILERMAKERS = 'purdue-boilermakers'
export const QUINCY_HAWKS = 'quincy-hawks'
export const RHODE_ISLAND_RAMS = 'rhode-island-rams'
export const RICE_OWLS = 'rice-owls'
export const RICHMOND_SPIDERS = 'richmond-spiders'
export const RIVERVIEW_EAST_HAWKS = 'riverview-east-hawks'
export const ROBERT_MORRIS_COLONIALS = 'robert-morris-colonials'
export const ROCKY_MOUNTAIN_BATTLIN_BEARS = 'rocky-mountain-battlin-bears'
export const ROGER_BACON_SPARTANS = 'roger-bacon-spartans'
export const ROSS_RAMS = 'ross-rams'
export const RUTGERS_SCARLET_KNIGHTS = 'rutgers-scarlet-knights'
export const RYLE_RAIDERS = 'ryle-raiders'
export const SACRAMENTO_STATE_HORNETS = 'sacramento-state-hornets'
export const SACRED_HEART_PIONEERS = 'sacred-heart-pioneers'
export const SALT_LAKE_STALLIONS = 'salt-lake-stallions'
export const SAM_HOUSTON_STATE_BEARKATS = 'sam-houston-state-bearkats'
export const SAMFORD_BULLDOGS = 'samford-bulldogs'
export const SAN_ANTONIO_COMMANDERS = 'san-antonio-commanders'
export const SAN_DIEGO_CHARGERS = 'san-diego-chargers'
export const SAN_DIEGO_FLEET = 'san-diego-fleet'
export const SAN_DIEGO_STATE_AZTECS = 'san-diego-state-aztecs'
export const SAN_DIEGO_TOREROS = 'san-diego-toreros'
export const SAN_FRANCISCO_49ERS = 'san-francisco-49ers'
export const SAN_JOSE_STATE_SPARTANS = 'san-jose-state-spartans'
export const SAVANNAH_STATE_TIGERS = 'savannah-state-tigers'
export const SCOTT_COUNTY_CARDINALS = 'scott-county-cardinals'
export const SEATTLE_DRAGONS = 'seattle-dragons'
export const SEATTLE_SEAHAWKS = 'seattle-seahawks'
export const SHORTER_HAWKS = 'shorter-hawks'
export const SHRODER_JAGUARS = 'shroder-jaguars'
export const SIMON_KENTON_PIONEERS = 'simon-kenton-pioneers'
export const SMU_MUSTANGS = 'smu-mustangs'
export const SOMERSET_BRIAR_JUMPERS = 'somerset-briar-jumpers'
export const SOUTH_ALABAMA_JAGUARS = 'south-alabama-jaguars'
export const SOUTH_CAROLINA_GAMECOCKS = 'south-carolina-gamecocks'
export const SOUTH_CAROLINA_STATE_BULLDOGS = 'south-carolina-state-bulldogs'
export const SOUTH_DAKOTA_COYOTES = 'south-dakota-coyotes'
export const SOUTH_DAKOTA_STATE_JACKRABBITS = 'south-dakota-state-jackrabbits'
export const SOUTH_SENIOR = 'south-senior'
export const SOUTHEAST_MISSOURI_STATE_REDHAWKS = 'southeast-missouri-state-redhawks'
export const SOUTHEASTERN_LOUISIANA_LIONS = 'southeastern-louisiana-lions'
export const SOUTHERN_ILLINOIS_SALUKIS = 'southern-illinois-salukis'
export const SOUTHERN_MISS_GOLDEN_EAGLES = 'southern-miss-golden-eagles'
export const SOUTHERN_OREGON_RAIDERS = 'southern-oregon-raiders'
export const SOUTHERN_UNIVERSITY_JAGUARS = 'southern-university-jaguars'
export const SOUTHERN_UTAH_THUNDERBIRDS = 'southern-utah-thunderbirds'
export const SOUTHWESTERN_COLLEGE_JAGUAR = 'southwestern-college-jaguar'
export const SPRINGBORO_PANTHERS = 'springboro-panthers'
export const SPRINGFIELD_WILDCATS = 'springfield-wildcats'
export const ST_ANSELM_HAWKS = 'st-anselm-hawks'
export const ST_AUGUSTINES_FALCONS = 'st-augustines-falcons'
export const ST_CHARLES_CARDINALS = 'st-charles-cardinals'
export const ST_FRANCIS_PA_RED_FLASH = 'st-francis-pa-red-flash'
export const ST_LOUIS_RAMS = 'st-louis-rams'
export const ST_LOUIS_BATTLEHAWKS = 'st-louis-battlehawks'
export const ST_VINCENT_ST_MARY_FIGHTING_IRISH = 'st-vincent---st-mary-fighting-irish'
export const ST_XAVIER_BOMBERS = 'st-xavier-bombers'
export const ST_XAVIER_TIGERS = 'st-xavier-tigers'
export const STANFORD_CARDINAL = 'stanford-cardinal'
export const STEPHEN_F_AUSTIN_LUMBERJACKS = 'stephen-f-austin-lumberjacks'
export const STETSON_HATTERS = 'stetson-hatters'
export const STONY_BROOK_SEAWOLVES = 'stony-brook-seawolves'
export const SYCAMORE_AVIATORS = 'sycamore-aviators'
export const SYRACUSE_ORANGE = 'syracuse-orange'
export const TAFT_SENATORS = 'taft-senators'
export const TALAWANDA_BRAVES = 'talawanda-braves'
export const TAMPA_BAY_BUCCANEERS = 'tampa-bay-buccaneers'
export const TAMPA_BAY_VIPERS = 'tampa-bay-vipers'
export const TARLETON_STATE_TEXANS = 'tarleton-state-texans'
export const TAYLOR_TROJANS = 'taylor-trojans'
export const TCU_HORNED_FROGS = 'tcu-horned-frogs'
export const TEMPLE_OWLS = 'temple-owls'
export const TENNESSEE_MARTIN_SKYHAWKS = 'tennessee-martin-skyhawks'
export const TENNESSEE_STATE_TIGERS = 'tennessee-state-tigers'
export const TENNESSEE_TECH_GOLDEN_EAGLES = 'tennessee-tech-golden-eagles'
export const TENNESSEE_TITANS = 'tennessee-titans'
export const TENNESSEE_VOLUNTEERS = 'tennessee-volunteers'
export const TEXAS_AM_AGGIES = 'texas-am-aggies'
export const TEXAS_AM_KINGSVILLE_JAVELINAS = 'texas-am-kingsville-javelinas'
export const TEXAS_LONGHORNS = 'texas-longhorns'
export const TEXAS_SOUTHERN_TIGERS = 'texas-southern-tigers'
export const TEXAS_STATE_BOBCATS = 'texas-state-bobcats'
export const TEXAS_TECH_RED_RAIDERS = 'texas-tech-red-raiders'
export const THURGOOD_MARSHALL_COUGARS = 'thurgood-marshall-cougars'
export const TOLEDO_ROCKETS = 'toledo-rockets'
export const TOWSON_TIGERS = 'towson-tigers'
export const TRIMBLE_COUNTY_RAIDERS = 'trimble-county-raiders'
export const TRINITY_INTERNATIONAL_TROJANS = 'trinity-international-trojans'
export const TRINITY_SHAMROCKS = 'trinity-shamrocks'
export const TROY_TROJANS = 'troy-trojans'
export const TULANE_GREEN_WAVE = 'tulane-green-wave'
export const TULSA_GOLDEN_HURRICANE = 'tulsa-golden-hurricane'
export const TURPIN_SPARTANS = 'turpin-spartans'
export const TUSKEGEE_GOLDEN_TIGERS = 'tuskegee-golden-tigers'
export const UAB_BLAZERS = 'uab-blazers'
export const UC_DAVIS_AGGIES = 'uc-davis-aggies'
export const UCF_KNIGHTS = 'ucf-knights'
export const UCLA_BRUINS = 'ucla-bruins'
export const UNLV_REBELS = 'unlv-rebels'
export const USC_TROJANS = 'usc-trojans'
export const USF_BULLS = 'usf-bulls'
export const UTAH_STATE_AGGIES = 'utah-state-aggies'
export const UTAH_UTES = 'utah-utes'
export const UTEP_MINERS = 'utep-miners'
export const UTSA_ROADRUNNERS = 'utsa-roadrunners'
export const VALPARAISO_CRUSADERS = 'valparaiso-crusaders'
export const VANDERBILT_COMMODORES = 'vanderbilt-commodores'
export const VILLANOVA_WILDCATS = 'villanova-wildcats'
export const VIRGINIA_CAVALIERS = 'virginia-cavaliers'
export const VIRGINIA_LYNCHBURG_DRAGONS = 'virginia-lynchburg-dragons'
export const VIRGINIA_MILITARY_INSTITUTE_KEYDETS = 'virginia-military-institute-keydets'
export const VIRGINIA_TECH_HOKIES = 'virginia-tech-hokies'
export const WAGNER_SEAHAWKS = 'wagner-seahawks'
export const WAKE_FOREST_DEMON_DEACONS = 'wake-forest-demon-deacons'
export const WALNUT_HILLS_EAGLES = 'walnut-hills-eagles'
export const WALTON_VERONA_BEARCATS = 'walton-verona-bearcats'
export const WARNER_ROYALS = 'warner-royals'
export const WASHINGTON_COMMANDERS = 'washington-commanders'
export const WASHINGTON_HUSKIES = 'washington-huskies'
export const WASHINGTON_FOOTBALL_TEAM = 'washington-football-team'
export const WASHINGTON_REDSKINS = 'washington-redskins'
export const WASHINGTON_STATE_COUGARS = 'washington-state-cougars'
export const WAYNE_WARRIORS = 'wayne-warriors'
export const WEBER_STATE_WILDCATS = 'weber-state-wildcats'
export const WEST_ALABAMA_TIGERS = 'west-alabama-tigers'
export const WEST_CLERMONT_WOLVES = 'west-clermont-wolves'
export const WEST_SHRINE = 'west-shrine'
export const WEST_VIRGINIA_MOUNTAINEERS = 'west-virginia-mountaineers'
export const WEST_VIRGINIA_WESLEYAN_BOBCATS = 'west-virginia-wesleyan-bobcats'
export const WESTERN_CAROLINA_CATAMOUNTS = 'western-carolina-catamounts'
export const WESTERN_HILLS_MUSTANGS = 'western-hills-mustangs'
export const WESTERN_ILLINOIS_LEATHERNECKS = 'western-illinois-leathernecks'
export const WESTERN_KENTUCKY_HILLTOPPERS = 'western-kentucky-hilltoppers'
export const WESTERN_MICHIGAN_BRONCOS = 'western-michigan-broncos'
export const WESTERN_NEW_MEXICO_MUSTANGS = 'western-new-mexico-mustangs'
export const WESTERN_OREGON_WOLVES = 'western-oregon-wolves'
export const WILLIAM_MARY_TRIBE = 'william--mary-tribe'
export const WINTON_WOODS_WARRIORS = 'winton-woods-warriors'
export const WISCONSIN_BADGERS = 'wisconsin-badgers'
export const WITHROW_TIGERS = 'withrow-tigers'
export const WOFFORD_TERRIERS = 'wofford-terriers'
export const WOODWARD_BULLDOGS = 'woodward-bulldogs'
export const WYOMING_COWBOYS = 'wyoming-cowboys'
export const YALE_BULLDOGS = 'yale-bulldogs'
export const YOUNGSTOWN_STATE_PENGUINS = 'youngstown-state-penguins'
