export interface LdaSubscription {
  id: number
  grootUid: string
  leagueId: number
  channelId: string
  isSubscribed: boolean
}

export interface LdaSubscriptionUpsertParams
  extends Pick<LdaSubscription, 'grootUid' | 'leagueId' | 'channelId' | 'isSubscribed'> {}

export enum LiveActivityState {
  START = 'start',
  UPDATE = 'update',
  END = 'end',
}

export type LiveActivityStateType = keyof typeof LiveActivityState

export interface LdaLiveEventContent {
  firstName: string
  lastName: string
  playerID: number
  summary: string
  extendedSummary: string
  draftRound: number
  pickNumber: number
  expiration: number
  availability: number
}

export interface LdaLiveEventPayload {
  channelId: string
  leagueId: string
  contentState: LdaLiveEventContent
  state: string
}
