import type { FeatureFlagName } from '@pff-consumer/schema'

import { useCallback } from 'react'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

export const useFeatureFlags = () => {
  const featureFlags = useFlags()
  const ldClient = useLDClient()

  const initFeatureFlags = useCallback(
    async (userId: string) => {
      ldClient?.identify({ key: userId })
    },
    [ldClient]
  )

  const getFeatureFlag = useCallback(
    <T>(featureName: FeatureFlagName, fallback?: T) => {
      const feature = featureFlags[featureName]

      return typeof feature === 'undefined' ? fallback : (feature as T)
    },
    [featureFlags]
  )

  return { initFeatureFlags, getFeatureFlag }
}
