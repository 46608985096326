export const CONNECT_TAB = {
  GAME: 'Game',
  PLAYER_INSIGHTS: 'Player Insights',
  TEAM: 'Team',
  USAGE: 'Usage',
  TEAM_INSIGHTS: 'Team Insights',
}

export const CONNECT_PATH = {
  GAME: '/game',
  PLAYER_INSIGHTS: '/player',
  TEAM: '/team',
  // TODO - change to usage in off season
  USAGE: '/live',
  TEAM_INSIGHTS: '/team-insights',
}
