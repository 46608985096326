import type { CurrentSuggestionData, NextSuggestionData, NextPickInfo } from '@pff-consumer/schema'
import { round } from 'lodash'

const REQUIRED_PLAYER_COUNT = 4

export const transformNextPickInfoApiResponse = (
  suggestions: CurrentSuggestionData,
  suggestedNextPicks: Record<string, NextSuggestionData>
): NextPickInfo[] => {
  const filteredSuggestions = suggestions.player_id.map((playerId) => {
    const filteredIndex = suggestions.player_id.indexOf(playerId)
    return {
      playerId,
      pickGrade: round(suggestions.PFF_s[filteredIndex] * 100),
      availability: round(suggestions.available_1[filteredIndex] * 100),
      points: round(suggestions.value[filteredIndex]),
    }
  })

  const topSuggestions = filteredSuggestions.sort((a, b) => b.pickGrade - a.pickGrade).slice(0, REQUIRED_PLAYER_COUNT)

  const transformedSuggestions = topSuggestions.map((suggestion, index) => {
    const nextPickSuggestion: NextSuggestionData | string = suggestedNextPicks[`if${index + 1}`]

    if (
      typeof nextPickSuggestion === 'string' &&
      (nextPickSuggestion === 'last_round' || nextPickSuggestion === 'draft_over')
    ) {
      return {
        ...suggestion,
        nextRoundPlayers: [],
      }
    }

    const nextRoundPlayers = nextPickSuggestion.player_id.map((nextPlayerId) => {
      const filteredNextIndex = nextPickSuggestion.player_id.indexOf(nextPlayerId)

      return {
        playerId: nextPlayerId,
        pickGrade: round(nextPickSuggestion.proj_PFF_s[filteredNextIndex] * 100),
        nextRoundBestPickScore: round(nextPickSuggestion.p_best[filteredNextIndex] * 100),
        availability: round(nextPickSuggestion.available_1[filteredNextIndex] * 100),
      }
    })

    const topNextRoundPlayers = nextRoundPlayers
      .sort((a, b) => b.nextRoundBestPickScore - a.nextRoundBestPickScore)
      .slice(0, REQUIRED_PLAYER_COUNT)

    return {
      ...suggestion,
      nextRoundPlayers: topNextRoundPlayers,
    }
  })

  return transformedSuggestions
}
