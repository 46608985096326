/**
 * @param value - Timestamp
 * @returns The string of data format THU, SEP 08 • 8:20 PM
 */

export const formatDateTime = (timestamp: number) => {
  const eventDate = new Date(timestamp)
  const [weekDay, month, day] = eventDate.toDateString().split(' ')
  const time = eventDate.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' })

  return `${weekDay}, ${month} ${day} • ${time}`
}

/**
 * @param value - Timestamp
 * @param type - date format 'short' | undefined
 * @returns The string of data format MM/DD/YY or MM/DD
 */

export const formatDate = (timestamp: number | string, type?: string) => {
  const date = new Date(timestamp)
  if (type === 'short') {
    return `${date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
    })}`
  }
  return `${date.toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  })}`
}

/**
 * @param value - Timestamp
 * @returns The string of data format THURSDAY, SEPTEMBER 08
 */

export const formatDayMonthDate = (timestamp: number) => {
  const eventDate = new Date(timestamp)

  const weekDay = eventDate.toLocaleDateString('en-US', { weekday: 'long' })
  const month = eventDate.toLocaleDateString('en-US', { month: 'long' })
  const day = eventDate.toLocaleDateString('en-US', { day: '2-digit' })
  return `${weekDay}, ${month} ${day}`
}

/**
 * @param value - Timestamp
 * @returns The string of data format Mar 22, 2024 7:54pm EDT
 * it will EST/EDT based on daylight savings month
 */

export const formatUpdatedTime = (epochTimestampInSeconds: number | null) => {
  // Intl.DateTimeFormat() expects epoch timestamp to be in milliseconds format
  // and in case of no timestamp value return null

  if (!epochTimestampInSeconds) {
    return null
  }

  const updatedAtTime = epochTimestampInSeconds * 1000

  const eventDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    timeZone: 'America/New_York',
    year: 'numeric',
  }).format(updatedAtTime)

  const eventTime = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'America/New_York',
    timeZoneName: 'short',
    hour12: true,
  })
    .format(updatedAtTime)
    .split(' ')

  const time = eventTime[0].split(':')

  const newTime = `${parseInt(time[0], 10)}:${time[1]}`
  const timeFormat = eventTime[1].toLowerCase()
  const timeZone = eventTime[2]

  return `${eventDate} ${newTime}${timeFormat} ${timeZone}`
}
