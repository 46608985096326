import type { SDKEventReceivingMetric, SDKEventRushingMetric } from '../sdk'

export interface Insight {
  insightTitle: string
  insightText: string
  sortOrder: number
  insightTimestamp?: number // Optional has to be removed, once analysis date is available
}

export interface EventInsight {
  eventId: number
  insights: Insight[]
}

export interface PFFLiveData {
  rushingEventData?: SDKEventRushingMetric
  receivingEventData?: SDKEventReceivingMetric
}

export type VisitedEvents = Record<number, number>

export enum AnalysisCardTypes {
  TOP_ANALYSIS = 'Top Analysis',
  EVENT_ANALYSIS = 'Event Analysis',
  PLAYER_ANALYSIS = 'Player Analysis',
  TEAM_ANALYSIS = 'Team Analysis',
  TEAM_INSIGHTS_ANALYSIS = 'Team Insights Analysis',
}
