import type { MDSLeagueSettings } from '@pff-consumer/schema'
import type { Keeper } from '@pff-consumer/schema/fantasy-live-draft/keeper'
import { mapAdditionalFlexPositionsWithFlex } from './get-additional-flex-positions'

export const transformLeagueSettingsForMds = (leagueSettings: MDSLeagueSettings, leagueKeepers: Keeper[]) => {
  const updatedKeeperPicks = {
    player_id: leagueKeepers.map((keeper: Keeper) => keeper.playerId),
    pick: leagueKeepers.map((keeper: Keeper) => keeper.pickNumber),
  }

  // Map Additional flex positions
  const mappedFlexPositionsLeagueSettings = mapAdditionalFlexPositionsWithFlex(leagueSettings)

  return {
    ...mappedFlexPositionsLeagueSettings,
    keeper_dict: updatedKeeperPicks,
  }
}
