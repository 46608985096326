import { FootballPlayer } from '../football/football-player'
import { GameScript } from './key-insights'

export enum ConnectInsightType {
  PlayerVsPlayer = 'player-vs-player',
  PlayerVsTeamUnit = 'player-vs-team-unit',
  HistoricalPerformance = 'historical-performance',
}

export interface ConnectInsight {
  id: number
  playerId: number
  insightType: ConnectInsightType
  header?: string | undefined
  text: string
  createdAt: number
  updatedAt: number
  edited: boolean
  sortOrder: number
  active: boolean
}

export interface ConnectGameScript extends GameScript {
  player: FootballPlayer
}

export interface ConnectPlayer extends FootballPlayer {
  teamAbbreviation?: string | null
}

export interface ConnectInsightWithPlayer extends ConnectInsight {
  player: ConnectPlayer
}

export interface EventConnectInsights {
  eventId: number
  connectInsights: ConnectInsight[]
}

export interface WeekConnectInsights {
  weekAbv: string
  connectInsights: ConnectInsight[]
}
export interface InsightsUpdatedTimestamp {
  eventId: number
  lastUpdated: number
}
