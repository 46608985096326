/**
 * 🚨🚨🚨
 * DO NOT ADD OR EDIT THESE WITHOUT THE EXPLICT PERMISSION OF EITHER ANDY BREEN OR ETHAN HEFFLER
 * 🚨🚨🚨
 */

export type ZIndexType = typeof ZIndex
export enum ZIndex {
  base = 0,
  levelOne = 1,
  levelTwo = 2,
  levelThree = 3,
  levelFour = 4,
  levelFive = 5,
  highestLevel = 99999,
  lowestLevel = -99999,
}
