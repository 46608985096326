import type { LeagueSettingsStarters, DraftedPlayerInfo } from '@pff-consumer/schema'
import { FlexPostionPills, ROSTER_POSITION_SORT_ORDER } from '@pff-consumer/schema'

interface DraftedRoster extends DraftedPlayerInfo {
  rosterPosition: string
  pickedRound: number
}

interface UserPicks extends DraftedPlayerInfo {
  originalIndex: number
}

interface PositionalDraftedPlayers {
  [position: string]: DraftedRoster[]
}

interface LeagueStarterPositions {
  [position: string]: number
}

const BENCH_POSITION: string = 'BN'

const sortPlayers = (userPicks: DraftedPlayerInfo[]): UserPicks[] => {
  const userPicsWithOriginalPickNumbers = userPicks.map((user, index) => ({
    ...user,
    originalIndex: index + 1,
  }))

  userPicsWithOriginalPickNumbers.sort((a, b) => {
    if (a.position < b.position) {
      return -1
    }
    if (a.position > b.position) {
      return 1
    }
    return (a.positionalRank || 9999) - (b.positionalRank || 9999)
  })
  return userPicsWithOriginalPickNumbers
}

const transformPlayersToRosterView = (
  playersByPosition: PositionalDraftedPlayers,
  startingPositions: LeagueStarterPositions,
  benchCount: number
): DraftedRoster[] => {
  const sortedPlayers: DraftedRoster[] = []

  Object.keys(ROSTER_POSITION_SORT_ORDER).forEach((key) => {
    const count = key === BENCH_POSITION ? benchCount : startingPositions[key]
    const players = playersByPosition[key] || []
    const filledPlayers = players.slice(0, count)
    const emptyPositionsCount = Math.max(count - filledPlayers.length, 0)

    sortedPlayers.push(
      ...filledPlayers,
      ...Array.from({ length: emptyPositionsCount }, () => ({
        id: -1,
        firstName: '',
        lastName: '',
        rosterPosition: key,
        bye: -1,
        team: '',
        teamName: '',
        teamCity: '',
        position: '',
        pickedRound: -1,
      }))
    )
  })

  return sortedPlayers
}

const mapAdditionalFlexPositionKey = (positionKey: string) => {
  switch (FlexPostionPills[positionKey.toUpperCase() as keyof typeof FlexPostionPills]) {
    case FlexPostionPills.FLEX:
      return FlexPostionPills.FLEX
    case FlexPostionPills.WRRB:
      return FlexPostionPills.WRRB
    case FlexPostionPills.WRTE:
      return FlexPostionPills.WRTE
    case FlexPostionPills.SUPERFLEX:
      return FlexPostionPills.SUPERFLEX
    default:
      return positionKey
  }
}

export const sortByDraftRoomStarterMap = (
  userPicks: DraftedPlayerInfo[],
  leagueSettingsStarters: LeagueSettingsStarters,
  benchCount: number
): DraftedRoster[] => {
  // Initialize an empty object to hold the players for each position
  const filledPlayers: PositionalDraftedPlayers = {}

  // Filter out only the properties starting with "starting_"
  const startingPositions: LeagueStarterPositions = Object.entries(leagueSettingsStarters).reduce(
    (acc, [key, value]) => {
      const positionKey = mapAdditionalFlexPositionKey(key.slice(9))
      return { ...acc, [positionKey]: value }
    },
    {}
  )

  // Helper function to check if a position is already filled
  const canPositionBeFilled = (position: string): boolean => {
    return startingPositions[position] > 0 && !(filledPlayers[position]?.length === startingPositions[position])
  }

  const canWRRBPositionBeFilled = (position: string): boolean => {
    const flxOptions = ['WR', 'RB']
    return flxOptions.includes(position) && canPositionBeFilled(FlexPostionPills.WRRB)
  }

  const canWRTEPositionBeFilled = (position: string): boolean => {
    const flxOptions = ['WR', 'TE']
    return flxOptions.includes(position) && canPositionBeFilled(FlexPostionPills.WRTE)
  }

  const canFlexPositionBeFilled = (position: string): boolean => {
    // Try to fill the FLX position with WR, RB, or TE if available
    const flxOptions = ['WR', 'RB', 'TE']
    return flxOptions.includes(position) && canPositionBeFilled(FlexPostionPills.FLEX)
  }

  const canSuperFlexPositionBeFilled = (position: string): boolean => {
    // Try to fill the SFLX position with WR, RB,TE or QB if available
    const sflxOptions = ['WR', 'RB', 'TE', 'QB']
    return sflxOptions.includes(position) && canPositionBeFilled(FlexPostionPills.SUPERFLEX)
  }

  const sortedUserPicks = sortPlayers(userPicks)

  // Iterate through the players and assign them to their respective positions
  sortedUserPicks.forEach((player) => {
    const { position } = player
    const { originalIndex, ...pickedPlayer } = player
    if (canPositionBeFilled(position)) {
      // If the position is not filled, add the player to that position
      filledPlayers[position] = filledPlayers[position] || []
      filledPlayers[position].push({
        ...pickedPlayer,
        rosterPosition: position,
        pickedRound: originalIndex,
      })
    } else if (canWRRBPositionBeFilled(position)) {
      filledPlayers[FlexPostionPills.WRRB] = filledPlayers[FlexPostionPills.WRRB] || []
      filledPlayers[FlexPostionPills.WRRB].push({
        ...pickedPlayer,
        rosterPosition: FlexPostionPills.WRRB,
        pickedRound: originalIndex,
      })
    } else if (canWRTEPositionBeFilled(position)) {
      filledPlayers[FlexPostionPills.WRTE] = filledPlayers[FlexPostionPills.WRTE] || []
      filledPlayers[FlexPostionPills.WRTE].push({
        ...pickedPlayer,
        rosterPosition: FlexPostionPills.WRTE,
        pickedRound: originalIndex,
      })
    } else if (canFlexPositionBeFilled(position)) {
      // If the position is already filled, check to fill FLX position
      filledPlayers[FlexPostionPills.FLEX] = filledPlayers[FlexPostionPills.FLEX] || []
      filledPlayers[FlexPostionPills.FLEX].push({
        ...pickedPlayer,
        rosterPosition: FlexPostionPills.FLEX,
        pickedRound: originalIndex,
      })
    } else if (canSuperFlexPositionBeFilled(position)) {
      // If the positions & FLX are already filled, check to fill SFLX position
      filledPlayers[FlexPostionPills.SUPERFLEX] = filledPlayers[FlexPostionPills.SUPERFLEX] || []
      filledPlayers[FlexPostionPills.SUPERFLEX].push({
        ...pickedPlayer,
        rosterPosition: FlexPostionPills.SUPERFLEX,
        pickedRound: originalIndex,
      })
    } else {
      // If all positions, Flex and SuperFlex are filled, fill the player at the BN position of the roster
      filledPlayers[BENCH_POSITION] = filledPlayers[BENCH_POSITION] || []
      filledPlayers[BENCH_POSITION].push({
        ...pickedPlayer,
        rosterPosition: BENCH_POSITION,
        pickedRound: originalIndex,
      })
    }
  })

  const rosterView = transformPlayersToRosterView(filledPlayers, startingPositions, benchCount)

  return rosterView
}
