import type { SDKTopPlayerProp } from '@pff-consumer/schema'

const FACET_ORDER = ['pass', 'run', 'receiving']
const FACET_DISPLAY_NAME: { [key in string]: string } = { pass: 'Passing', run: 'Rushing', receiving: 'Receiving' }

export function sortTopPlayerProps(topPlayers: SDKTopPlayerProp[]) {
  return [...topPlayers]
    .sort((a, b) => FACET_ORDER.indexOf(a.facet) - FACET_ORDER.indexOf(b.facet))
    .map(({ facet, players }) => ({ facet: FACET_DISPLAY_NAME[facet], players }))
}
