import { extractId } from '@pff-consumer/utils'
import { NextSuggestionData, PlayerNextRoundAvailability } from '@pff-consumer/schema'

export const getPlayersNextRoundAvailability = (
  suggestedNextPicks: Record<string, NextSuggestionData>
): PlayerNextRoundAvailability => {
  const playerNextRoundAvailability: PlayerNextRoundAvailability = {}

  // If this is the last round, there will not be any next round suggestions.
  const nextPickSuggestion: NextSuggestionData | string = suggestedNextPicks.if1

  if (
    typeof nextPickSuggestion === 'string' &&
    (nextPickSuggestion === 'last_round' || nextPickSuggestion === 'draft_over')
  ) {
    return playerNextRoundAvailability
  }

  Object.entries(suggestedNextPicks).forEach(([key, value]) => {
    value.player_id.forEach((playerId, index) => {
      const strippedPlayerId = extractId(playerId)
      if (!playerNextRoundAvailability[strippedPlayerId]) {
        playerNextRoundAvailability[strippedPlayerId] = Array(Object.keys(suggestedNextPicks).length).fill(0)
      }
      const suggestionIndex = parseInt(key.slice(2), 10) - 1
      playerNextRoundAvailability[strippedPlayerId][suggestionIndex] = Math.round(value.available_1[index] * 100)
    })
  })

  return playerNextRoundAvailability
}
