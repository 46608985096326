import type { EventMarketData, BettingNFLEvent, SportsbookType, SDKEvent } from '@pff-consumer/schema'

export const addEventMarketsDataToEvents = (
  events: SDKEvent[],
  eventMarkets: EventMarketData[],
  sportsbookId: SportsbookType
): Partial<BettingNFLEvent>[] => {
  if (!eventMarkets || eventMarkets.length === 0) {
    return events
  }

  return events.map((event) => {
    const eventAndSportsbookEventIds = {
      sportsbookEventId: event.sportsbookEventId,
      sportsbookId,
    }

    const awayMoneylineMarket = eventMarkets.find(
      (market) => market.eventId === event.eventId && market.market === 'moneyline' && market.option === 'away'
    )

    const homeMoneylineMarket = eventMarkets.find(
      (market) => market.eventId === event.eventId && market.market === 'moneyline' && market.option === 'home'
    )

    const awayPointSpreadMarket = eventMarkets.find(
      (market) => market.eventId === event.eventId && market.market === 'handicap-points' && market.option === 'away'
    )

    const homePointSpreadMarket = eventMarkets.find(
      (market) => market.eventId === event.eventId && market.market === 'handicap-points' && market.option === 'home'
    )

    const overTotalMarket = eventMarkets.find(
      (market) => market.eventId === event.eventId && market.market === 'total-points' && market.option === 'over'
    )

    const underTotalMarket = eventMarkets.find(
      (market) => market.eventId === event.eventId && market.market === 'total-points' && market.option === 'under'
    )

    const newBettingNFLEvent: Partial<BettingNFLEvent> = { ...event, sportsbookId }

    if (awayMoneylineMarket) {
      newBettingNFLEvent.awayMoneyline = {
        ...awayMoneylineMarket,
        ...eventAndSportsbookEventIds,
        detailedType: 'AwayTeamMoneyline',
        generalType: 'Moneyline',
        category: 'Game Bet',
      }
    }

    if (homeMoneylineMarket) {
      newBettingNFLEvent.homeMoneyline = {
        ...homeMoneylineMarket,
        ...eventAndSportsbookEventIds,
        detailedType: 'HomeTeamMoneyline',
        generalType: 'Moneyline',
        category: 'Game Bet',
      }
    }

    if (awayPointSpreadMarket) {
      newBettingNFLEvent.awayPointSpread = {
        ...awayPointSpreadMarket,
        ...eventAndSportsbookEventIds,
        detailedType: 'AwayTeamSpread',
        generalType: 'Spread',
        category: 'Game Bet',
      }
    }

    if (homePointSpreadMarket) {
      newBettingNFLEvent.homePointSpread = {
        ...homePointSpreadMarket,
        ...eventAndSportsbookEventIds,
        detailedType: 'HomeTeamSpread',
        generalType: 'Spread',
        category: 'Game Bet',
      }
    }

    if (overTotalMarket) {
      newBettingNFLEvent.overTotal = {
        ...overTotalMarket,
        ...eventAndSportsbookEventIds,
        detailedType: 'Over',
        generalType: 'Total',
        category: 'Game Bet',
      }
    }

    if (underTotalMarket) {
      newBettingNFLEvent.underTotal = {
        ...underTotalMarket,
        ...eventAndSportsbookEventIds,
        detailedType: 'Under',
        generalType: 'Total',
        category: 'Game Bet',
      }
    }

    return newBettingNFLEvent
  })
}
